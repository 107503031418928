import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

const MyPageTitleRoot = styled.div((props) => ({
  //color: "red",
  display: "flex",
  alignItems: "center",
  backgroundColor: props.theme.palette.primary.main,
  background: "white",
  background: `linear-gradient(90deg, white 0%, ${props.theme.palette.secondary.main} 75%)`,
  padding: props.size === "small" ? "1vh 1vh" : "3vh 3vh",
  borderRadius: "0.5rem",
  fontSize: props.size === "small" ? "1rem" : "1.25rem",
  fontWeight: "bold",
  transition: "padding 1s, font-size 1s",
  //border: "1px solid black",
}));

const MyPageTitle = (props) => {
  
  const {
    children,
    size="default"
  } = props;

  return(
    <MyPageTitleRoot size={size}>
      {children}
    </MyPageTitleRoot>
  );
}

export default MyPageTitle;