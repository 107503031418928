import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Button, Dialog, IconButton, Tooltip, DialogTitle, DialogContent, DialogContentText, DialogActions, unstable_useEnhancedEffect } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router-dom';
import MyPage from '../../layout-components/MyPage';
import MyPageContent from '../../layout-components/MyPage/MyPageContent';
import MyPageTitle from '../../layout-components/MyPage/MyPageTitle';
import SecondarySidebar from '../../layout-components/SecondarySidebar';
import {MyLayoutGrid as MyLayout} from '../../layout-components/MyLayout';
import { useDispatch, useSelector } from 'react-redux';
import StartVoteOverlay from './StartVoteOverlay';
import MyLineChart from '../../layout-components/MyLineChart';
import { DateRangeTwoTone, Garage } from '@mui/icons-material';
import voteService from '../../services/vote.service';
import MyButton from '../../layout-components/MyButton/MyButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PauseIcon from '@mui/icons-material/Pause';
import texts from '../../texts';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import GroupsIcon from '@mui/icons-material/Groups';
import GridTable from '../../layout-components/GridTable';
import GridTableHead from '../../layout-components/GridTable/GridTableHead';
import { useLocaleText } from '@mui/x-date-pickers/internals';
import UserList from '../../layout-components/UserList';

const NormalVoteRoot = styled.div((props) => ({
  width: "calc(100% - 2rem)",
  height: "calc(100% - 2rem)",
  padding: "1rem",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
}));

const NormalVoteHeader = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: props.theme.palette.primary.main,
  background: "white",
  background: `linear-gradient(90deg, ${props.theme.palette.primary.light} 0%, ${props.theme.palette.secondary.main} 75%)`,
  padding: "1rem",
  borderRadius: "0.5rem",
  gap: "1rem",
}));

const NormalVoteContent = styled.div((props) => ({
  height: props.hide ? "0px" : "100%",
  overflow: "hidden",
  width: "100%",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gridTemplateRows: "auto auto auto 3fr",
  gridTemplateAreas: `
    "buttons buttons"
    "infos results"
    "object subvotes"
    ". subvotes"
  `,
  gap: "1rem 1rem",
  position: "relative",
  transition: "height 1s",
}));

const ProcessingVoteContent = styled.div((props) => ({
  height: props.hide ? "0px" : "100%",
  overflow: "hidden",
  width: "100%",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gridTemplateRows: "auto auto 2fr",
  gridTemplateAreas: `
    "buttons buttons"
    "progress progress"
    "subvotes subvotes"
  `,
  gap: "1rem 1rem",
  position: "relative",
  transition: "height 1s",
}));

const NormalVoteModule = styled.div((props) => ({
  gridArea: props.gridArea,
  border: props.no_border ? "none" : "1px solid rgba(0, 0, 0, 0.2)",
  borderRadius: props.no_border ? "none" : "0.5rem",
  boxShadow: "1px 1px 1px 0px rgba(0, 0, 0, 0.2)",
  width: "calc(100% - 2px)",
  height: "calc(100% - 2px)",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  //transition: "height 2s",
}));

const NormalVoteModuleContent = styled.div((props) => ({
  overflow: "hidden",
  maxHeight: props.voting ? "0px" : "100%",
  transition: "max-height 0.1s"
}));

const NormalVoteModuleTitle = styled.div((props) => ({
  fontSize: "0.9rem",
  opacity: "0.7",
  padding: "0.5rem",
}));

const NormalVoteNumberModule = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.25rem",
  border: "1px solid black",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: props.theme.palette.gray[props.color],
  color: "black",
  borderRadius: "0.5rem",
  padding: "0.25rem",
  "& :nth-of-type(1)": {
    //color: "red",
    fontSize: "1.5rem",
  },
  "& :nth-of-type(2)": {
    //color: "blue",
    fontSize: "0.8rem",
    opacity: "0.7",
    fontStyle: "italic",
  },
}))

const AddVoteRoot = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",
}))

const AddVoteContent = styled.div((props) =>   ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
}))

const AddVoteForm = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  alignItems: "center",
}))

const AddVoteButtons = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
}))

const RemoveVote = (props) => {
  const {
    vote,
    setSidebar = () => {},
    handleClose,
  } = props;

  const navigate= useNavigate();

  const handleRemove = async() => {
    
    try{
      voteService.removeVote(vote)
        .then((response) => {
          navigate(`/vote#${vote.headvote}`);
        })
        .catch((e) => {
          console.log(e);
        })
    } catch(e){
      console.log(e);
    }

  }

  const closeSidebar = () => {
    handleClose();
  }


  if(vote === undefined || vote === null) return null;

  return(
    <AddVoteRoot>
      <h3>{texts.votes.sing} löschen</h3>
      <AddVoteContent>
        <div style={{textAlign: "center", display: "flex", flexDirection: "column", gap: "1rem", marginTop: "1.5rem"}}>
          <div>Soll</div>
          <div style={{fontWeight: "600", fontSize: "1.1rem"}}>{vote.title}</div>
          <div>wirklich gelöscht werden?</div>
        </div>
        <AddVoteButtons>
          <Button color="primary" variant="contained" onClick={handleRemove}>Löschen</Button>
          <Button color="error" variant="outlined" onClick={closeSidebar}>Abbrechen</Button>
        </AddVoteButtons>
      </AddVoteContent>
    </AddVoteRoot>
  );
}

const Vote = (props) => {

  const [id, setId] = React.useState(null);
  const [vote, setVote] = React.useState(null);
  const [sidebarContent, setSidebarContent] = React.useState(null);
  const [secondarySidebar, setSecondarySidebar] = React.useState(false);
  const [prepareVote, setPrepareVote] = React.useState(false);
  const [voting, setVoting] = React.useState(false);
  const [results, setResults] = React.useState([]);
  const [selectedResult, setSelectedResult] = React.useState([]);
  const [objectDialog, setObjectDialog] = React.useState(false);
  const [mea, setMea] = React.useState(0);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxVote = useSelector((state) => state.vote);
  const reduxSocket = useSelector((state) => state.socket);

  useEffect(() => {
    var hash = location.hash.slice(1);
    if(hash !== ""){
      setId(hash);
    }
  },[location.hash]);

  useEffect(() => {
    if(id !== null){
      voteService.getOne(id)
        .then((response) => {
          setVote(response.data);
        });
    }
  }, [id])

  useEffect(() => {
    if(vote){
      var mea = 0;
      if(vote.object !== undefined && vote.object !== null){
        if(vote.object.subobjects !== undefined && vote.object.subobjects !== null){
          for(let i=0; i<vote.object.subobjects.length; i++){
            if(vote.object.subobjects[i].type.voting){
              mea += Number(vote.object.subobjects[i].mea);
            }
          }
        } else {
          mea = vote.object.mea;
        }
      } 
      
      setMea(mea);
    }
  }, [vote])

  useEffect(() => {
    if(vote === null) return;
    if(reduxVote.id && reduxVote.id === vote.id){
      setVoting(true);
    } else {
      setVoting(false);
      
    }
  },[reduxVote])


  const handleStartVote = () => {
    if(reduxSocket.connected || true){
      dispatch({type: "vote/start", data: {voteId:vote.id}});
    } else {
      alert("Keine Socket-Verbindung aufgebaut!");
    }
  }

  const handleStopVote = () => {
    dispatch({type: "vote/stop", data: {voteId:vote.id}});
  }

  const handleEndVote = () => {
    dispatch({type: "vote/end", data: {voteId:vote.id}});
  }

  const handleReopenVote = () => {
    dispatch({type: "vote/reopen", data: {voteId:vote.id}});
  }

  const handleManualVote = (data) => {
    //wsSendMessage(Messages.manualVote(data));
  }

  const handleSecondarySidebarClosed = () => {
    setSecondarySidebar(false);
  }

  const handleOpenPres = () => {
    window.open(process.env.REACT_APP_PRES_URL, '_blank');
  }

  const uniqueOwners = () => {
    var count = 0;
    var temp = [];
    for(let i=0; i < vote.object.subobjects.length; i++){
      if(vote.object.subobjects[i].owner && !temp.includes(vote.object.subobjects[i].owner.name)){
        temp.push(vote.object.subobjects[i].owner.name);
        count++;
      }
    }

    return count;
  }

  const handleSecondarySidebar = (type) => {

    switch(type){
      case "remove_vote":
        setSidebarContent(<RemoveVote vote={vote} heatvote={vote.headvote} setSidebar={setSecondarySidebar}/>);
        break;

      default:
        setSidebarContent(null);
        break;
    }

    setSecondarySidebar(true);

  }

  const handleDelete = () => {
    handleSecondarySidebar("remove_vote")
  }

  if(vote === null) return null;

  return(
    <MyPage>
      <SecondarySidebar 
        open={secondarySidebar} 
        onClose={handleSecondarySidebarClosed}
        content={sidebarContent}
      />
      <MyPageTitle size={voting ? "small" : null}>
        <div style={{display: "flex", flexDirection: "column", position: "relative",}}>
          <div style={{
            position: "absolute",
            top: "-1rem",
            fontSize: "0.8rem",
            opacity: "0.6",
            fontStyle: "italic"
          }}>
            {texts.votes.sing}
          </div>
          <div>{vote.title}</div>
        </div>
        <div style={{
            display: "flex",
            marginRight: "0",
            marginLeft: "auto",
            gap: "1rem",
          }}>
            <MyButton text="Zurück" color={"secondary"} onClick={() => {navigate(-1)}} />
            
          </div>
      </MyPageTitle>
      <MyPageContent >
        <StartVoteOverlay />

        {/* not processing -> overview */}
        <NormalVoteContent hide={voting}>
          <NormalVoteModule gridArea="infos">
            <NormalVoteModuleTitle>
              Details
            </NormalVoteModuleTitle>
            <div style={{padding: "0.5rem", display: "grid", gridTemplateColumns: "1fr 1fr", gridTemplateRows: "1fr 1fr auto", gap: "0.5rem 0.5rem"}}>
              <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.25rem",
              }}>
                <div>
                  {vote.title}
                </div>
                <div style={{
                  fontSize: "0.8rem",
                  opacity: "0.7",
                  fontStyle: "italic",
                }}>
                  Titel
                </div>
              </div>

              <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.25rem",
              }}>
                <div style={{
                  display: "flex",
                  gap: "5px",
                }}>
                  <div>
                    {new Date(vote.createdAt).getDate().length < 2 ? `0${new Date(vote.createdAt).getDate()}` : new Date(vote.createdAt).getDate()}.
                    {(new Date(vote.createdAt).getMonth()+1).length < 2 ? `0${new Date(vote.createdAt).getMonth()+1}` : new Date(vote.createdAt).getMonth()+1}.
                    {new Date(vote.createdAt).getYear()+1900}
                  </div>
                   <div>{new Date(vote.createdAt).toLocaleTimeString()}</div>
                </div>
                <div style={{
                  fontSize: "0.8rem",
                  opacity: "0.7",
                  fontStyle: "italic",
                }}>
                  Angelegt am
                </div>
              </div>
              <div style={{
 
              }}>
                {vote.type === "qualified" 
                ? <div style={{
                  display: "flex",
                  gap: "0.5rem",
                  alignItems: "center",
                }}><NewReleasesIcon />Qualifizierter Beschluss</div>
                : null
                }
              </div>
              <div style={{

              }}>
                {vote.communities.length > 0 
                ? <div style={{
                  display: "flex",
                  gap: "0.5rem",
                  alignItems: "center",
                }}><GroupsIcon />Untergemeinschaften stimmen ab</div>
                : null
                }
              </div>

              <div style={{gridColumn: "1 / 3"}}>
                <div style={{
                  fontSize: "0.8rem",
                  opacity: "0.7",
                  fontStyle: "italic",
                  marginBottom: "3px",
                }}>Beschreibung</div>
                <div>{vote.description !== "" ? vote.description : "Keine Beschreibung"}</div>
              </div>
            </div>

            

          </NormalVoteModule>
          
          <NormalVoteModule gridArea="object">
            <div style={{
              position: "absolute",
              right: "0",
              top: "0",
              fontSize: "0.9rem",
              padding: "0.25rem",
              cursor: "pointer",
              zIndex: "+1",
              opacity: "0.7",
            }}
            onClick={()=>setObjectDialog(true)}>Details</div>
            <NormalVoteModuleTitle>
              {texts.objects.sing}
            </NormalVoteModuleTitle>
            <div style={{padding: "0.5rem", display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gridTemplateRows: "1fr 1fr", gap: "0.5rem 0.5rem"}}>
              <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.25rem",
                 gridColumn: "1 / 4",
              }}>
                <div>
                  {vote.object.name}
                </div>
                <div style={{
                  fontSize: "0.8rem",
                  opacity: "0.7",
                  fontStyle: "italic",
                }}>
                  Titel
                </div>
              </div>
              <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.25rem",
                 gridColumn: "1 / 4",
              }}>
                <div>
                  {vote.object.votingtype === "mea" ? "MEA" : texts.owner.plu}
                </div>
                <div style={{
                  fontSize: "0.8rem",
                  opacity: "0.7",
                  fontStyle: "italic",
                }}>
                  Stimmen
                </div>
              </div>
              <NormalVoteNumberModule color="main">
                <div>{mea}</div>
                <div>MEA</div>
              </NormalVoteNumberModule>
              <NormalVoteNumberModule color="light">
                <div>{vote.object.subobjects.length}</div>
                <div>{texts.subobjects.plu}</div>
              </NormalVoteNumberModule>
              
              <NormalVoteNumberModule color="dark">
                <div>{uniqueOwners()}</div>
                <div>{texts.owners.plu}</div>
              </NormalVoteNumberModule>

              <Dialog
                fullWidth={true}
                maxWidth={'lg'}
                open={objectDialog}
                onClose={()=>setObjectDialog(false)}
              >
                <DialogTitle>Objekt Informationen</DialogTitle>
                <DialogContent>
                  <GridTable cols={5}>
                    <GridTableHead>
                      <tr>
                        <th>Nummer</th>
                        <th>Name</th>
                        <th>Typ</th>
                        <th>{texts.owners.sing}</th>
                        <th>MEA</th>
                      </tr>
                    </GridTableHead>
                    <tbody>
                      {vote.object.subobjects.map((subobject, index) => 
                        <tr key={index}>
                          <td>{subobject.number}</td>
                          <Tooltip title={subobject.name} placement="top" arrow={true}><td>{subobject.name}</td></Tooltip>
                          <td>{subobject.typeId}</td>
                          <Tooltip title={subobject.owner.name} placement="top" arrow={true}><td>{subobject.owner.name}</td></Tooltip>
                        <td>{subobject.mea}</td>
                        </tr>
                      )}
                    </tbody>
                  </GridTable>
                </DialogContent>
                <DialogActions>
                  <Button onClick={()=>setObjectDialog(false)}>Schließen</Button>
                </DialogActions>
              </Dialog>
            </div>
          </NormalVoteModule>

          <NormalVoteModule gridArea="buttons">
            <div style={{
              display: "flex",
              gap: "1rem",
              padding: "0.5rem 0.5rem",
            }}>
              
              {reduxVote.started
              ? null
              : <div style={{
                  display: "flex",
                  marginRight: "0",
                  marginLeft: "auto",
                  gap: "1rem",
                }}>
                  <Button size="small" color="error" variant='contained' startIcon={<DeleteIcon />} onClick={handleDelete}>Löschen</Button>
                </div>
              }
            </div>
          </NormalVoteModule>
          
          <NormalVoteModule gridArea="results">
            <MyLineChart 
              results={vote.result !== null ? vote.result.resultsets : null} 
              users={vote.seatlayout.users} 
              //mea={mea} 
              presentation
            />
          </NormalVoteModule>

          <NormalVoteModule gridArea="subvotes">
              <NormalVoteModuleTitle>Teilnehmer</NormalVoteModuleTitle>
              <div style={{overflow: "auto", padding: "5px"}}>
                <UserList users={vote.seatlayout.users} results={vote.result} presentation/>
              </div>
                
              
              <div style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
              }}>

                {/*vote.seatlayout.users.map((user, index)=>
                  <div key={index} style={{ display: "flex", border: "1px solid rgba(0, 0, 0, 0.2)", padding: "0.5rem", alignItems: "center", justifyContent: "center", gap: "1rem"}}>
                    <div>
                      {user.deviceId !== null ? user.device.title : "no device"}
                      </div>
                    <div>
                      {user.owner.name}
                    </div>
                  </div>
                )*/}
              </div>
              <div style={{
                width: "calc(100% - 0.5rem)",
                height: "calc(100% - 0.5rem)",
                padding: "0.25rem",
                display: "none",
              }}>
                <MyLayout layout={vote.seatlayout} results={selectedResult.resultsets} object={vote.object} presentation />
              </div>
          </NormalVoteModule>
        </NormalVoteContent>

      </MyPageContent>
    </MyPage>
  );

}

export default Vote;