import axios from "axios";
import { clearAuth } from "../redux/slices/authSlice";

const login = (credentials) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/auth/signin`, credentials)
    .then((response) => {
      return response.data;
    });
}

const logout = () => {
  clearAuth();
}

const getCurrentUser = () => {
  
}

export default{
  login,
  logout
}