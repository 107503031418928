import React, { useEffect } from "react";

import MyList from "../../layout-components/MyList/index";
import DeviceListItem from "./DeviceListItem";

import deviceService from "../../services/device.service";
import ownerService from "../../services/owner.service";
import MyPage from "../../layout-components/MyPage";
import MyPageContent from "../../layout-components/MyPage/MyPageContent";
import MyPageTitle from "../../layout-components/MyPage/MyPageTitle";
import texts from "../../texts";

const DeviceList = () => {
  
  const [devices, setDevices] = React.useState([]);
  const [owners, setOwners] = React.useState([]);

  useEffect(() => {
    init();
  }, []);

  const init = async() => {
    deviceService.getAll()
      .then((response) => {
        setDevices(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
    ownerService.getAll()
      .then((response) => {
        setOwners(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  return(
    <MyPage>
      <MyPageTitle>
        {texts.devices.h_plu}
      </MyPageTitle>
      <MyPageContent>
        <MyList 
            component={<DeviceListItem owners={owners} />} 
            elements={devices}
            addButton={
            <div>
              
            </div>
            }
            searchProps={["title"]}
          />
      </MyPageContent>
    </MyPage>
  );
}

export default DeviceList;