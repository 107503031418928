import { 
  Tuple, 
  applyMiddleware, 
  combineReducers, 
  configureStore, 
  getDefaultMiddleware 
} from '@reduxjs/toolkit';

import { socketMiddleware } from './socket.middelware';
import { Socket } from '../utils/Socket';

//import storage from 'redux-persist/lib/storage';
import {thunk} from 'redux-thunk';
//import {persistReducer, persistStore} from 'redux-persist';

import socketSlice from './slices/socket.slice';
import authSlice from './slices/authSlice';

import {
  persistStore, 
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
/*import persistReducer from 'redux-persist/lib/persistReducer';
import persistStore from 'redux-persist/lib/persistStore';*/

//import storage from 'redux-persist/lib/storage';
//import storageSession from 'redux-persist/lib/storage/session';
//import storage from 'redux-persist/es/storages/session'
import storage from 'redux-persist/lib/storage/session';
import { voteMiddleware } from './vote.middelware';
import voteSlice from './slices/voteSlice';
import alertsSlice from './slices/alertsSlice';
import meetingSlice from './slices/meetingSlice';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ["socket"],
}

const rootReducer = combineReducers({
  auth: authSlice,
  socket: socketSlice,
  vote: voteSlice,
  alerts: alertsSlice,
  meeting: meetingSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  //middelware: applyMiddleware(socketMiddleware, thunk),
  //middelware: new Tuple(socketMiddleware, thunk),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  }).concat(socketMiddleware(new Socket())),
  //middleware: () => [thunk, socketMiddleware],
  //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  /*
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware([thunk]),
    */
  devtools: true
});

export const persistor = persistStore(store)