import React, { useEffect } from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Collapse, IconButton, ThemeProvider, Tooltip } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from "react-router-dom";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import voteService from "../../services/vote.service";
import MyButton from "../../layout-components/MyButton/MyButton";
import SecondarySidebar from "../../layout-components/SecondarySidebar";
import texts from '../../texts';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

//#region styles
const ObjectVoteItemRoot = styled.div((props) => ({
  width: "100%",
}))

const ObjectVoteItemTitle = styled.div((props) => ({
  fontSize: "1.0rem",
  display: "flex",
  justifyContent: "space-between",
  padding: "0.5rem 1rem",
  //backgroundColor: props.theme.palette.primary.main,
  color: "black", //props.theme.palette.primary.main,
  alignItems: "center",
  border: "1px solid black",
  borderBottom: "none",
}));

const ObjectVoteItemChevron = styled(KeyboardArrowDownIcon)((props) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  transition: "transform 0.2s, color 0.2s",
  transform: props.open ? "rotate(180deg)" : "rotate(0deg)",
  cursor: "pointer",
  borderRadius: "50%",
  color: props.active === "true" ? props.theme.palette.info.main : 'inherit',
  "&:hover": {
    color: props.theme.palette.secondary.dark,
  }
}));

const ObjectVoteItemAddIcon = styled(AddCircleOutlineIcon)((props) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  transition: "transform 0.2s, color 0.2s",
  transform: props.open ? "rotate(180deg)" : "rotate(0deg)",
  cursor: "pointer",
  borderRadius: "50%",
  color: 'inherit',
  "&:hover": {
    color: props.theme.palette.secondary.dark,
  }
}));

const ObjectVoteItemHideDoneIcon = styled(RemoveDoneIcon)((props) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  transition: "color 0.2s",
  cursor: "pointer",
  borderRadius: "50%",
  color: props.active === "true" ? props.theme.palette.info.main : 'inherit',
  "&:hover": {
    color: props.theme.palette.secondary.dark,
  }
}));

const ObjectVoteItemVotesContainer = styled.div((props) => ({
  border: "1px solid black",
  borderTop: "none",
}));

const ObjectVoteItemVote = styled.div((props) => ({
  padding: props.hide ? "0px 0.75rem" : "0.75rem",
  display: "grid",
  gridTemplateColumns: "auto 1fr auto auto auto",
  alignItems: "center",
  gap: "0rem 1rem",
  justifyContent: "space-between",
  backgroundColor: "unset",
  maxHeight: props.hide ? "0" : "100%",
  overflow: "hidden",
  cursor: "pointer",
  transition: "padding 0.5s, max-height 0.5s",
  "&:nth-of-type(odd)": {
    backgroundColor: "#e7e7e7",
  },
  "& > div": {
    transition: "color 0.2s",
  },
  
}));

const ObjectVoteItemVoteTitle = styled.div((props) => ({
  cursor: "pointer",
  textDecoration: "underline",
  "&:hover": {
    color: props.theme.palette.secondary.main,
  }
}));

const ObjectVoteItemNoVotes = styled.div((props) => ({
  opacity: "0.5",
  textAlign: "center",
}))

const AddVoteRoot = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",
}))

const AddVoteContent = styled.div((props) =>   ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
}))

const AddVoteButtons = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
}))
//#endregion

const MeetingItem = (props) => {
  
  const {
    element
  } = props;
  
//#region states
  const [object, setObject] = React.useState(null);
  const [open, setOpen] = React.useState(true);
  const [hideDone, setHideDone] = React.useState(false);
  const [sidebarContent, setSidebarContent] = React.useState(null);
  const [secondarySidebar, setSecondarySidebar] = React.useState(false);
  const [removeVote, setRemoveVote] = React.useState(null);
//#endregion

//#region hooks
  const theme = useTheme();
  const navigate = useNavigate();
//#endregion
  
//#region functions

  useEffect(() => {
    setObject(element);
  },[element]);

//#endregion

  if(object === null){
    return null;
  }

  return(
    <ThemeProvider theme={theme}>
      <ObjectVoteItemRoot>
        
        <ObjectVoteItemTitle>
          <div style={{
            display: "flex",
            gap: "1rem",
          }}>
            <div style={{opacity: "0.5"}}>{object.number}</div> 
            <div style={{
              fontWeight: "bold",
            }}>
              {object.name}
            </div>
          </div>
          <div style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}>
            <ObjectVoteItemHideDoneIcon onClick={() => setHideDone(!hideDone)} active={hideDone.toString()}/>
            <ObjectVoteItemChevron open={open} onClick={()=>setOpen(!open)} active={(!open).toString()} />
          </div>
          
        </ObjectVoteItemTitle>
        <Collapse in={open}>
          {object.votes.length <= 0
          ? <ObjectVoteItemNoVotes>Keine {texts.votes.plu}!</ObjectVoteItemNoVotes>
          : <ObjectVoteItemVotesContainer>
              {object.votes.map((vote, index) =>
                <ObjectVoteItemVote hide={hideDone && vote.done} key={index} >
                  {console.log("vote", vote)}
                  <div onClick={() => console.log("subvote", vote)} style={{display: "flex", alignItems: "center", gap: "0.5rem"}}>
                    {vote.done
                    ? 
                      <Tooltip title="Bereits durchgeführt">
                        <CheckCircleOutlineOutlinedIcon color="success"/>
                      </Tooltip>
                    : <Tooltip title="Noch nicht durchgeführt">
                        <CancelOutlinedIcon color="error" />
                      </Tooltip>
                    }
                    {vote.type === "incognito"
                    ? <Tooltip title="Nicht namentliche Abstimmung">
                        <VisibilityOffIcon color="gray" />
                      </Tooltip>
                    : null
                    }
                  </div>
                  
                  <ObjectVoteItemVoteTitle onClick={() => navigate(`/meeting#${vote.id}`)}>
                    {vote.title}
                  </ObjectVoteItemVoteTitle>

                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem"
                  }}>
                    <div>
                      {vote.subvotes.length} { vote.subvotes.length > 1 ? texts.votes.plu : texts.votes.sing}
                    </div>
                    <div>
                      {new Date(vote.createdAt).toLocaleTimeString()}
                    </div>
                    <div>
                      {new Date(vote.date).toLocaleDateString()}
                    </div>
                   
                  </div>
                  
                </ObjectVoteItemVote>
              )} 
            </ObjectVoteItemVotesContainer>
          }
        </Collapse>
      </ObjectVoteItemRoot>
    </ThemeProvider>
  );
}

export default MeetingItem;