export const getPropsForUser = (layout=null, results=null, devices=null, aways=null, index) => {
  /* returns: noone für kein benutzer in salami oder salami undefined oder null
      pro wenn result = 1
      contra wenn result = -1
      nichts wenn result = 0
  */

  /*if(index==="0"){

  }*/

  var result={};

  result.pro=null;
  result.contra=null;
  result.away=null;
  result.user=null;
  result.device=null;
  result.connected=null;
  result.ratio=0;


  if(layout!==null){
    //überprüfung ob es einen user auf dem sitz gibt - der sitz setzt im layout
      if(layout[index] !== undefined && layout[index] !== null){

          // ist ein user auf dem sitz hinterlegt?
          if(layout[index] !== undefined && layout[index] !== null){
              //result["user"]=layout[index].user;
              //ja ein user ist auf dem platz
              result.user = true;
              //ist der benutzer away?

              if(aways !== null){
                if(aways[index] !== undefined ){
                  result.away=true;
                } else {
                  result.away=false;
                }
              }
              

              /* obsolete bc new object aways where all away users are stored 18.05.2023
              if(layout[index].user.away !== undefined && layout[index].user.away === 1){
                result.away=true;
              } else {
                result.away=false;
              }*/

          } else {
              // kein user auf dem sitz hinterlegt
              result.user=false;
          }

          if(devices !== null && devices[index] !== undefined && devices[index] !== null){
            result.connected = devices[index].connected;
          } 

          //user überprüfungen abgeschlossen.
          //wir wissern ob ein user auf dem sitz ist und ob er away ist oder nicht

          //überprüfe results
          //gibt es results?
          if(results !== null){
              //gibt es results für den sitz?
              if(results[index] !== undefined && results[index] !== null){
                  //ist das ergebnis 1 = pro?

                  if(results[index] > 0){
                    result.pro=true;
                    result.contra=false;
                  } else if(results[index] < 0) {
                    result.pro=false;
                    result.contra=true;
                  }
              } 
          }
      } else {
        //kein user auf dem sitz
          result.pro = false;
          result.contra = false;
          result.away = false;
          result.user = false;
          result.connected=null;
      }
  }

  return result;
}