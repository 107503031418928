import React from 'react';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import history from './services/history';
import { ThemeProvider } from '@mui/material';
import theme from "./theme";
import {Provider}  from 'react-redux';

import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';

import RouteProvider from './routes';

import { createRoot } from 'react-dom/client';


const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Router history={history} basename={process.env.REACT_APP_BASENAME}> {/* objektverwaltung */}
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <RouteProvider />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </Router>
);

/*ReactDOM.render(
    <Router history={history}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <RouteProvider />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </Router>
    ,
  document.getElementById('root')
);*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
