import { setMeeting, setMeetingDevices } from "../redux/slices/meetingSlice";
import { setSocket, setId } from "../redux/slices/socket.slice";
import { setVote } from "../redux/slices/voteSlice";
import sendMessages from "./SendMessages";

class MessageHandler {

  constructor(socket, dispatch, getState){
    this.message = null;
    this.dispatch=dispatch;
    this.getState=getState;
    this.socket = socket;
    this.voteHandler = null;
  }

  /* 
    first message when connected: connected response -> send approve 
  */

  attachVoteHandler(voteHandler){
    this.voteHandler = voteHandler;
  }

  sendMessage(data){
    try{
      var token = this.getState().auth.token;
      var dacorToken = process.env.REACT_APP_WS_AUTH_TOKEN;
      var message = {
        header: {
          "x-dacor-token": dacorToken,
          "x-auth-token": token,
        },
        body: data
      }
      this.socket.send(message);
    } catch(e){
      console.log(e);
    }
  }

  handle(message){
    try{
      message = JSON.parse(message.data);
    } catch(e){
      console.log(e);
      return;
    }

    console.log("message", message);

    /* message konnte geparsed werden */
    switch(message.type){
      case "connected response":
        this.dispatch(setSocket(message.data));
        this.sendMessage(sendMessages.approve());
        break;

      case "approve response": 
        this.dispatch(setSocket(message.data));
        this.sendMessage(sendMessages.auth);
        break;

      case "auth response":
        this.dispatch(setSocket(message.data));
        this.sendMessage(sendMessages.GetProcessingVote);
        break;

      case "processing vote response":
        this.dispatch(setVote(message.data.vote));
        this.dispatch(setMeeting(message.data.meeting));
        break;

      case "meeting init response":
        console.log(message.data);
        this.dispatch(setMeeting(message.data));
        break;

      case "meeting start response":
        this.dispatch(setMeeting(message.data.meeting));
        this.dispatch(setVote(message.data.vote));
        break;

      case "meeting stopped response":
        console.log("meeting stopped response");
        console.log(message);
        this.dispatch(setMeeting(message.data));
        this.dispatch(setVote(null));
        break;

      case "meeting ended response":
        this.dispatch(setMeeting(null));
        this.dispatch(setVote(null));
        break;

      case "meeting update":
        this.dispatch(setMeeting(message.data));
        break;

      case "vote start response":
        this.dispatch(setVote(message.data));
        break;

      case "vote stop response":
        this.dispatch(setVote(message.data));
        break;

      case "vote ended response":
        this.dispatch(setVote(null));
        break;

      case "find vote response":
        this.voteHandler.findVoteRes(message);
        break;

      case "check processing response":
        this.voteHandler.checkProcessingRes(message);
        break;

      case "find layout response":
        this.voteHandler.layoutRes(message);
        break;
        
      case "users response":
        this.voteHandler.usersRes(message);
        break;
      
      case "devices response":
        this.voteHandler.devicesRes(message);
        break;

      case "connect to thingsboard response":
        this.voteHandler.tbConnectRes(message);
        break;
      
      case "verify vote init response":
        this.voteHandler.voteInitRes(message);
        break;
        
      case "results changed":
        this.voteHandler.resultsChanged(message);
        break;

      case "reopen vote response":
        this.dispatch(setVote(message.data));
        break;

      case "devices update":
        this.dispatch(setMeetingDevices(message.data));
        break;
    }

  }

}

export { MessageHandler }