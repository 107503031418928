import styled from '@emotion/styled';
import React, { useEffect } from "react";
import { Button, Checkbox, CircularProgress, FormControlLabel, LinearProgress, MenuItem, Tab, Tabs, TextField } from "@mui/material";
import MyButton from "../../layout-components/MyButton/MyButton";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Papa from "papaparse";
import PropTypes from 'prop-types';
import { useTheme } from "@emotion/react";
import MyPage from '../../layout-components/MyPage';
import MyPageTitle from "../../layout-components/MyPage/MyPageTitle";
import MyPageContent from "../../layout-components/MyPage/MyPageContent";
import GridTable from "../../layout-components/GridTable";
import objectService from '../../services/object.service';
import ObjectListItem from './ObjectListItem';
import { FileUploader } from "react-drag-drop-files";

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import configService from '../../services/config.service';
import { useDispatch } from 'react-redux';
import { addAlert } from '../../redux/slices/alertsSlice';
import SaveIcon from '@mui/icons-material/Save';

const ImportDataRoot = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  height: "100%",
  position: "relative",
}));

const ImportDataHeader = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
}))

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const StyledTextfield = styled(TextField)((props) => ({
  backgroundColor: "white",
  color: "black",
  borderRadius: "4px"
}));

const dataColumns = [
  
]

const StartImportContainer = styled.div((props) => ({
  display: "flex",
  height: "100%",
  width: "100%",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem"
}));

const StartImportInfos = styled.div((props) => ({
  border: "1px solid rgba(0, 0, 0, 0.2)",
  borderRadius: "1rem",
  padding: "1rem",
  maxWidth: "50%",
  "& :first-of-type": {
    fontSize: "0.7rem",
    opacity: "0.7",
  }
}));

const ImportPreviewContainer = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  overflowY: "auto",
  //border: "1px solid red",
  height: "100%",
}));

const ImportPreviewHeader = styled.div((props) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
}));

const ImportPreviewStatusbar = styled.div((props) => ({
  marginTop: "auto",
  marginBottom: "0",
  //height: "25px",
  borderTop: "1px solid rgba(0, 0, 0, 0.5)",
  backgroundColor: "rgba(0, 0, 0, 0.4)",
  padding: "3px",
}));

const FileUploadStyles = {
  backgroundColor: "red",
};

const StyledTh = styled.th((props) => ({
  display: "flex",
  alignItems: "center",
  padding: "none",
  flexDirection: "column",
}))

const ImportData = (props) => {

  const [importConfig, setImportConfig] = React.useState(null);
  const [importData, setImportData] = React.useState(null);
  const [value, setValue] = React.useState(0);
  const [cols, setCols] = React.useState({
    objNo: 0,
    objName: 1,
    subObjNo: 2,
    subObjName: 3,
    subObjType: 4,
    owner: 5,
    mea: 6
  });

  const [csvHeading, setCsvHeading] = React.useState(false);
  const [communitysCheckbox, setCommunityCheckbox] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [convertedData, setConvertedData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    if(file !== null){
      readFile();
    } else {
      setImportData(null);
    }
  },[file]);

  useEffect(() => {
    if(file !== null){
      readFile();
    } else {
      setImportData(null);
    }
  }, [importConfig])


  const init = () => {
    configService.importConfig()
      .then((response) => {
        setImportConfig(response.data);
      })
      .catch((e) => {
        console.log(e);
        dispatch(addAlert({
          type: "error",
          msg: "Fehler beim Abfragen der Spalten-Konfiguration!",
        }));
      })
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const readFile = () => {
    if(file === null) return;
    Papa.parse(file, {
      header: importConfig.heading,
      skipEmptyLines: true,
      complete: function (results) {
        var rows = results.data;

        //check for empty lines
        for(let i=0; i<rows.length; i++){
          var checkSum = 0;
          //check all flags
          var keys = Object.keys(rows[i]);
          for(let j=0; j<keys.length; j++){
            if(rows[i][keys[j]] === ""){
              checkSum++
            }
          }

          if(checkSum === keys.length){
            rows.splice(i,1);
          }
        }

        setImportData(rows);
      },
    });
  }

  const changeHandler = (file) => {
    //ladeanimation einfügen, dmait der user weiß, dass was passiert
    setFile(file[0]);
    //setFile(event.target.files[0]);
  }

  const handleColChanged = (label, value) => {
    var _cols ={...cols};
    _cols[label] = value;
    setCols(_cols);
  }

  const handleConvert = () => {
    const newData = [];
    const used = [];
    var objects = [];
    var owners = [];
    var types = [];
    var communities = [];

    

    /* save cols */
    configService.saveImportConfig(importConfig)
    .then((res) => {
      dispatch(addAlert({
        type: "info",
        msg: "Spalten-Konfiguration übernommen",
      }))
    })
    .catch((e) => {
      dispatch(addAlert({
        type: "error",
        msg: "Fehler beim Übernehmen der Spalten-Konfiguration!",
      }))
    })
    

    /*
      gehe jedes element des array importData durch
      ein element ist ein objekt bestehend aus keys = überschriften der spalten
      und values = daten in den zellen

      im prinzip repräsentiert jede zeile ein teilobjekt von einem objekt
    */

    for(let i=0; i<importData.length; i++){
      
      var row = importData[i];
      //obj = zeile
      var objNumber = row[Object.keys(row)[importConfig.objectNr]];
      var objName = row[Object.keys(row)[importConfig.objectName]];
      var subName = row[Object.keys(row)[importConfig.subobjectName]];
      var subNumber = row[Object.keys(row)[importConfig.subobjectNr]];
      var subType = row[Object.keys(row)[importConfig.subobjectType]];
      var subMea = row[Object.keys(row)[importConfig.mea]];
      var subOwner = row[Object.keys(row)[importConfig.owner]];
      var community = row[Object.keys(row)[importConfig.community]];
      var votefaktor = row[Object.keys(row)[importConfig.votefaktor]];
      var subobject = {};

      /*
        überprüfe, ob das objekt zu diesem subobjekt bereits vorhanden ist
        wenn es vorhanden ist, dann füge dieses subobjekt zu dem objekt als sub hinzu

        überprüfe ob der owner dieses subobjekts bereits vorhanden ist
        wenn nicht, füge den owner zum owner array hinzu

        überprüfe, ob der typ dieses subobjekts bereits vorhanden ist
        wenn nicht, füge den typ zum typ array hinzu
      */

      /* überprüfe owner */
      //versuche index des owners zu finden
      var ownerIndex = owners.findIndex(x => x === subOwner);
      if(ownerIndex === -1){
        //owner nicht gefunden
        ownerIndex = owners.push(subOwner);
      } 

      /* überprüfe type */
      //versuche index des owners zu finden
      var typeIndex = types.findIndex(x => x === subType);
      if(typeIndex === -1){
        //owner nicht gefunden
        typeIndex = types.push(subType);
      } 

      var communityIndex = communities.findIndex(x => x === community);
      if(communityIndex === -1){
        //owner nicht gefunden
        communityIndex = communities.push(community);
      } 

      subobject = {
        ownerIndex: ownerIndex,
        typeIndex: typeIndex,
        name: subName,
        number: subNumber,
        mea: subMea,
        community: community,
        type: {
          title: subType
        },
        owner: {
          name: subOwner
        }
      };

      /* überprüfe ob das object schon existiert */
      var objectIndex = objects.findIndex(x => x.number === objNumber);
      if(objectIndex === -1){
        objects.push({
          number: objNumber,
          name: objName,
          subobjects: [subobject]
        });
      } else {
        objects[objectIndex].subobjects.push(subobject);
      }
    }

    setConvertedData(objects);
    


    /*
      
    */

    //für jedes object eine suche ausführen
    //daten nebeneinander stellen
    //muss auch erstmal nicht.
    //erstmal objekte visuell darstellen
    //die search reoute gibt es auf dem server nicht - muss erstellt werden
    /*objectService.search({
      name: objectIndex.name,
      number: objectIndex.number,
    })*/

  }

  const handleSave = () => {
    setLoading(true);
    objectService.importNewData(convertedData)
      .then((response) => {
        setImportData(null);
        setConvertedData(null);
        setFile(null);
        setLoading(false);
        dispatch(addAlert({
          type: "info",
          msg: "Daten wurden erfolgreich importiert!"
        }));
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        dispatch(addAlert({
          type: "error",
          msg: "Fehler beim Importieren der Import-Data!"
        }));
      });
  }

  const handleCancel = () => {
    setImportData(null);
    setConvertedData(null);
    setFile(null);
  }

  /*
    Daten import
    1. Datei hochladen
    2. Spalten auswählen
    3. Daten konvertieren
    4. Versuche Objekte vom Server zu holen
  */


  return(
    <ImportDataRoot>

      {loading
      ? <div style={{
        position: "absolute",
        display: "flex",
        height: "100%",
        width: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        zIndex: "+1",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "1rem",
      }}>
        <CircularProgress size="4rem" color="secondary" />
        <div style={{color: "white", fontSize: "1.3rem"}}>Daten werden verarbeitet.</div>
      </div>
      : null
      }

      {file === null && convertedData === null
      ? <StartImportContainer>
          <FileUploader 
            multiple={true}
            handleChange={changeHandler}
            name="file"
            types={["CSV"]}
            label="Datei hier hinziehen oder hochalden"
          /> 
        </StartImportContainer> 
      : null
      }

      {file !== null && convertedData === null
        ? <ImportPreviewContainer>
          
            <ImportPreviewHeader>
              <div style={{
                display: "flex",
                justifyContent: "space-between"
              }}>
                <Button variant="contained" onClick={handleCancel} color="error">Abbrechen</Button>
                <Button variant="contained" onClick={handleConvert} color="primary">Spalten übernehmen - Konvertieren beginnen</Button>
              </div>
              
              <div>
                <FormControlLabel label="Überschriften" control={<Checkbox checked={importConfig.heading} onChange={
                  (e) => setImportConfig({...importConfig, heading: !importConfig.heading})}/>
                } />
                <FormControlLabel label="Untergemeinschaften" control={<Checkbox checked={importConfig.community !== null} onChange={
                  (e) => setImportConfig({...importConfig, community: !e.target.checked ? null : 7})}/>
                }/>
                
                <FormControlLabel label="Abstimmungsfaktor" control={<Checkbox checked={importConfig.votefaktor !== null} onChange={
                  (e) => setImportConfig({...importConfig, votefaktor: !e.target.checked ? null : 8})}/>
                }/>
              </div>
            </ImportPreviewHeader>
            
            <GridTable 
              cols={
                importConfig.community !== null && importConfig.votefaktor !== null ? 9
                : importConfig.community !== null && importConfig.votefaktor === null ? 8
                : importConfig.community === null && importConfig.votefaktor !== null ? 8
                : 7
              } 
              //colsTemplate={`repeat(9 - ${importConfig.community !== null ? 0 : 1} - ${importConfig.votefaktor !== null ? 0 : 1}, auto)`}
            >
              <thead>
                <tr>

                  <StyledTh>
                    <div style={{fontSize: "0.9rem", color: "black"}}>Obj. Nr.</div>
                    <StyledTextfield select value={importConfig.objectNr} size="small"  onChange={(e)=>setImportConfig({...importConfig, objectNr: e.target.value})}>
                      {importData === null ? null : Object.keys(importData[0]).map((key, index) =>
                        <MenuItem key={index} value={index}>{index+1}</MenuItem>
                      )}
                    </StyledTextfield>
                  </StyledTh>

                  <StyledTh>
                    <div style={{fontSize: "0.9rem", color: "black"}}>Obj. Name</div>
                    <StyledTextfield select value={importConfig.objectName} size="small" onChange={(e)=>setImportConfig({...importConfig, objectName: e.target.value})}>
                      {importData === null ? null : Object.keys(importData[0]).map((key, index) =>
                        <MenuItem key={index} value={index}>{index+1}</MenuItem>
                      )}
                    </StyledTextfield>
                  </StyledTh>

                  <StyledTh>
                    <div style={{fontSize: "0.9rem", color: "black"}}>SubObj. Nr.</div>
                    <StyledTextfield select value={importConfig.subobjectNr} size="small" onChange={(e)=>setImportConfig({...importConfig, subobjectNr: e.target.value})}>
                      {importData === null ? null : Object.keys(importData[0]).map((key, index) =>
                        <MenuItem key={index} value={index}>{index+1}</MenuItem>
                      )}
                    </StyledTextfield>
                  </StyledTh>

                  <StyledTh>
                    <div style={{fontSize: "0.9rem", color: "black"}}>SubObj. Name</div>
                    <StyledTextfield select value={importConfig.subobjectName} size="small" onChange={(e)=>setImportConfig({...importConfig, subobjectName: e.target.value})}>
                      {importData === null ? null : Object.keys(importData[0]).map((key, index) =>
                        <MenuItem key={index} value={index}>{index+1}</MenuItem>
                      )}
                    </StyledTextfield>
                  </StyledTh>

                  <StyledTh>
                    <div style={{fontSize: "0.9rem", color: "black"}}>SubObj Typ</div>
                    <StyledTextfield select value={importConfig.subobjectType} size="small" onChange={(e)=>setImportConfig({...importConfig, subobjectType: e.target.value})}>
                      {importData === null ? null : Object.keys(importData[0]).map((key, index) =>
                        <MenuItem key={index} value={index}>{index+1}</MenuItem>
                      )}
                    </StyledTextfield>
                  </StyledTh>

                  <StyledTh>
                    <div style={{fontSize: "0.9rem", color: "black"}}>Eigentümer</div>
                    <StyledTextfield select value={importConfig.owner} size="small" onChange={(e)=>setImportConfig({...importConfig, owner: e.target.value})}>
                      {importData === null ? null : Object.keys(importData[0]).map((key, index) =>
                        <MenuItem key={index} value={index}>{index+1}</MenuItem>
                      )}
                    </StyledTextfield>
                  </StyledTh>

                  <StyledTh>
                    <div style={{fontSize: "0.9rem", color: "black"}}>MEA</div>
                    <StyledTextfield select value={importConfig.mea} size="small" onChange={(e)=>setImportConfig({...importConfig, mea: e.target.value})}>
                      {importData === null ? null : Object.keys(importData[0]).map((key, index) =>
                        <MenuItem key={index} value={index}>{index+1}</MenuItem>
                      )}
                    </StyledTextfield>
                  </StyledTh>

                  {importConfig.community === null ? null : 
                    <StyledTh>
                      <div style={{fontSize: "0.9rem", color: "black"}}>UG</div>
                      <StyledTextfield select value={importConfig.community} size="small" onChange={(e)=>setImportConfig({...importConfig, community: e.target.value})}>
                        {importData === null ? null : Object.keys(importData[0]).map((key, index) =>
                          <MenuItem key={index} value={index}>{index+1}</MenuItem>
                        )}
                      </StyledTextfield>
                    </StyledTh>
                  }

                  {importConfig.votefaktor === null ? null : 
                    <StyledTh>
                      <div style={{fontSize: "0.9rem", color: "black"}}>Faktor</div>
                      <StyledTextfield select value={importConfig.votefaktor} size="small" onChange={(e)=>setImportConfig({...importConfig, votefaktor: e.target.value})}>
                        {importData === null ? null : Object.keys(importData[0]).map((key, index) =>
                          <MenuItem key={index} value={index}>{index+1}</MenuItem>
                        )}
                      </StyledTextfield>
                    </StyledTh>
                  }
                </tr>
              </thead>
              <tbody>
                {importData === null ? null : importData.map((row, index) => {
                  if(index > 10) return null;
                  return(
                    <tr key={index}>
                      <td>{row[Object.keys(row)[importConfig.objectNr]]}</td>
                      <td>{row[Object.keys(row)[importConfig.objectName]]}</td>
                      <td>{row[Object.keys(row)[importConfig.subobjectNr]]}</td>
                      <td>{row[Object.keys(row)[importConfig.subobjectName]]}</td>
                      <td>{row[Object.keys(row)[importConfig.subobjectType]]}</td>
                      <td>{row[Object.keys(row)[importConfig.owner]]}</td>
                      <td>{row[Object.keys(row)[importConfig.mea]]}</td>
                      {importConfig.community === null ? null : <td>{row[Object.keys(row)[importConfig.community]]}</td>}
                      {importConfig.votefaktor === null ? null : <td>{row[Object.keys(row)[importConfig.votefaktor]]}</td>}
                    </tr>
                    ); 
                  })}
                </tbody>
            </GridTable>
            <ImportPreviewStatusbar>
              {file.name}
            </ImportPreviewStatusbar>
          </ImportPreviewContainer>
      : null
      }


      {convertedData !== null
      ? <div style={{height: "100%", display: "flex", flexDirection: "column", gap: "1rem",}}>
          <div style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            width: "100%",
          }}>
            <Button variant="contained" onClick={handleCancel} color="error">Abbrechen</Button>
            <div style={{
              padding: "0rem 0rem",
              display: "flex",
              gap: "1rem",
              marginLeft: "auto",
              marginRight: "0",
            }}>
              
              <Button variant="contained" onClick={()=>setConvertedData(null)} color="secondary">Zurück</Button>
              <Button variant='contained' onClick={handleSave} color="primary" startIcon={<SaveIcon />}>Import starten</Button>
              
              
            </div>
            <div style={{
              border: `2px solid ${theme.palette.info.main}`,
              padding: "0.5rem",
              borderRadius: "0.5rem",
              display: "none",
              gap: "1rem",
              alignItems: "center",
            }}>
              <div style={{
                color: `${theme.palette.info.main}`,
              }}>
                <InfoOutlinedIcon/>
              </div>
              <div>
                <div>Daten wurden konvertiert. Neue Objekte werden angezeigt.</div>
                <div>Wenn die Objekte übernommen werden, überschreiben sie bereits vorhandene alte Objekte.</div>
              </div>
            </div>
            
          </div>
         
          <div style={{
            overflowY: "auto",
            height: "100%",
          }}>
            {convertedData.map((data, index) =>
              <ObjectListItem element={data} key={index} />
            )}
          </div>
        </div>
      : null
      }

    </ImportDataRoot>
  );
}

export default ImportData;