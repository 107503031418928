import React from 'react';
import { 
  Grow
} from '@mui/material';

import MyAlert from "./MyAlert";
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';

const MyAlertsProviderRoot = styled.div((props) => ({
  position: "absolute",
  top: "0",
  left: "0",
  padding: "1em",
  display: "flex",
  opacity: "1",
  zIndex: 9999,
  width: "calc(100% - 2em)",
  flexDirection: "column",
  gap: "0.5em",
}));

const MyAlertsProvider = () => {

  const alerts = useSelector((state) => state.alerts);

  return(
    <Grow in={Object.keys(alerts).length > 0}>
      <MyAlertsProviderRoot>
        {Object.keys(alerts).map((ts, index) => 
          <MyAlert alert={alerts[ts]} key={index} ts={ts} />
        )}
      </MyAlertsProviderRoot>
    </Grow>
  );
}

export default MyAlertsProvider;