import React from 'react';
import { 
  ThemeProvider,
  useTheme,
  Button,
  Tooltip
} from '@mui/material';
import styled from '@emotion/styled';



const StyledButton = styled(Button)(({ theme }) => ({
  width: 300,
  color: theme.palette.success.main,
  
}));

const MyButton = (props) => {

  const {
    text,
    disabled,
    tooltip="",
    onClick=null,
    color="primary",
    startIcon=null,
    style,
    children,
    fullWidth=true
  } = props;

  const theme = useTheme();

  return(
    <ThemeProvider theme={theme}>
      <Tooltip title={tooltip} enterDelay={750} enterNextDelay={750} arrow>
          
          <Button 
            variant="contained"
            disabled={disabled}
            onClick={onClick ? onClick : null}
            fullWidth={fullWidth}
            style={style}
            color={color}
          >
            <div style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}>
              {startIcon !== null
              ? React.cloneElement(startIcon, {style: {opacity: "0.7", marginTop: "1px"}})
              : null
              }
              {text}
            </div>
            {children}
          </Button>
       
    </Tooltip>
  </ThemeProvider>
);
}

export default MyButton;
