import React, { useEffect } from "react";
import { Button, Collapse, IconButton, Tooltip } from "@mui/material";
import {
  Replay,
  Check,
  Close,
  VoiceOverOff,
  Mic,
  Smartphone
} from '@mui/icons-material';
import styled from "@emotion/styled/macro";
import { useDispatch, useSelector } from "react-redux";
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { ContraVoteIcon, NoneVoteIcon, ProVoteIcon } from "../MyIcons";

const UserItemRoot = styled.div((props) => {
  switch(props.voted){
    case "pro":
      return {
        border: "4px solid rgba(0, 200, 0, 1)",
      }
    
    case "contra":
      return {
        border: "4px solid rgba(200, 0, 0, 1)",
      }

    case "none":
      return {
        border: "4px solid orange",
      }

    default:
      return {
        border: "1px solid rgba(0, 0, 0, 0.2)"
      }
  }
})

const UserItemContent = styled.div((props) => ({
  display: "flex",
  padding: "0.5rem",
  alignItems: "center",
  gap: "1rem",
  height: "calc(100% - 1rem)",
  position: "relative",
  "&:hover": {
    [SeatVoteButtons]: {
      opacity: "1",
    },
    [SeatCheckIcon]: {
      opacity: props.buttons ? "0" : null,
    },
    [SeatCloseIcon]: {
      opacity: props.buttons ? "0" : null,
    }
  }
}))

const SeatButtonContainer = styled.div((props) => ({
  //border: "1px solid red",
  marginRight: "0",
  marginLeft: "auto",
  position: "relative",
  width: "50%",
  height: "100%",
}));

const SeatVoteButtons = styled.div((props) => ({
  position: "absolute",
  top: "0",
  bottom: "0",
  right: "0",
  //border: "1px solid black",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  opacity: "0",
  transition: "opacity 0.2s linear",
  width: "100%",
  zIndex: "+1",
}));

const SeatCheckIcon = styled(Check)((props) => ({
  opacity: props.show === 'true' ? "1" : "0",
  //opacity: "1",
  position: "absolute",
  right: "0",
  //left: "0",
  //bottom: "0",
  //top: "0",
  //width: "100%",
  //border: "1px solid red",
  //height: "100%",
  fontSize: "2rem",
  transition: "opacity 0.2s linear",
  //display: "flex",
  //justifyContent: "flex-end"  
}));

const SeatCloseIcon = styled(Close)((props) => ({
  opacity: props.show === 'true' ? "1" : "0",
  position: "absolute",
  right: "0",
  //left: "0",
  bottom: "0",
  top: "0",
  //width: "100%",
  height: "100%",
  fontSize: "2rem",
  transition: "opacity 0.2s linear",
}));

const DeviceContainer = styled.div((props) => ({
  position: "relative",
  "&::after" : {
    content: '" "',
    display: "block",
    backgroundColor: 
      props.device === "true" 
      ? props.connected === "true" 
        ? props.theme.palette.success.main
        : props.theme.palette.error.main 
      : "transparent",
    height: "10px",
    width: "10px",
    position: "absolute",
    bottom: "0px",
    right: "0px",
    borderRadius: "50%",
  }
}))

const StyledSmartphone = styled(Smartphone)((props) => ({
  border: "1px solid rgba(0, 0, 0, 0.2)",
  padding: "5px",
  cursor: "pointer",
  color: props.away === "true" ? props.theme.palette.error.main : props.theme.palette.success.main,
}))

const StyledDoNotDisturbAltIcon = styled(DoNotDisturbAltIcon)((props) => ({
  border: "1px solid rgba(0, 0, 0, 0.2)",
  padding: "5px",
  cursor: "pointer",
}))

const StlyedAwayIcon = styled(CloseIcon)((props) => ({
  border: "1px solid rgba(0, 0, 0, 0.2)",
  padding: "5px",
  cursor: "pointer",
  color: props.theme.palette.error.main
}))

const StlyedNotAwayIcon = styled(CheckIcon)((props) => ({
  border: "1px solid rgba(0, 0, 0, 0.2)",
  padding: "5px",
  cursor: "pointer",
  color: props.theme.palette.success.main
}))

const StyledCheckContainer = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "2px",
  backgroundColor: props.theme.palette.success.main,
  color: "white",
}))

const StyledCrossContainer = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "2px",
  backgroundColor: props.theme.palette.error.main,
  color: "white",
}))

const UserItem = (props) => {
  
  const {
    user = null, 
    index,
    voteValue=0,
    result=null,
    userProps=null,
    presentation,
  } = props;

  const [open, setOpen] = React.useState(false);

  

  const reduxVote = useSelector((state) => state.vote);
  const dispatch = useDispatch();

  const setVoteOnSeat = (value) => {
    dispatch({type: "vote/manual", data: {
      seatnum: user.seat,
      value: value,
    }})
  }

  const handleUserAway = () => {
    console.log("User Away", user.away);
    dispatch({type: "user/toogle", data: {
      seatnum: user.seat,
    }})
  }

  if(userProps===null) return null;

  if(presentation) {
    return(
      <UserItemRoot voted={userProps.result > 0 ? "pro" : userProps.result < 0 ? "contra" : null}>
      <UserItemContent buttons={userProps.buttons}>
      {userProps.result !== null 
          ? userProps.result > 0
            ?  <ProVoteIcon />
            : userProps.result < 0
              ? <ContraVoteIcon />
              : userProps.result === 0
                ? <NoneVoteIcon />
                : null
          : null
          }
       
        <div style={{width: "100%"}}>
          {userProps.owner !== null ? userProps.owner.name : null}
        </div>

        
        <DeviceContainer 
          device={"false"} 
          connected={null}
        >
          {userProps.device !== null
          ? <Tooltip title={userProps.device !== null ? userProps.device.title : null}>
              <StyledSmartphone onClick={() => setOpen(!open)} device={userProps.device !== null ? "true" : "false"} away={userProps.away !== undefined ? userProps.away.toString() : null}/>
            </Tooltip>
          : <Tooltip title={"kein Abstimmgerät hinterlegt"}>
              <StyledDoNotDisturbAltIcon color="error" onClick={() => setOpen(!open)} device={userProps.device !== null ? "true" : "false"}/>
            </Tooltip>
          }
        </DeviceContainer>
        
      </UserItemContent>
      <Collapse in={open}>
        <div style={{
          padding: "3px",
        }}>
          <div>{userProps.device !== null && userProps.device.model !== undefined && userProps.device.model !== null ? userProps.device.model.title : "Kein Gerät hinterlegt"}</div>
          <div>
            {userProps.device !== null && userProps.device.connected !== undefined 
            ?  userProps.device.connected
              ? "verbunden" 
              : "nicht verbunden"
            : null
            }
          </div>
          <div>
            {userProps.away !== undefined 
            ? userProps.away
              ? "nicht angemeldet"
              : "angemeldet"
            : null
            }
          </div>
          <div>
            <Button size="small" onClick={()=>console.log(user)}>Print User</Button>
            <Button onClick={handleUserAway}>Toggle Away</Button>
            <button onClick={() => console.log("userProps", userProps)}>prubnt user props</button>
          </div>
        </div>
        
      </Collapse>
    </UserItemRoot>
    );
  }


  return(
    <UserItemRoot 
      voted={
        userProps.result !== null 
        ? userProps.result > 0
          ? "pro"
          : userProps.result < 0
            ? "contra"
            : userProps.result === 0
            ? "none"
            : null 
        :  null
        }
      >
      <UserItemContent buttons={userProps.buttons}>
        
      {userProps.result !== null 
      ? userProps.result > 0
        ?  <ProVoteIcon />
        : userProps.result < 0
          ? <ContraVoteIcon />
          : userProps.result === 0
            ? <NoneVoteIcon />
            : null
      : null
      }


        <div>
          {userProps.owner !== null ? userProps.owner.name : null}
        </div>

        
           <SeatButtonContainer>
           {!userProps.buttons || userProps.away ? null :
            <SeatVoteButtons>
              <IconButton onClick={() => setVoteOnSeat(1)}>
                <ProVoteIcon />
              </IconButton>
              <IconButton onClick={() => setVoteOnSeat(-1)}>
                <ContraVoteIcon />
              </IconButton>
              <IconButton onClick={() => setVoteOnSeat(0)}>
                <NoneVoteIcon />
              </IconButton>
              <IconButton onClick={() => setVoteOnSeat(null)}>
                <Replay color="info"/>
              </IconButton>
            </SeatVoteButtons>  
            }
          </SeatButtonContainer>
        
       
        
        <DeviceContainer 
          device={userProps.device !== null && userProps.device.model !== undefined && userProps.device.model !== null ? "true" : "false"} 
          connected={userProps.device !== null && userProps.device.model !== null && userProps.device.connected !== undefined ? userProps.device.connected.toString() : null}
        >
          {userProps.device.model !== null
          ? <Tooltip title={userProps.device !== null ? userProps.device.title : null}>
              {userProps.away
              ? <StlyedAwayIcon onClick={() => setOpen(!open)} device={userProps.device !== null ? "true" : "false"} away={userProps.away !== undefined ? userProps.away.toString() : null}/>
              : <StlyedNotAwayIcon onClick={() => setOpen(!open)} device={userProps.device !== null ? "true" : "false"} away={userProps.away !== undefined ? userProps.away.toString() : null}/>
              }
              {/*<StyledSmartphone onClick={() => setOpen(!open)} device={userProps.device !== null ? "true" : "false"} away={userProps.away !== undefined ? userProps.away.toString() : null}/>*/}
            </Tooltip>
          : <Tooltip title={"kein Abstimmgerät hinterlegt"}>
              <StyledDoNotDisturbAltIcon color="error" onClick={() => setOpen(!open)} device={userProps.device !== null ? "true" : "false"}/>
            </Tooltip>
          }
        </DeviceContainer>
      </UserItemContent>
      <Collapse in={open}>
        <div style={{
          padding: "3px",
        }}>
          <div>{userProps.device !== null && userProps.device.model !== undefined && userProps.device.model !== null ? userProps.device.model.title : "Kein Gerät hinterlegt"}</div>
          <div>
            {userProps.device !== null && userProps.device.connected !== undefined 
            ?  userProps.device.connected
              ? "verbunden" 
              : "nicht verbunden"
            : null
            }
          </div>
          <div>
            {userProps.away !== undefined 
            ? userProps.away
              ? "nicht angemeldet"
              : "angemeldet"
            : null
            }
          </div>
          <div>
            <Button size="small" onClick={()=>console.log(user)}>Print User</Button>
            <Button onClick={handleUserAway}>Toggle Away</Button>
            <button onClick={() => console.log("userProps", userProps)}>prubnt user props</button>
            <button onClick={() => null}>print results</button>
          </div>
        </div>
        
      </Collapse>
    </UserItemRoot>
  );

  if(result){
    return(
      <UserItemRoot voted={result > 0 ? "pro" : result < 0 ? "contra" : null}>
      <UserItemContent >
        <DeviceContainer device={user.device !== undefined && user.device !== null  ? "true" : "false"}>
          {user.deviceId !== null && user.device !== null
          ? <Tooltip title={user.device.title}>
              <StyledSmartphone onClick={() => setOpen(!open)} device={user.device !== null ? "true" : "false"} />
            </Tooltip>
          : <Tooltip title={"kein Abstimmgerät hinterlegt"}>
              <StyledDoNotDisturbAltIcon color="error"/>
            </Tooltip>
          }
        </DeviceContainer>
        <div>
          {user.owner.name}
        </div>

          <SeatCheckIcon show={result > 0  ? 'true' : 'false'} />
          <SeatCloseIcon show={result < 0  ? 'true' : 'false'} />
        </UserItemContent>
        <Collapse in={open}>
          <div style={{
            padding: "3px",
          }}>
           
          </div>
          
        </Collapse>
      </UserItemRoot>
    );
  }

  if(reduxVote.id === undefined){
    return(
      <UserItemRoot>
        <UserItemContent voted={voteValue > 0 ? "pro" : voteValue < 0 ? "contra" : null}>
          <DeviceContainer device={user.device.model !== null ? "true" : "false"} connected={user.device.connected.toString()}>
            {user.deviceId !== null && user.device.model !== null
            ? <Tooltip title={user.device.model.title}>
                <StyledSmartphone onClick={() => setOpen(!open)} device={user.device.model !== null ? "true" : "false"} away={user.away.toString()}/>
              </Tooltip>
            : <Tooltip title={"kein Abstimmgerät hinterlegt"}>
                <StyledDoNotDisturbAltIcon color="error" onClick={() => setOpen(!open)} device={user.device.model !== null ? "true" : "false"}/>
              </Tooltip>
            }
          </DeviceContainer>
          <div>
            {user.owner.name}
          </div>
        </UserItemContent>
        <Collapse in={open}>
          <div style={{
            padding: "3px",
          }}>
            <div>{user.device.model !== null ? user.device.model.title : "Kein Gerät hinterlegt"}</div>
            <div>
              {user.device.connected ? "verbunden" : "nicht verbunden"}
            </div>
            <div>
              {user.away ? "nicht angemeldet" : "angemeldet"}
            </div>
            <div>
              <Button size="small" onClick={()=>console.log(user)}>Print User</Button>
              <Button onClick={handleUserAway}>Toggle Away</Button>
            </div>
          </div>
          
        </Collapse>
      </UserItemRoot>
    );
  }

  //redux is set
  return(
    <UserItemRoot voted={reduxVote.results[user.seat] > 0 ? "pro" : reduxVote.results[user.seat] < 0 ? "contra" : null}>
        <UserItemContent >
          <DeviceContainer device={user.device.model !== undefined && user.device.model !== null  ? "true" : "false"} connected={user.device.connected !== undefined ? user.device.connected.toString() : null}>
            {user.deviceId !== null && user.device.model !== null
            ? <Tooltip title={user.device.model.title}>
                <StyledSmartphone onClick={() => setOpen(!open)} device={user.device.model !== null ? "true" : "false"} away={user.away.toString()}/>
              </Tooltip>
            : <Tooltip title={"kein Abstimmgerät hinterlegt"}>
                <StyledDoNotDisturbAltIcon color="error" onClick={() => setOpen(!open)} device={user.device.model !== null ? "true" : "false"}/>
              </Tooltip>
            }
          </DeviceContainer>
          <div>
            {user.owner.name}
          </div>
          <SeatButtonContainer>
            <SeatVoteButtons>
              <IconButton onClick={() => setVoteOnSeat(1)}>
                <Check color="success" />
              </IconButton>
              <IconButton onClick={() => setVoteOnSeat(-1)}>
                <Close color="error"/>
              </IconButton>
              <IconButton onClick={() => setVoteOnSeat(0)}>
                <Replay color="info"/>
              </IconButton>
            </SeatVoteButtons>
            
            
            
          </SeatButtonContainer>
          <SeatCheckIcon show={reduxVote.results[user.seat] > 0  ? 'true' : 'false'} />
          <SeatCloseIcon show={reduxVote.results[user.seat] < 0  ? 'true' : 'false'} />
        </UserItemContent>
        <Collapse in={open}>
          <div style={{
            padding: "3px",
          }}>
            <div>{user.device.model !== null ? user.device.model.title : "Kein Gerät hinterlegt"}</div>
            <div>
              {user.device.connected ? "verbunden" : "nicht verbunden"}
            </div>
            <div>
              {user.away ? "nicht angemeldet" : "angemeldet"}
            </div>
            <div>
              <Button size="small" onClick={()=>console.log(user)}>Print User</Button>
              <Button onClick={handleUserAway}>Toggle Away</Button>
            </div>
          </div>
          
        </Collapse>
      </UserItemRoot>
  );

  return(
    <UserItemRoot voted={reduxVote.results[index] > 0 ? "pro" : reduxVote.results[index] < 0 ? "contra" : null}>
      <DeviceContainer connected={user.device.connected.toString()}>
        {user.deviceId !== null 
        ? <Tooltip title={user.device.title}>
            <StyledSmartphone />
          </Tooltip>
        : <Tooltip title={"kein Abstimmgerät hinterlegt"}>
            <StyledSmartphone sx={{opacity: "0"}}/>
          </Tooltip>
        }
        
      </DeviceContainer>
      <div>
        {user.owner.name}
      </div>
      <SeatButtonContainer>
        <SeatVoteButtons>
          <IconButton onClick={() => setVoteOnSeat(1)}>
            <Check color="success" />
          </IconButton>
          <IconButton onClick={() => setVoteOnSeat(-1)}>
            <Close color="error"/>
          </IconButton>
          <IconButton onClick={() => setVoteOnSeat(0)}>
            <Replay color="info"/>
          </IconButton>
        </SeatVoteButtons>
        
        <SeatCheckIcon show={userProps.pro ? 'true' : 'false'} />
        <SeatCloseIcon show={userProps.contra ? 'true' : 'false'} />
        
      </SeatButtonContainer>
    </UserItemRoot>
  );
}

export default UserItem;