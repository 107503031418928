import React from "react";
import { 
  ThemeProvider, 
  useTheme, 
} from '@mui/material';
import styled from "@emotion/styled";



const GridTableRoot = styled.table((props) => ({
  display: "grid",
  width: "100%",
  gridTemplateColumns: props.colsTemplate ? props.colsTemplate : `repeat(${props.cols}, auto)`,
  //gridTemplateColumns: props.cols ? `repeat(${props.cols}, 1fr)` :  "1fr 1fr 1fr 1fr 1fr auto",
    //gap: "10px 5px",
    //backgroundColor: "rgba(15, 175, 20, 0.4)",
    //height: "100%",
  borderCollapse: "collapse",
  "& thead, tbody, tr": {
    display: "contents",
  },
  "& th, td": {
    padding: "10px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  "& th": {
    position: "sticky",
    top: "0",
    background: "#cccccc",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: "1.1rem",
    color: "black",
  },
  "& th:last-child": {
    border: "0",
  },
  "& td": {
    paddingTop: "10px",
    paddingBottom: "10px",
    color: "#808080",
  },
  "& tr:nth-of-type(even) td": {
    background: "#e7e7e7",
  },
  "& tr:nth-of-type(odd) td": {
    //background: "#c3c3c3",
  },
}));

const GridTable = (props) => {

  const {
    children,
    cols=6,
    colsTemplate=false
  } = props;

  const theme = useTheme();

  return(
    <ThemeProvider theme={theme}>
      <GridTableRoot cols={cols} colsTemplate={colsTemplate}>
        {children}
      </GridTableRoot>
    </ThemeProvider>
  );
}

export default GridTable;