import React, { useEffect } from 'react';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
import styled from '@emotion/styled';

const ListItemRoot = styled.div((props) => ({
  display: "flex",
  borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
  gap: "1rem",
  padding: "1rem 1rem",
  position: "relative",
  transition: "background-color 0.2s",
}));

const StyledIcon = styled(SmartphoneOutlinedIcon)((props) => ({
  fontSize: "3rem",
  opacity: "0.5",
  color: props.outdated === "true" ? "red" : "unset",
}));

const DateInfo = styled.div((props) => ({
  position: "absolute",
  top: "0",
  right: "0",
  fontSize: "0.75rem",
  gap: "0.5rem",
}))

const InfoElement = styled.div((props) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& div:first-of-type": {
    fontSize: "1.25rem",
  },
  "& div:last-of-type": {
    opacity: "0.5",
  }
}))

const DeviceListItem = (props) => {

  const {
    element,
    owners,
  } = props;

  const [device, setDevice] = React.useState(null);

  useEffect(() => {
    setDevice(element);
  }, [element, owners])

  if(device === null) return null;

  return(
    <ListItemRoot>
      <StyledIcon/>
      <DateInfo>
        <div>{new Date(device.createdAt).toLocaleTimeString()}</div>
        <div>{new Date(device.createdAt).toLocaleDateString()}</div>
      </DateInfo>
      <InfoElement>
        <div>{device.title}</div>
        <div>Titel</div>
      </InfoElement>
    </ListItemRoot>
  );
}

export default DeviceListItem;