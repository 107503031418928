import styled from "@emotion/styled";
import { Button, Checkbox, FormControlLabel, MenuItem, TextField, Tooltip, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import objectService from "../../services/object.service";
import MyButton from "../../layout-components/MyButton/MyButton";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import 'moment/locale/de';
import { HomeOutlined } from "@mui/icons-material";
import moment from "moment";
import voteService from "../../services/vote.service";
import MyPage from "../../layout-components/MyPage";
import MyPageContent from "../../layout-components/MyPage/MyPageContent";
import MyPageTitle from "../../layout-components/MyPage/MyPageTitle";
import GridTable from "../../layout-components/GridTable";
import GridTableHead from "../../layout-components/GridTable/GridTableHead";
import SaveIcon from '@mui/icons-material/Save';
import texts from "../../texts";
import InfoIcon from '@mui/icons-material/Info';


const AddNextVoteRoot = styled.div((props) => ({
  width: "calc(100% - 2rem)",
  height: "calc(100% - 2rem)",
  padding: "1rem",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
}));

const AddNextVoteHeader = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: props.theme.palette.primary.main,
  background: "white",
  background: `linear-gradient(90deg, ${props.theme.palette.primary.light} 0%, ${props.theme.palette.secondary.main} 75%)`,
  padding: "1rem",
  borderRadius: "0.5rem",
}));

const AddNextVoteHeaderButtons = styled.div((props) => ({
  display: "flex",
  gap: "10px",
  marginLeft: "auto",
  marginRight: "0px",
  minWidth: "500px"
}));

const AddNextVoteContent = styled.div((props) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gridTemplateRows: "1fr 1fr",
  gridTemplateAreas: `
    "infos date"
    "object date"
  `,
  gap: "1rem 1rem",
  height: "100%",
  width: "100%",
  overflow: "hidden",
}));

const AddMeetingObjectContainer = styled.div((props) => ({
  gridArea: "object",
  border: "1px solid rgba(0, 0, 0, .2)",
  borderRadius: "1rem",
  height: "calc(100% - 2px - 2rem)",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  paddingBottom: "1rem",
}))

const AddMeetingObjectTitle = styled.div((props) => ({
  display: "flex",
  gap: "0.5rem",
  opacity: "0.7",
  alignItems: "center",
  padding: "0.5rem",
}));

const CustomActionBar = () => {
  return(
    null
  )
}

const CustomToolBar = (props) => {
  
  const [value, setValue] = React.useState(new Date());

  useEffect(() => {
    if(props.value){
      var date = new Date(props.value);
      setValue(date);
    }
    
  }, [props.value]);

  return(
    <div style={{
      gridColumn: "2/4",
      gridRow: "1",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      gap: "0.5rem",
      alignItems: "center",
      }}>
      <div style={{fontSize: "1.3rem"}}>{value.toLocaleDateString()}</div>
      <div style={{fontSize: "0.8rem", opacity: "0.7", fontStyle: "italic"}}>Ausgewähltes Datum</div>
    </div>
  )
}

const Module = styled.div((props) => ({
  gridArea: props.area,
  border: "1px solid rgba(0, 0, 0, 0.2)",
  borderRadius: "0.5rem",
  display: "flex",
  flexDirection: "column",
  //height: "100%",
  //width: "100%",
}));

const ModuleTitle = styled.div((props) => ({
  display: "flex",
  gap: "0.5rem",
  opacity: "0.7",
  alignItems: "center",
  padding: "0.5rem",
}));

const ModuleContent = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  padding: "0.5rem",
  height: "100%",
}))

const StyledMultirowTextField = styled(TextField)((props) => ({
  height: "100%",
  //border: "1px solid red",
  "& .MuiInputBase-root": {
    height: "100%",
  },
  "& textarea": {
    height: "100% !important",
  }
}))

const StyledUl = styled.ul((props) => ({
  //marginLeft: "0",
  paddingLeft: "0.75rem",
  fontSize: "0.9rem",
}))

const StyledLi = styled.li((props) => ({
  marginTop: "0.5rem",
  marginBottom: "0.25rem",
}))

const AddMeeting = () => {

  const [objectId, setObjectId] = React.useState("");
  const [object, setObject] = React.useState(null);
  const [objects, setObjects] = React.useState([]);
  const [votes, setVotes] = React.useState([]);
  const [vote, setVote] = React.useState(
    {
      title: "",
      description: "",
      date: moment(),
      subvotes: [],
      headvote: null,
      type: "meeting",
      incognito: false,
    }
  )

  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();


  useEffect(() => {
    var hash = location.hash.slice(1);
    if(hash !== ""){
      setObjectId(hash);
    }
    init();
  }, [location]);

  useEffect(() => {
    if(objectId !== "" && objectId !== "-"){
      objectService.getOne(objectId)
        .then((response) => {
          setObject(response.data);
        })
    } else {
      setObject(null);
    }
  }, [objectId]);

  const init = () => {
    objectService.getAll()
      .then((response) => {
        setObjects(response);
      })
  }

  const handleSave = () => {

    voteService.create(vote, objectId)
      .then((response) => {
        navigate("/");
      })
  }

  const handleUpdateVote = (e, attr) => {
    var _vote = {...vote};
    _vote[attr] = e.target.value;
    setVote(_vote);
  }

  const handleDateChanged = (date) => {
    var _vote = {...vote};
    _vote.date = date;
    setVote(_vote);
  }

  const handleChangeObjectId = (e) => {
    setObjectId(e.target.value);
  }

  const handleIncognitoChanged = (e) => {
    var checked = e.target.checked;
    console.log(checked);
    //(e) => setVote({...vote, incognito: e.target.checked})
  }

  return(
    <MyPage>
      <MyPageTitle>
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}>
          <div>
            {texts.meetings.h_add}
          </div>
          <div style={{
            display: "flex",
            marginRight: "0",
            marginLeft: "auto",
            gap: "1rem",
          }}>
            <Button text="Abbrechen" variant="outlined" color={"error"} onClick={() => {navigate("/")}} fullWidth>Abbrechen</Button>
            <Button color="primary" onClick={handleSave} startIcon={<SaveIcon />} variant="contained" fullWidth>Speichern</Button>
          </div>
        </div>
      </MyPageTitle>
      <MyPageContent>
        <AddNextVoteContent>

          <Module area="infos">
            <ModuleTitle>Details</ModuleTitle>
            <ModuleContent>
              <TextField label="Titel" value={vote.title} onChange={(e) => handleUpdateVote(e, "title")}/>
              <StyledMultirowTextField label="Beschreibung" multiline value={vote.description} onChange={(e) => handleUpdateVote(e, "description")}/> 
              <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}>
                <FormControlLabel label="Namentliche Abstimmung" control={<Checkbox checked={!vote.incognito} onChange={(e) => setVote({...vote, incognito: !e.target.checked})}/>} />
                <Tooltip title={
                  <StyledUl>
                    <StyledLi>Namentliche Abstimmung</StyledLi>
                    <ul>
                      <li>Die Namen der Eigentümer werden bei der Abstimmung angezeigt</li>
                    </ul>
                    <StyledLi>Nicht namentliche Abstimmung</StyledLi>
                    <ul>
                      <li>Die Namen der Eigentümer werden bei der Abstimmung nicht angezeigt</li>
                    </ul>
                  </StyledUl>
                }>
                  <InfoIcon color="info" />
                </Tooltip>
                
              </div>
            </ModuleContent>
          </Module>

          <Module area="date">
            <ModuleTitle>Datum</ModuleTitle>
            <ModuleContent>
              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
                <StaticDatePicker 
                  label="Voraussichtliches Datum" 
                  //orientation="landscape" 
                  disablePast
                  onChange={handleDateChanged}
                  value={vote.date}
                  slots={{
                    // Override default <ActionBar /> with a custom one
                    actionBar: CustomActionBar,
                    toolbar: CustomToolBar,
                  }}
                />
              </LocalizationProvider> 
            </ModuleContent>
          </Module>

          <Module area="object">
            <ModuleTitle>Objekt</ModuleTitle>
            <ModuleContent>
              <TextField 
                select 
                fullWidth 
                defaultValue={"-"} 
                required
                value={objectId}
                onChange = {handleChangeObjectId}
              >
                <MenuItem value="-">-</MenuItem>
                {objects.map((objectItem, index) => 
                  <MenuItem value={objectItem.id} key={index} disabled={objectItem.outdated}>
                    {objectItem.number} | {objectItem.name}
                  </MenuItem>
                )}
              </TextField>
            </ModuleContent>
          </Module>

        
          
        </AddNextVoteContent>
      </MyPageContent>
    </MyPage>
  );

}

export default AddMeeting;