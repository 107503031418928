import { createSlice } from '@reduxjs/toolkit'

export const meetingSlice = createSlice({
  name: 'meeting',
  initialState: null,
  reducers: {
    setMeeting: (state, action) => {
      //state = action.payload;
      return {
        ...action.payload
      };
    },
    setMeetingDevices: (state, action) => {
      state.users = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setMeeting, setMeetingDevices } = meetingSlice.actions

export default meetingSlice.reducer