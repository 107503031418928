import React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import HourglassBottomOutlinedIcon from '@mui/icons-material/HourglassBottomOutlined';
import { keyframes } from '@emotion/react';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { Button, IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const MyVoteStateModulRoot = styled.div((props) => ({
  backgroundColor: "#cccccc",
  borderRadius: "2rem 0rem 0rem 2rem",
  minHeight: "50px",
  minWidth: "50px",
  position: "absolute",
  bottom: "1rem",
  right: props.minimize === "true" ? "-320px" : "0",
  padding: "1rem",
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  border: `2px solid ${props.theme.palette.primary.main}`,
  zIndex: "99",
  transition: "right 0.5s",
}));

const StyledHourglassEmptyOutlinedIcon = styled(HourglassBottomOutlinedIcon)((props) => ({
  fontSize: "2rem",
  color: props.theme.palette.primary.main,
  padding: "0.25rem",
  animation: `${RotateAnimation} 2s infinite`,
}))

const StyledChevron = styled(ArrowForwardIosIcon)((props) => ({
  transform: props.minimize === "true" ? "rotate(180deg)" : "rotate(0deg)",
  transition: "transform 0.5s",
}))


const RotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg)
  }
`;

const MyVoteStateModul = () => {
  
  const [minimize, setMinimize] = React.useState(false);

  const auth = useSelector((state) => state.auth);
  const reduxVote = useSelector((state) => state.vote);
  const reduxMeeting = useSelector((state) => state.meeting);
  const navigate = useNavigate();
  const location = useLocation();

  if((!reduxVote || !reduxVote.id)) return null;
  if(reduxMeeting.model !== undefined && !reduxMeeting.model.processing) return null;
  
  console.log("minimie", minimize);

  if(location.pathname === "/run") return null;

  /* unterscheidung zwischen vote und meeting */
  if(reduxMeeting){
    //meeting
    
    return(
      <MyVoteStateModulRoot minimize={minimize.toString()}>
        
        <IconButton onClick={() => setMinimize(!minimize)}>
          <StyledChevron minimize={minimize.toString()}/>
        </IconButton>
        
       

        <StyledHourglassEmptyOutlinedIcon/>
        
        <div style={{display: "flex", flexDirection: "column", gap: "0.5rem"}}>
          <div style={{fontSize: "1.1rem", color: "black",}}>Es läuft aktuell eine Sitzung!</div>
          <div style={{
            fontStyle: "italic",
          }}>
            {reduxMeeting.model ? reduxMeeting.model.title : null}
          </div>
          <div style={{display: "flex", justifyContent: "center", marginTop: "1rem"}}>
            
            <Button endIcon={<ArrowForwardOutlinedIcon />} onClick={()=>navigate(`meeting#${reduxMeeting.id}`)} color="secondary" variant='contained' fullWidth> 
              Zur Sitzung
            </Button>
          </div>
        </div>
      </MyVoteStateModulRoot>
    );
  }

  if(reduxVote){
    //vote
    if(location.pathname === "/vote") return null;
    return(
      <MyVoteStateModulRoot>
        
        <StyledHourglassEmptyOutlinedIcon/>
        
        <div style={{display: "flex", flexDirection: "column", gap: "0.5rem"}}>
          <div style={{fontSize: "1.1rem", color: "black",}}>Es läuft aktuell eine Abstimmung!</div>
          <div style={{
            fontStyle: "italic",
          }}>
            {reduxVote.model ? reduxVote.model.title : null}
          </div>
          <div style={{display: "flex", justifyContent: "center", marginTop: "1rem"}}>
            
            <Button endIcon={<ArrowForwardOutlinedIcon />} onClick={()=>navigate(`vote#${reduxVote.id}`)} color="secondary" variant='contained' fullWidth> 
              Zur Abstimmung
            </Button>
          </div>
        </div>
      </MyVoteStateModulRoot>
    );
  }

  
  
}

export default MyVoteStateModul;