import { createSlice } from '@reduxjs/toolkit'

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState: {},
  reducers: {
    addAlert: (state, action) => {
      var ts = Date.now()
      state[ts] = action.payload;
    },
    removeAlert: (state, action) => {
      var elements = state;
      delete elements[action.payload];
      return elements;
    }
  },
});

// Action creators are generated for each case reducer function
export const { addAlert, removeAlert } = alertsSlice.actions

export default alertsSlice.reducer