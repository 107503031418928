import styled from "@emotion/styled/macro";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

const StyledCheckContainer = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "2px",
  backgroundColor: props.theme.palette.success.main,
  color: "white",
}));

const ContraResultIcon = () => {
  return(
    <StyledCheckContainer>
      <CloseIcon />
    </StyledCheckContainer>
  );
}

export default ContraResultIcon;