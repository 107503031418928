/*import MainMenu from "./routes/MainMenu";*/
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import styled from '@emotion/styled';

const MySideBarMenuItemRoot = styled.div((props) => ({
  padding: "2vh 1vh",
  borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
  cursor: "pointer",
  transition: "background-color 0.25s, color 0.25s",
  backgroundColor: props.active === "true" ? "rgba(0, 0, 0, 0.2)" : "unset",
  color: props.active === "true" ? "rgba(0, 0, 0, 0.9)" : "unset",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
}));

const MySideBarMenuItemLabel = styled.div({
  display: "flex",
  gap: "13px",
  alignItems: "center",
  position: "relative",
});

const MySideBarMenuItemLabelUnderline = styled.div({
  position: "absolute",
  left: "50%",
  bottom: "-2px",
  width: "0px",
  height: "1px",
  background: "#333",
  transition: "all 0.45s",
  "&.active": {
    width: "100%",
    left: "0",
  }
});

const MySideBarMenuItemIcon = styled.div({
  opacity: "0.5",
});

const MySideBarMenuItem = (props) => {

  const {title, icon, route, open} = props;
  const [active, setActive] = React.useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(()=>{
    if(location.pathname === route){
      setActive(true);
    } else {
      setActive(false);
    }
  }, [location.pathname]);

  return(
    <MySideBarMenuItemRoot active={active.toString()} onClick={() => navigate(route)}>
      <MySideBarMenuItemLabel>
        <MySideBarMenuItemIcon>
          {icon}
        </MySideBarMenuItemIcon>
        <div style={{
          width: open ? "100%" : "0px",
          overflow: "hidden",
          transition: "width 0.25s",
        }}>
          {title}
        </div>
        <MySideBarMenuItemLabelUnderline />
      </MySideBarMenuItemLabel>
    </MySideBarMenuItemRoot>
  );

}

export default MySideBarMenuItem;