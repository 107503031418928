import axios from "axios";
import {store} from '../redux/store';

const api = process.env.REACT_APP_API_URL;
const dacorAuthToken = process.env.REACT_APP_AUTH_TOKEN;

/*
  Zugriffsbeschränkungen
  Der Zugriff auf Resourcen, die auf dem Server liegen, zu beschränken, werden zwei verschiedene Tokens verwendet.
  1. DacorAuthToken: Der DacorAuthToken beschränkt den Zugriff auf alle Resourcen. Dieser Token muss immer im Header stehen, 
    egal ob die Resourcen "öffentlich", also auch ohne Login, oder "privat", also nur mit Login, ist.
  2. AccessToken: Dieser Token wird durch das Einloggen des Benutzers zur Verfügung gestellt. Damit können Resourcen abgefragt werden,
    die sich auf den Mandanten beziehen, zu dem sie gehören.
*/



const getOne = (id) => {
  var state = store.getState();

  return axios
    .get(`${api}/object/one/${id}`, {
      headers: {
        "x-dacor-auth-token": dacorAuthToken,
        "x-access-token": state.auth.token
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    })
}

const getAll = () => {
  var state = store.getState();

  return axios
    .get(`${api}/user/object/all`, {
      headers: {
        "x-dacor-auth-token": dacorAuthToken,
        "x-access-token": state.auth.token
      }
    })
    .then((response) => {
      return response.data.data;
    })
    
    .catch((e) => {
      console.log(e);
    })
}

const createOrUpdate = (object) => {

  var state = store.getState();

  return axios
    .post(`${api}/object/add`, object, {
      headers: {
        "x-dacor-auth-token": dacorAuthToken,
        "x-access-token": state.auth.token
      }
    })
    .then((response) => {
    })
    .catch((e) => {
      console.log(e);
    })
}

const getWithNextVote = () => {
  var state = store.getState();

  return axios
    .get(`${api}/user/object/next`, {
      headers: {
        "x-dacor-auth-token": dacorAuthToken,
        "x-access-token": state.auth.token
      }
    })
    .then((response) => {
      return response.data.data;
    })
    
    .catch((e) => {
      console.log(e);
    })
}

const getAllVotes = () => {
  return [];
}

const search = (props) => {
  var state = store.getState();
  
  return axios
    .post(`${api}/user/object/search`, {}, {
      headers: {
        "x-dacor-auth-token": dacorAuthToken,
        "x-access-token": state.auth.token
      }
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((e) => {
      console.log(e);
    })
}

const importNewData = (data) => {
  var state = store.getState();
  
  return axios
    .post(`${api}/user/object/import`, data, {
      headers: {
        "x-dacor-auth-token": dacorAuthToken,
        "x-access-token": state.auth.token
      }
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((e) => {
      console.log(e);
    })
}

const update = (object) => {
  var state = store.getState();

  console.log("object", object);

  return axios
    .post(`${api}/user/object/update`, object, {
      headers: {
        "x-dacor-auth-token": dacorAuthToken,
        "x-access-token": state.auth.token
      }
    })
}

export default{
  getOne,
  getAll,
  createOrUpdate,
  getAllVotes,
  getWithNextVote,
  search,
  importNewData,
  update
}