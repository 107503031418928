import React, { useEffect } from 'react';
import { useTheme } from '@mui/material';
import { getImage } from '../../services/Index';
import _default from './../../images/default.png';
import styled from '@emotion/styled';

const MyImageRoot = styled.div((props) => ({
  height: props.fullsize ? "100%" : props.dim.height,
  width: props.fullsize ? "100%" : props.dim.width,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
  "&.loading": {
    //backgroundColor: "red",
  },
  "& img": {
    objectFit: "contain",
    maxWidth: "100%",
    maxHeight: "100%",
  }
}));

const MyImage = (props) => {
  //#region props
  const {
    src=null,
    size,
    fullsize=false,
    online=null,
  } = props;
  //#endregion

  //#region states
  const [image, setImage] = React.useState(_default);
  const [dim, setDim] = React.useState({height: null, width: null});
  //#endregion

  //region hooks
  const theme = useTheme();

  //endregion


  //#region functions
  useEffect(() => {
    init();
  },[src, size]);


  const init = async() => {

    var _dim = {};

    if(size !== null){

      _dim.width=`${size}px`;
      _dim.height=`${size}px`;

    } else {

      if(src.height > src.width){
        _dim.height = Number(size);
        _dim.width = Number(src.width) / (Number(src.height) / Number(size));
      } else {
        _dim.width = Number(size);
        _dim.height = Number(src.height) / (Number(src.width) / Number(size));
      }

    }
    setDim(_dim);

    if(src !== null){
      var response = await getImage(src.id);
      setImage(response);
    } else {
      setImage(_default);
    }
    /*if(src.height > src.width){
      _dim.height = Number(size);
      _dim.width = Number(src.width) / (Number(src.height) / Number(size));
    } else {
      _dim.width = Number(size);
      _dim.height = Number(src.height) / (Number(src.width) / Number(size));
    }
    setDim(_dim);
    var response = await getImage(src.id);
    setImage(response);
    setLoading(false);*/
  }
  //#endregion

  return(
    <MyImageRoot fullsize={fullsize} dim={dim}>
      
      <div style={{
        position: "relative",
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>

        <img src={image} style={{border: "1px solid rgba(0, 0, 0, 0.2)"}} alt="Mandant Logo" />

        {online !== null
        ? <div style={{
          height: "9px",
          width: "9px",
          borderRadius: "100%",
          bottom: "2px",
          right: "-5px",
          backgroundColor: online ? "green" : "red",
          position: "absolute",
        }}></div>

        : null
        }
      </div>
      
    </MyImageRoot>
  );

}

export default MyImage;