import React from 'react';

import { Slide, AppBar, Breadcrumbs, Link, Typography } from '@mui/material';
import LinearIndeterminate from '../MyProgress/MyProgress';
import { useNavigate } from 'react-router-dom';

import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';


const MyPageRoot = styled.div((props) => ({
  height: "calc(100% - 2vh)",
  width: "calc(100% - 2vh)", 
  display: "flex", 
  flexDirection: "column", 
  overflow: "hidden",
  padding: "1vh",
  gap: "1rem",
  trasition: "padding 0.5s, height 0.5s, width 0.5s",
}));


const MyPage = (props) => {

  const theme = useTheme();
  const navigate = useNavigate();

  const classes = {};

  const {
    children,
    title,
    loading,
    fullsize,
    noback=true,
  } = props;

  const handleClick = () => {
    navigate(-1);
  }

  return(
    <MyPageRoot>
      {children}
    </MyPageRoot>
  );

}

export default MyPage;