import styled from '@emotion/styled';

const MyPageContentRoot = styled.div((props) => ({
  height: "100%",
  width: "100%",
  overflowY: "auto",
}));

const MyPageContent = (props) => {
  
  const {children, style} = props;

  return(
    <MyPageContentRoot style={style}>
      {children}
    </MyPageContentRoot>
  );
}

export default MyPageContent;