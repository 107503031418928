var meetings = {};

meetings = {
  sing: "Versammlung",
  plu: "Versammlungen",
  h_plu: "Geplante Versammlungen",
  h_add: "Neue Versammlung anlegen",
  add: "Neue Versammlung",
  b_run: "Versammlung durchführen",
}

export default meetings;