import React, { useEffect } from "react";
import objectService from "../../services/object.service";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MyPage from "../../layout-components/MyPage";
import MyPageContent from "../../layout-components/MyPage/MyPageContent";
import MyPageTitle from "../../layout-components/MyPage/MyPageTitle";
import AddIcon from '@mui/icons-material/Add';
import MeetingItem from "./MeetingItem";
import texts from "../../texts";

const Meetings = () => {
  
  const [objects, setObjects] = React.useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    init();
  }, [])

  const init = () => {
    objectService.getWithNextVote()
      .then((response) => {
        setObjects(response);
      })
      .catch((e) => {
        console.log(e);
      })
  }

  return(
    <MyPage>
      <MyPageTitle>
        {texts.meetings.h_plu}
      </MyPageTitle>
      <MyPageContent style={{display: "flex", flexDirection: "column", gap: "0.5rem"}}>
        <div style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "1rem",
        }}>
          <Button variant="contained" startIcon={<AddIcon />} onClick={() => navigate("/meeting/add")} fullWidth={false}>
          {texts.meetings.add}
          </Button>
        </div>
        <div style={{
          height: "1px",
          backgroundColor: "rgba( 0, 0, 0, 0.2)",
        }}/>
          {objects === undefined || objects.length <= 0
          ? <div>
              {/*Keine Sitzungen geplant!*/}
            </div>
          : objects.map((object) =>
              <MeetingItem key={object.id} element={object} />
            )
          }
      </MyPageContent>
    </MyPage>
  );
}

export default Meetings;