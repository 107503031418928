import { setSocket, setId } from "../redux/slices/socket.slice";
import { prepare, setResults, startVote, votePrepared, setVote } from "../redux/slices/voteSlice";
import sendMessages from "./SendMessages";

class VoteHandler {

  constructor(dispatch, getState){
    this.messageHandler = null;
    this.dispatch=dispatch;
    this.getState=getState;
  }

  /* 
    first message when connected: connected response -> send approve 
  */

  attachMessageHandler(messageHandler){
    this.messageHandler = messageHandler;
  }

  initMeeting(data){
    this.messageHandler.sendMessage({type: "init meeting", data: data});
  }

  startMeeting(data){
    this.messageHandler.sendMessage({type: "start meeting", data: data});
  }

  stopMeeting(data){
    this.messageHandler.sendMessage({type: "stop meeting", data: data});
  }

  endMeeting(data){
    this.messageHandler.sendMessage({type: "end meeting", data: data});
  }

  startVote(data){
    this.messageHandler.sendMessage({type: "start vote", data: data.voteId});
  }

  stopVote(data){
    this.messageHandler.sendMessage({type: "stop vote", data: data.voteId});
  }

  endVote(data){
    this.messageHandler.sendMessage({type: "end vote", data: data.voteId});
  }

  reopenVote(data){
    this.messageHandler.sendMessage({type: "reopen vote", data: data.voteId});
  }

  nextVote(data){
    this.messageHandler.sendMessage({type: "meeting next vote"});
  }

  toogleAwayUser(data){
    console.log("data", data);
    this.messageHandler.sendMessage({type: "user away toggle", data: data});
  }

  manualVote(data){
    this.messageHandler.sendMessage({
      type: "manuel vote",
      seatnum: data.seatnum,
      value: data.value,
    })
  }

  findVoteRes(message){
    var state;
    if(message.status === 200){
      state = this.getState();
      this.dispatch(prepare({...state.vote.prepared, ...{found: true}}));
    } else {
      this.dispatch(prepare({...state.vote.prepared, ...{found: false}}));
    }
  }

  checkProcessingRes(message){
    var state;
    if(message.status === 200){
    this.dispatch(setVote(message.data)); 
    }
  }

  layoutRes(message){
    var state;
    if(message.status === 200){
      state = this.getState();
      this.dispatch(prepare({...state.vote.prepared, ...{layout: true}}));
    } else {
      this.dispatch(prepare({...state.vote.prepared, ...{layout: false}}));
    }
  }

  usersRes(message){
    var state;
    if(message.status === 200){
      state = this.getState();
      this.dispatch(prepare({...state.vote.prepared, ...{users: true}}));
    } else {
      this.dispatch(prepare({...state.vote.prepared, ...{users: false}}));
    }
  }

  devicesRes(message){
    var state;
    if(message.status === 200){
      state = this.getState();
      this.dispatch(prepare({...state.vote.prepared, ...{devices: true}}));
    } else {
      this.dispatch(prepare({...state.vote.prepared, ...{devices: false}}));
    }
  }

  tbConnectRes(message){
    var state;
    if(message.status === 200){
      state = this.getState();
      this.dispatch(prepare({...state.vote.prepared, ...{tbConnect: true}}));
    } else {
      this.dispatch(prepare({...state.vote.prepared, ...{tbConnect: false}}));
    }
  } 

  voteInitRes(message){
    var state;
    if(message.status === 200){
      state = this.getState();
      this.dispatch(prepare({...state.vote.prepared, ...{overall: true}}));
      this.dispatch(votePrepared());
    } else {
      this.dispatch(prepare({...state.vote.prepared, ...{overall: false}}));
    }
  }

  resultsChanged(message){
    if(message.status === 200){
      this.dispatch(setResults(message.data));
    }
  }

  

}

export { VoteHandler }