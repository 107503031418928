import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: null,
    username: null,
    id: null,
    mandant: null,
    authed: null,
  },
  reducers: {
    setAuth: (state, action) => {
      state.token = action.payload.accessToken;
      state.username = action.payload.username;
      state.id = action.payload.id;
      state.mandant = action.payload.mandant;
      state.authed = action.payload.authed;
    },
    clearAuth: (state) => {
      state.token = null;
      state.username = null;
      state.id = null;
      state.mandant = null;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setAuth, clearAuth } = authSlice.actions

export default authSlice.reducer