import React, { useEffect, useRef } from "react";
import objectService from "../../services/object.service";
import GridTable from "../../layout-components/GridTable";
import GridTableHead from "../../layout-components/GridTable/GridTableHead";
import { Checkbox, FormControlLabel, IconButton, TextField, useTheme, Tooltip, Button, Modal, Fade, Box, Typography, Backdrop, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Collapse } from "@mui/material";
import voteService from "../../services/vote.service";
import MyButton from "../../layout-components/MyButton/MyButton";
import { useLocation, useNavigate } from "react-router-dom";
import SecondarySidebar from "../../layout-components/SecondarySidebar";
import styled from '@emotion/styled';
import MyPage from "../../layout-components/MyPage";
import MyPageContent from "../../layout-components/MyPage/MyPageContent";
import MyPageTitle from "../../layout-components/MyPage/MyPageTitle";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AddIcon from '@mui/icons-material/Add';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import texts from "../../texts";
import communityService from "../../services/community.service";
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import GroupsIcon from '@mui/icons-material/Groups';
import { Check, Close } from '@mui/icons-material';

const SubVoteRoot = styled.div((props) => ({
  display: "contents",
}));

const SubVoteIndex = styled.div((props) => ({
  padding: "1rem",
  backgroundColor: props.theme.palette.primary.main,
  color: props.theme.palette.secondary.main,
  borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
  display: "flex",
  //alignContent: "center",
}));

const SubVoteContainer = styled.div((props) => ({
  borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
  display: "flex",
  alignItems: "center",
  paddingLeft: "7px",
  paddingRight: "7px",
  gap: "5px",
  //gap: "0.5rem",
}));

const SubVoteTitle = styled.div((props) => ({
  textDecoration: "underline",
  cursor: "pointer",
}));

const SubVoteNoDescription = styled.div((props) => ({
  opacity: "0.7",
  fontSize: "0.9rem",
  fontStyle: "italic"
}));

const getItemStyle = (isDragging, draggableStyle, theme) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  
  // change background colour if dragging
  background: isDragging ? theme.palette.secondary.main : null,
  //border: isDragging ? "15px solid #ffcc00" : null,
  
  // styles we need to apply on draggables
  ...draggableStyle
})

const getPlaceholderStyle = (isDragging) => ({
  display: isDragging ? "flex" : "none",
  backgroundColor: "unset"
})

const StyledCheckContainer = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "2px",
  backgroundColor: props.theme.palette.success.main,
  color: "white",
}))

const StyledCrossContainer = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "2px",
  backgroundColor: props.theme.palette.error.main,
  color: "white",
}))

const SubVoteItem = (props) => {

  const {
    subvote,
    index,
    handleRemove,
    meeting
  } = props;

  const [open, setOpen] = React.useState(false);
  const [vote, setVote] = React.useState(null);

  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    if(subvote){
      voteService.getOne(subvote.id)
        .then((res) => setVote(res.data))
        .catch((e) => console.log(e));
    }
  }, [subvote])

  const overallResult = () => {
    return 0;
  }

  return(
    <Draggable key={`${subvote.id}`} draggableId={`${subvote.id}`} index={index}>
      {(provided,snapshot) => (
        <SubVoteRoot>
          <Dialog open={open} onClose={()=> setOpen(false)}>
            <DialogContent>
              {subvote.description}
            </DialogContent>
          </Dialog>
          <SubVoteIndex 
            {...provided.dragHandleProps}
            ref={provided.innerRef} 
            {...provided.draggableProps} 
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
              theme
            )}
          >
            {index+1}
          </SubVoteIndex>
          <SubVoteIndex 
            style={getPlaceholderStyle(
              snapshot.isDragging,
            )}
          >
          </SubVoteIndex>

          <SubVoteContainer>
            {subvote.done
            ? <Tooltip title="Bereits durchgeführt">
                <CheckCircleOutlineOutlinedIcon color="success" />
              </Tooltip>
            : <Tooltip title="Noch nicht durchgeführt">
                <CancelOutlinedIcon color="error" />
              </Tooltip>
            }
            {subvote.type === "qualified"
            ? <Tooltip title="Qualifizierter Beschluss">
                <NewReleasesIcon color="gray" />
              </Tooltip>
            : <NewReleasesIcon color="gray" sx={{opacity: "0"}}/>
            }
            {subvote.communities.length > 0
            ? <Tooltip title="Beschluss für Untergemeinschaften">
                <GroupsIcon color="gray" />
              </Tooltip>
            : <GroupsIcon color="gray" sx={{opacity: "0"}}/>
            }
            {subvote.success !== null
            ? subvote.success 
              ? <Tooltip title="Angenommen">
                  <StyledCheckContainer>
                    <Check />
                  </StyledCheckContainer>
                </Tooltip>
              :<Tooltip title="Abgelehnt">
                  <StyledCrossContainer>
                    <Close />
                  </StyledCrossContainer>
                </Tooltip>
            : null
            }
          </SubVoteContainer>

          <SubVoteContainer>
            <SubVoteTitle onClick={() => navigate(`/vote#${subvote.id}`)}>
              <div style={{
                whiteSpace: "nowrap",
              }}>
                {subvote.title}
              </div>
              
            </SubVoteTitle>   
          </SubVoteContainer>

          <SubVoteContainer 
            style={{overflow: "hidden", cursor: subvote.description ? "pointer" : "unset"}} 
            onClick={subvote.description ? ()=> setOpen(true) : ()=>{}}
          >
            <div style={{
              whiteSpace: "nowrap",
	            overflow: "hidden",
	            textOverflow:"ellipsis",
            }}>
              {subvote.description 
              ? subvote.description
              : <SubVoteNoDescription>
                  Keine Beschreibung
                </SubVoteNoDescription>
              }
            </div>
            
          </SubVoteContainer>

          <SubVoteContainer>
            {subvote.ended 
            ? <div> 
                <div>
                {new Date(subvote.ended).getDate().length < 2 ? `0${new Date(subvote.ended).getDate()}` : new Date(subvote.ended).getDate()}.
                {(new Date(subvote.ended).getMonth()+1).length < 2 ? `0${new Date(subvote.ended).getMonth()+1}` : new Date(subvote.ended).getMonth()+1}.
                {new Date(subvote.ended).getYear()+1900}
                </div>
                <div>
                {new Date(subvote.ended).toLocaleTimeString()}
                </div>
              </div>
            : null
            }
          </SubVoteContainer>

          <SubVoteContainer>
            <div style={{
              display: "flex",
              gap: "0.5rem",
              alignItems: "center",
            }}>
               
              <IconButton color="error" onClick={() => handleRemove(subvote)}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
              
              
            </div>
            
          </SubVoteContainer>
          
        </SubVoteRoot>
      )}
    </Draggable>
  );
}

export default SubVoteItem;