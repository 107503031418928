import React from 'react';
import { ThemeProvider, useTheme } from '@mui/material';
import {formats} from './Config';
import HeaderDefault from './Header/HeaderDefault';
import { useEffect } from 'react';


/*const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1em",
    //backgroundColor: "rgba(255, 0, 0, 0.2)",
    overflow: "hidden",
    height: "calc(100% - 2em)",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    borderBottom: "1px solid rgba(0,0,0,1)",
    padding: "0.5em",
    display: "flex",
    alignItems: "center",
  },
  list: {
      paddingTop: "0.5em",
      paddingBottom: "0.5em",
      width: "100%",
      borderBottom: "1px solid rgba(0,0,0,1)",
      overflow: "auto",
      height: "calc(100% - 1em)",
      "& .user:first-child":{
        borderTop: "1px solid rgba(0,0,0,0.2)",
      },
      "& .user:last-child":{
        borderBottom: "1px solid transparent",
      },
      "& .user":{
        width: "calc(100% - 2em)",
        display: "flex",
        //alignContent: "center",
        flexWrap: "nowrap",
        alignItems: "center",
        borderBottom: "1px solid rgba(0,0,0,0.2)",
        borderTop: "1px solid transparent",
        cursor: "pointer",
        "& .number":{
          order: "0",
          minWidth: "1em",
          textAlign: "center",
          minHeight: "1em",
        },
        "& .avatar":{
          flex: "",
          flexBasis: "auto",
          order: "1",
          display: "flex",
          alignItems: "center",
        },
        "& .infos": {
          flex: "",
          flexBasis: "auto",
          order: "2",
          flexGrow: "1",
          overflow: "hidden",
          "& >*":{
            //whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          },
          "& .name":{

          },
          "& .party" : {
            opacity: "0.5"
          },
        },
        "& .device":{
          flex: "",
          flexBasis: "auto",
          order: "3",
          marginLeft: "auto",
        },
        "& .buttons":{
          display: "inline-flex",
          flex: "",
          flexBasis: "auto",
          order: "4",
          marginLeft: theme.spacing(5),
          alignItems: "center",
          flexShrink: "0",
          "& .play":{
            color: theme.palette.primary.main,
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            fontSize: "2.5em",
            animationName: "ani",
            animationDuration: "10s",
            animationIterationCount: "infinite",
          },
          "& > *": {
            flex: "",
            opacity: "0.6",
            cursor: "pointer"
          },
          "& >*:hover":{
            opacity: "1"
          },
        },
        "&:hover":{
          marginLeft: theme.spacing(1.0),
          "& .play":{
            animationName: "slide-in-bck-left",
            animationDuration: "0.25s",
            animationTimingFunction: "ease",
            animationDelay: "0s",
            animationIterationCount: "1",
            animationDirection: "normal",
            animationFillMode: "forwards",
          },
        },
      },
      "&.big":{
        "& .user":{
          padding: "1em",
          "& .number":{
            padding: "0.35em 0.5em",
            border: "1px solid black",
            marginRight: theme.spacing(3),
            opacity: "0.5",
          },
          "& .avatar":{
            width: theme.spacing(6),
            height: theme.spacing(6),
            marginRight: theme.spacing(5),
          },
          "& .infos": {
            "& .name": {
              fontSize: "1.2em",
            },
            "& .party": {
              fontSize: "1em",
            }
          }
        },
      },
      "&.small": {
        "& .user":{
          padding: "0em 0.5em 0em 0.5em",
          "& .number":{
            marginRight: theme.spacing(1),
            opacity: "0.5",
          },
          "& .avatar":{
            width: theme.spacing(3),
            height: theme.spacing(3),
            marginRight: theme.spacing(2),
            "& *":{
              fontSize: "0.7em",
            },
          },
          "& .infos":{
            display: "inline-flex",
            flexGrow: "3",
            alignItems: "center",
            "& .name": {
              order: "0",
              fontSize: "1em",
            },
            "& .party": {
              display: "none",
              order: "1",
              marginLeft: "auto",
            },
          },
          "& .device": {
            width: "20%",
            textAlign: "right",
          },
        },
      },
  },
  newuser: {
    order: "2",
    marginLeft: "auto",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  listview: {
    "& .active": {
      color: "#ffcc00",
    },
    "& .notactive": {
      color: "rgba(0,0,0,0.5)",
    },
    "& .notactive:hover": {
      color: "#000"
    }
  }
}));*/

const MyList = (props) => {

  const theme = useTheme();

  //#region props
  const {
    elements=[], 
    format="vote", 
    addButton=null, 
    variant="big",
    component,
    searchFunction,
    searchProps=null,
  } = props;
  //#endregion

  //#region states
  const [view] = React.useState("big");
  const [items, setItems] = React.useState(null);
  //#endregion

  //#region hooks
  const classes = {};//useStyles();
  //#endregion

  useEffect(()=>{
    if(elements !== undefined && elements !== null && elements.length !== 0){
      setItems(elements);
    }
  }, [elements]);

  useEffect(() => {
    //setView(variant);
  }, [variant]);

  //#region functions
  const search = (string) => {
    console.log("search");
    let searchresult=[];
    
    if(searchProps !== null){
      var filtered = elements.filter((x) => x[searchProps].toLowerCase().includes(string.toLowerCase()));
      setItems(filtered);
    }

    /*if(searchFunction !== undefined && string !==""){
      for(let i=0; i<elements.length; i++){
        if(searchFunction(elements[i], string)){
          searchresult.push(elements[i]);
        }
      }
      setItems(searchresult);
    } else {
      setItems(elements);
    }*/

  }

  const handleSearchChanged = (string) => {
    search(string);
  }

  const newElementAdded = async(element) => {
    var newitems=items;
    newitems.unshift(element);
    setItems([...newitems]);
  }

  //#endregion

  //#region no items

  //#endregion

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        
        <HeaderDefault 
          handleSearchChanged={handleSearchChanged}
          addButton={addButton}
          itemCount={items !== null ? items.length : "k.A."}
        />
        {items === null
        ? <div>Keine Element!</div>
        : <div className={`${classes.list} ${view}`}>
            {items.map((item, index) => 
              React.cloneElement(component, {element: item, key: index})
            )}
          </div>
        }
        
      </div>
      </ThemeProvider>

    );
  
}

export default MyList;