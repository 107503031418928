//const api="https://dondom.servebeer.com:3001";
//const api="http://localhost:3001";
//const api="https://dondom.onthewifi.com:3001";
//const api="https://vote-co.dacor.io:4321";
const api=process.env.REACT_APP_API_URL;
//const api = "http://localhost:4321"

//const api="https://api.dominit.systems";


export {api};

/*module.exports = api;
module.exports = apiPhp;*/