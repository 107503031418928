import { clearSocket, setConnected } from "./slices/socket.slice";
import { clearAuth } from "./slices/authSlice";
import { MessageHandler } from "../utils/MessageHandler";

export const voteMiddleware = () => store => (next) => (action) => {

  const { dispatch, getState } = store
  const { type, id } = action

  switch(type) {
    case 'vote/start':
      

      break;

    case 'vote/stop':
      break

    default:
      break
  }


  return next(action)

}