var votes = {};

votes = {
  sing: "Beschluss",
  plu: "Beschlüsse",
  add: "Neuer Beschluss",
  h_add: "Neuen Beschluss anlegen",
  b_stop: "Stop",
  b_start: "Start",
  b_next: "Nächster Beschluss",
  fields: {
    title: "Titel",
    description: "Antrag",
  }
}

export default votes;