import styled from '@emotion/styled/macro';
import MyPage from '../../layout-components/MyPage';
import MyPageTitle from '../../layout-components/MyPage/MyPageTitle';
import MyPageContent from '../../layout-components/MyPage/MyPageContent';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, IconButton, keyframes, Tooltip } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {MyLayoutList as MyLayout} from '../../layout-components/MyLayout';
import voteService from '../../services/vote.service';
import MyLineChart from '../../layout-components/MyLineChart';
import { setMeeting } from '../../redux/slices/meetingSlice';
import SyncIcon from '@mui/icons-material/Sync';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';


/*
  ProcessingMeeting.js
  Übersichtseite einer laufenden Abstimmung
*/

const ProcessingMeetingRoot = styled.div((props) => ({
  height: "100%",
  width: "100%",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gridTemplateRows: "auto auto 1fr ",
  gridTemplateAreas: `
    "header header"
    "votes progress"
    "votes layout"

  `,
  gap: "1rem 1rem",
}));

const ProcessingMeetingTitle = styled.div((props) => ({
  padding: "1rem",
  border: "1px solid rgba(0, 0, 0, 0.2)",
  borderRadius: "0.5rem",
  boxShadow: "1px 1px 1px 0px rgba(0, 0, 0, 0.2)",
  gridArea: "header",
  display: "flex",
  alignItems: "center",
}));

const ProcessingMeetingVotes = styled.div((props) => ({
  gridArea: "votes",
  width: "100%",
  height: "calc(100%)",
  display: "flex",
  flexDirection: "column",
}));

const ProcessingMeetingVotesHeading = styled.div((props) => ({
  border: `1px solid ${props.theme.palette.primary.main}`,
  borderRadius: "0.5rem 0.5rem 0 0",
  padding: "0.5rem",
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  //fontWeight: "600",
  backgroundColor: props.theme.palette.primary.main,
  color: props.theme.palette.secondary.main
}));

const ProcessingMeetingVotesContainer = styled.div((props) => ({
  border: "1px solid rgba(0, 0, 0, 0.2)",
  display: "grid",
  gridTemplateColumns: "auto auto minmax(400px, 0.5fr) auto auto",
  //alignItems: "start",
  justifyContent: "center",
  gridTemplateRows: "repeat(3, 60px)",
  alignItems: "stretch",
  height: "calc(100% - 2px)",
  borderRadius: "0rem 0rem 0.5rem 0.5rem",
}));

const ProcessingMeetingVote = styled.div((props) => ({
  display: "contents",
  minHeight: "0",
  [ProcessingMeetingVoteTitle]: {
    transition: "font-size 1s, font-weight 1s",
    color: props.selected === "true" ? "blue" : "inherit",
    fontSize: props.selected === "true" ? "1.3rem" : "inherit",
    textDecoration: props.selected === "true" ? "underline" : null,
    color: props.active ? "red" : null,
    fontSize: props.active ? "1.3rem" : "inherit",
  } 
}));

const ProcessingMeetingVoteDone = styled.div((props) => ({
  padding: "1rem",
  //borderLeft: "1px solid rgba(0, 0, 0, 0.2)",
  borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
  //border: "1px solid red",
  display: "flex",
  alignItems: "center",
  //backgroundColor: "grey",
}));

const ProcessingMeetingVoteTitle = styled.div((props) => ({
  //backgroundColor: "yellow",
  padding: "1rem",
  borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
  //border: "1px solid blue",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  "&:hover": {
    opacity: "0.7",
  }
}));

const ProcessingMeetingVoteResult = styled.div((props) => ({
  //backgroundColor: "yellow",
  padding: "1rem",
  borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
  //borderRight: "1px solid rgba(0, 0, 0, 0.2)",
  //border: "1px solid green",
}))

const ProcessingMeetingLayout = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  height: "calc(100% - 2px - 1rem)",
  gridArea: "layout",
  borderRadius: "0.5rem",
  border: "1px solid rgba(0, 0, 0, 0.2)",
  padding: "0.5rem"
}))

const ProcessingMeetingButtons = styled.div((props) => ({
  gridArea: "buttons",
  //backgroundColor: "red",
  border: "1px solid rgba(0, 0, 0, .2)",
  borderRadius: "0.5rem",
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
}))

const ProcessingMeetingInfos = styled.div((props) => ({
  gridArea: "infos",
  //backgroundColor: "red",
  border: "1px solid rgba(0, 0, 0, .2)",
  borderRadius: "0.5rem",
}))

const ProcessingMeetingProgress = styled.div((props) => ({
  gridArea: "progress",
  //backgroundColor: "red",
  border: "1px solid rgba(0, 0, 0, .2)",
  borderRadius: "0.5rem",
  //display: "flex",
}))

const StlyedSyncIcon = styled(SyncIcon)((props) => ({
  fontSize: "1.5rem",
  animation: `${RotateAnimation} 2s infinite`,
}));

const RotateAnimation = keyframes`
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg)
  }
`;



const ProcessingMeeting = () => {
  
  const [id, setId] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [index, setIndex] = React.useState(-1);
  const [headvote, setHeadvote] = React.useState(null);
  const [selectedSubvote, setSelectedSubvote] = React.useState(0);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const reduxMeeting = useSelector((state) => state.meeting);
  const reduxVote = useSelector((state) => state.vote);

  useEffect(() => {
    setId(location.hash.slice(1));
  },[location.hash]);
  
  useEffect(() => {
    if(id !== null){
      //dispatch({type: 'meeting/start', data: id});
      voteService.getOne(id)
        .then((response) => setHeadvote(response.data))
        .catch((e) => console.log(e));
    }
  },[id]);
  
  useEffect(() => {
    if(reduxMeeting !== null && reduxMeeting.id !== undefined){
      setIndex(reduxMeeting.voteIndex);
    } else {
      voteService.getOne(id)
        .then((response) => setHeadvote(response.data))
        .catch((e) => console.log(e));
    }
  }, [reduxMeeting, id]);


  const handleIndex = (val) => {
    if(val === 0 ){
      setIndex(0);
    } else {
      setIndex(index + val)
    }
  }

  const handleStart = () => {
    dispatch({type: 'meeting/start', data: id});
  }

  const handleStop = () => {
    dispatch({type: 'meeting/stop', data: id});
    //dispatch(setMeeting(null));
  }

  const handleNext = () => {
    dispatch({type: 'meeting/next', data: id});
  }

  if(headvote === null) return "loading...";

  /* 
    wenn diese seite aufgerufen wird, dann ist eine sitzung gestartet
    allerdings muss die erste abstimmung noch gestartet werden
    => ich brauch wieder einen start button
  */

  return(
    <MyPage>
      <MyPageTitle size="small">
        Sitzung durchführen
        <div style={{marginRight: "0", marginLeft: "auto"}}>
          <Button variant="contained" color="primary" onClick={() => navigate(`/vote#${headvote.id}`)}>Zurück</Button>
        </div>
      </MyPageTitle>

      <MyPageContent>
        <ProcessingMeetingRoot>

          <ProcessingMeetingTitle size="small">
            {headvote.title}
            <div style={{display: "flex", gap: "0.5rem", marginRight: "0", marginLeft: "auto"}}>
              <Button variant='contained' color="secondary">Präsentation</Button>
              {reduxMeeting !== null && reduxMeeting.id !== undefined
              ? <>
                  <Button variant='contained' onClick={handleNext}>Nächste Abstimmung</Button>
                  <Button variant='contained' onClick={handleStop}>Stop</Button>
                </>
              : <Button variant='contained' onClick={handleStart}>Start</Button>
              }
            </div>
          </ProcessingMeetingTitle>

          <ProcessingMeetingVotes>
            
            <ProcessingMeetingVotesHeading>
              Abstimmungen
            </ProcessingMeetingVotesHeading>

            <ProcessingMeetingVotesContainer>
              {headvote.subvotes.map((sub, i) =>
                <ProcessingMeetingVote key={i} active={sub.id === reduxVote.id} selected={headvote.subvotes[selectedSubvote].id === sub.id ? "true" : "false"}>
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
                  }}>
                  {reduxVote.id === sub.id
                  ? <StlyedSyncIcon />
                  : null
                  }
                  </div>
                  
                  <ProcessingMeetingVoteDone>
                    {sub.done 
                    ? <Tooltip title="Bereits durchgeführt">
                        <CheckCircleOutlineOutlinedIcon color="success"/>
                      </Tooltip> 
                    : <Tooltip title="Noch nicht durchgeführt">
                        <CancelOutlinedIcon color="error" />
                      </Tooltip>
                    }
                    
                  </ProcessingMeetingVoteDone>
                  <ProcessingMeetingVoteTitle onClick={() => setSelectedSubvote(i)}>
                    {sub.title} - {sub.deleted ? "deleted" : null}
                  </ProcessingMeetingVoteTitle>
                  <ProcessingMeetingVoteResult>
                  </ProcessingMeetingVoteResult>

                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
                  }}>
                    <Tooltip title="Detailansicht Beschluss">
                      <IconButton onClick={() => navigate(`/vote#${sub.id}`)}>
                        <OpenInNewIcon />
                      </IconButton>
                    </Tooltip>
                  </div>

                </ProcessingMeetingVote>
              )}
            </ProcessingMeetingVotesContainer>
          </ProcessingMeetingVotes>
          
          {/*
          <ProcessingMeetingInfos>
            <p>Dies hier ist nur die Ansicht um eine Abstimmung durchzuführen.</p>
            <p>Es beginnt mit einer Übersicht</p>
            <p>Bei Klick auf start wird die erste Abstimmung gestartet</p>
          </ProcessingMeetingInfos>

          <ProcessingMeetingButtons>
          </ProcessingMeetingButtons>
          */}
          <ProcessingMeetingProgress>
            {reduxVote.id !== undefined 
            ? <MyLineChart 
                layout={reduxVote.layout.seats}
                results={reduxVote.results}
              />
            : <MyLineChart 
                //layout={headvote.subvotes[selectedSubvote].seatlayout}
                //results={headvote.subvotes[selectedSubvote].results.length > 0 ? headvote.subvotes[selectedSubvote].results[0].resultsets : null}
              />
            }
          </ProcessingMeetingProgress>

          <ProcessingMeetingLayout>
            {reduxVote.id !== undefined
            ? <MyLayout layout={reduxVote.layout} />
            : <MyLayout layout={headvote.subvotes[selectedSubvote].seatlayout} presentation/>
            }
          </ProcessingMeetingLayout>

        </ProcessingMeetingRoot>
      </MyPageContent>
    </MyPage>
  );
}

export default ProcessingMeeting;