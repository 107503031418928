import React, { 
  useRef 
} from 'react';
import { 
  CircularProgress,
  Fade,
  TextField,
  useTheme,
} from '@mui/material';
import styled from '@emotion/styled';
import { ThemeProvider } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import authService from '../../services/auth.service';
import { setAuth } from '../../redux/slices/authSlice';
import MyButton from '../../layout-components/MyButton/MyButton';
import logo from '../../images/dacor_logo.png';

const LoginRoot = styled.div((props) => {
  return {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "calc(100% - 10px)",
  backgroundColor: "#282a2b",
  border: `5px solid ${props.theme.palette.primary.main}`,
  height: "calc(100% - 10px)",
}});

const LoginDialog = styled.div((props) => ({
  display: "grid",
  width: "50%",
  minWidth: "350px",
  height: "50%",
  gridTemplateColumns: "1fr",
  gridTemplateRows: "auto 1fr",
}));

const LoginTitle = styled.div((props) => ({
  width: "100%",
  textAlign: "center",
  borderRadius: "10px 10px 0px 0px",
  transition: "background-color 0.7s",
  color: props.theme.palette.secondary.main,

}));

const LoginContent = styled.div((props) => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
  //gridTemplateColumns: "minmax(250px, 1fr) minmax(250px, 1fr)",
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  width: "100%",
}))
  
const LoginLogoArea = styled.div({
  width: "100%",
  //height: "100%",
  transition: "background-color 0.7s",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

});

const LoginLogoContainer = styled.div({
  width: "50%",
  height: "50%",
  maxHeight: "50px",
});

const LoginLogo = styled.img({
  objectFit: "contain", 
  width: "100%", 
  height: "100%",
  
})

const LoginFormContainer = styled.div({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: "2vh 0",
  //gap: "2vh",
});

const LoginForm = styled.div({
  width: "calc(100% - 40px)",
  display: "flex",
  flexDirection: "column",
  gap: "2vh",
  justifyContent: "center",
  //padding: "20px",
});

const LoginButtonContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  paddingTop: "2vh"
})

const StyledH2 = styled.h2((props) => ({
  padding: "0",
  margin: "0",
}));

const Login = () => {

  const [username, setUsername] = React.useState("");
  const [domain, setDomain] = React.useState("");
  const [domainError, setDomainError] = React.useState(null);
  const [usernameError, setUsernameError] = React.useState(null);
  const [passwordError, setPasswordError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  
  const passRef = useRef();
  const theme = useTheme();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateForm = () => {
    if(domain === ""){
      setDomainError(true);
      return false;
    }

    if(username === ""){

      setUsernameError(true);
      return false;
    }

    if(passRef.current.value === ""){
      setPasswordError(true);
      return false;
    }

    return true;
  }

  const handleLogin = () => {
    if(!validateForm()) return;

    setLoading(true);

    authService.login({
      username: username, 
      password: passRef.current.value, 
      domain: domain
    })
    .then((response) => {
      dispatch(setAuth(response));
      navigate('/');
    })
    .catch((error) => {
      setUsernameError(true);
      setPasswordError(true);
      setLoading(false);
    });
  }

  const handleUsernameChanged = (e) => {
    setUsername(e.target.value);
    setUsernameError(false);
    setPasswordError(false);
  }

  const handlePasswordChanged = (e) => {
    setPasswordError(false);
  }

  const handleDomainChanged = (e) => {
    setDomain(e.target.value);
    setDomainError(false);
  }

  const handleKeyDown = (e) => {
    if(e.keyCode === 13) { 
      handleLogin();
    }
  }

  return(
    <ThemeProvider theme={theme}>
      <LoginRoot>
        <LoginDialog>
          <LoginTitle>
            <StyledH2>ProVoSys</StyledH2>
          </LoginTitle>
          <LoginContent>
            <LoginLogoArea>
              <LoginLogoContainer>
                <LoginLogo src={logo} alt="Dacor Logo"/>
              </LoginLogoContainer>
            </LoginLogoArea>
            <LoginFormContainer>
              <LoginForm>
                <TextField label="Mandant" type="text" autoComplete='new-password' onChange={handleDomainChanged} error={domainError}  disabled={loading} onKeyDown={handleKeyDown}/>
                <TextField label="Benutzername" type="text" autoComplete='new-password' onChange={handleUsernameChanged} error={usernameError}  disabled={loading} onKeyDown={handleKeyDown}/>
                <TextField label="Passwort" type="password" autoComplete='new-password' onChange={handlePasswordChanged} inputRef={passRef} error={passwordError}  disabled={loading} onKeyDown={handleKeyDown}/>
              </LoginForm>
              <LoginButtonContainer>
                {loading
                ? <CircularProgress />
                : <MyButton text="Anmelden" color="primary" onClick={handleLogin} disabled={loading} />
                }
              </LoginButtonContainer>
            </LoginFormContainer>
          </LoginContent>
        </LoginDialog>
      </LoginRoot>
    </ThemeProvider>
  );
}

export default Login;