import React, {useEffect} from 'react';
import typesService from '../../services/types.service';
import TypeListItem from './TypeListItem';
import MyList from "../../layout-components/MyList/index";
import { useNavigate } from 'react-router-dom';
import MyPage from '../../layout-components/MyPage';
import MyPageContent from '../../layout-components/MyPage/MyPageContent';
import MyPageTitle from '../../layout-components/MyPage/MyPageTitle';
import texts from '../../texts';

const Types = () => {

  const [types, setTypes] = React.useState([]);

  useEffect(() => {
    init();
  }, [])

  const init = () => {
    typesService.getAll()
      .then((response) => setTypes(response.data))
      .catch((e) => console.log(e));
  }

  return(
    <MyPage>
      <MyPageTitle>
        {texts.types.h_plu}
      </MyPageTitle>
      <MyPageContent style={{display: "flex", flexDirection: "column",}}>
        <MyList 
          component={<TypeListItem />} 
          elements={types}
          searchProps={"title"}
          addButton={
          <div>
            {/*<MyButton text="Neu" startIcon={<AddCircleOutlineOutlinedIcon color="secondary" />} onClick={() => navigate('/type/add')} />*/}
          </div>
          }
        />
      </MyPageContent>
    </MyPage>
  );

}

export default Types;