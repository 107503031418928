import React from "react";

import {getPropsForUser} from './Src/Functions';
import SeatList from "./SeatList";
import styled from '@emotion/styled';
import SeatPresentation from "./SeatPresentation";
import { useSelector } from "react-redux";
import SeatListPresentation from "./SeatListPresentation";

const MyLayoutRoot = styled.div((props) => ({
  position: "relative",
  width: "calc(100% - 2px)",
  height: "calc(100% - 2px)",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  //border: "1px solid red",
}));

const MyLayoutLoading = styled.div((props) => ({
  border: "1px solid red"
}))

const MyLayoutSeatcontainer = styled.div((props) => ({
  backgroundColor: "black",
  height: "calc(100% - 20px)",
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gridTemplateRows: "repeat(11, 1fr)",
  gridRowStart: "2",
  gridColumnGap: "10px",
  gridRowGap: "10px",
  padding: "10px",
  "& .row":{
    paddingTop: props.theme.spacing(0.4),
    paddingBottom: props.theme.spacing(0.4),
    display: "flex",
    alignItems: "center",
    "&.headseat":{
      width: "50%",
      margin: "0 auto",
    },  
  },
}));

const MyLayoutLayout = styled.div((props) => ({
  //width: "100%",
  display: "flex",
  position: "relative",
  "& .header":{
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid rgba(0,0,0,0.5)",
    paddingBottom: props.theme.spacing(1),
    marginBottom: props.theme.spacing(1),
    "& .title":{
        order: "1",
        marginLeft: props.theme.spacing(3),
        fontSize: "1.5em",
    },
    "& .save":{
        order: "2",
        marginLeft: "auto",
        marginRight: props.theme.spacing(4),
    },
  },
  "& .row":{
    paddingTop: props.theme.spacing(0.4),
    paddingBottom: props.theme.spacing(0.4),
    display: "flex",
    alignItems: "center",
    "&.headseat":{
      width: "50%",
      margin: "0 auto",
    },  
  },
  "& .error":{
    backgroundColor: "rgba(255,0,0,0.5)",
    marginBottom: props.theme.spacing(2),
    position: "absolute",
    top: "0",
    left: "0",
    zIndex:"10",
    padding: props.theme.spacing(1),
    width: "100%",
    fontSize: "1.2em",
  }
}));




const MyLayoutList = (props) => {

  //#region props
  const {
    layout=null,
    results=null,
    aways=null,
    devices,
    handleManualVote,
    handleManualSpeaker,
    handleManualAway,
    presentation,
    object
  } = props;
  //#endregion

  //#region hooks
  const classes = {};
  const reduxVote = useSelector((state) => state.vote);
  //#endregion
  
  //#region no layout
  if(layout===null){
    return(
      <MyLayoutLoading>
        <div>loading layout...</div>
      </MyLayoutLoading>
    );
  }
  //#endregion

  //#region no seats on layout
  if(Object.keys(layout.seats).length <= 0){
    return(
      <MyLayoutRoot>
        <div style={{
          height: "100%",
          width: "100%",
          position: "relative",
        }}>
          <div style={{
            position: "absolute",
            top: "0",
            left: "0",
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
            <div style={{
              padding: "4em", 
              backgroundColor: "#ffcc00", 
              fontSize: "1.2em",
              boxShadow: "0px 0px 2px 2px black",
              borderRadius: "3px",
              zIndex: "5",
            }}>
              Der Sitzplan hat keine definierten Sitze!
            </div>
          </div>
          <div style={{
            //backgroundColor: "rgba(0, 0, 0, 0.2)",
            height: "100%",
            filter: "blur(3px)",
            width: "100%",
          }}>
            {[...Array(layout.rows.length*(layout.cols.length))].map((x, i) => 
              <div key={i} style={{border: "1px solid black"}}></div>
           )}
          </div>  
        </div>
      </MyLayoutRoot>
    );
  }
  //#endregion
  
  if(presentation){
    return(
    <MyLayoutRoot>
      {[...Array(layout.rows.length*(layout.cols.length))].map((x, i) => {
        if(layout.seats[i] === undefined) {
          return(
            <div key={i} className={classes.LayoutCell}>
              {/*i*/}
            </div>
          );
        }
        
        var userProps = getPropsForUser(layout.seats, results, devices, aways, i, object);
        
        return(
          <SeatListPresentation 
            key={i} 
            seatnum={i} 
            index={i} 
            user={layout.seats[i]} 
            userProps={userProps} 
            handleManualVote={handleManualVote}
            handleManualSpeaker={handleManualSpeaker}
            handleManualAway={handleManualAway}
          />
        );
      })}
    </MyLayoutRoot>
    );
    
  }

  return(
    <MyLayoutRoot>
      {[...Array(layout.rows.length*(layout.cols.length))].map((x, i) => {
        if(layout.seats[i] === undefined) {
          return(
            <div key={i} className={classes.LayoutCell}>
              {/*i*/}
            </div>
          );
        }
        
        var userProps = getPropsForUser(layout.seats, reduxVote.results, devices, aways, i);
        
        return(
          <SeatList 
            key={i} 
            seatnum={i} 
            index={i} 
            user={layout.seats[i]} 
            userProps={userProps} 
            handleManualVote={handleManualVote}
            handleManualSpeaker={handleManualSpeaker}
            handleManualAway={handleManualAway}
          />
        );
      })}
    </MyLayoutRoot>
  );

  
} 

export default MyLayoutList;