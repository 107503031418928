import React from "react";
import { 
  IconButton,
 } from "@mui/material";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { removeAlert } from "../../redux/slices/alertsSlice";

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';


const colors = {
  error: {
    color: "red",
    border: "red",
    bg: "rgba(255, 200, 200, 1)",
    shadow: "rgba(255, 0, 0, 0.5)",
    close: "red",
    icon: "red",
  },
  info: {
    color: "black",
    border: "#6495ED",
    bg: "white",
    shadow: "#4169E1",
    close: "#4169E1",
    icon: "#6495ED",
  },
  warning: {
    color: "#E49B0F",
    border: "#E49B0F",
    bg: "#F5F5DC",
    shadow: "#E49B0F",
    close: "#E49B0F",
    icon: "#E49B0F",
  },
  default: {
    color: "black",
    border: "rgba(0, 0, 0, 0.7)",
    bg: "rgba(255, 255, 255, 1)",
    shadow: "rgba(0, 0, 0, 0.5)",
  },
};

const icons = {
  error: <ErrorOutlineOutlinedIcon />,
  info: <InfoOutlinedIcon />,
  warning: <WarningOutlinedIcon />,
  default: null,
}

const MyAlert = (props) => {

  const {
    alert,
    ts
  } = props;

  const dispatch = useDispatch();

  useEffect(()=>{
    setTimeout(handleClose, 5000);
  }, [])

  const handleClose = () => {
    dispatch(removeAlert(ts));
  }

  return(
    <div style={{
      display: "flex",
      
      padding: "0.5em",
      
      borderRadius: "5px",
      //width: "100%",
      display: "flex",
      alignItems: "center",
      backgroundColor: `${colors[alert.type].bg}`,
      border: `1px solid  ${colors[alert.type].border}`,
      boxShadow: `0px 0px 4px 1px ${colors[alert.type].shadow}`,
      color: `${colors[alert.type].color}`,
      gap: "15px",
    }}>
      {icons[alert.type]
      ? React.cloneElement(icons[alert.type], {
          style: {
            color: `${colors[alert.type].icon}`
          }
        })
      : null
      }
      <div style={{
        width: "100%",
      }}>
        {alert.msg}
      </div>
      <div>
        <IconButton size="small" onClick={handleClose}>
          <CloseOutlinedIcon style={{
            color: `${colors[alert.type].close}`
          }}/>
        </IconButton>
      </div>
    </div>
  );
}

export default MyAlert;