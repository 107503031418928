import React from 'react';
import TypeSpecimenIcon from '@mui/icons-material/TypeSpecimen';
import styled from '@emotion/styled/macro';
import {Switch} from '@mui/material';
import typeService from '../../services/type.service';
import { useEffect } from 'react';
import voteService from '../../services/vote.service';

const ListItemRoot = styled.div((props) => ({
  display: "flex",
  borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
  gap: "1rem",
  padding: "1rem 1rem",
  position: "relative",
  transition: "background-color 0.2s",
}));

const StyledIcon = styled(TypeSpecimenIcon)((props) => ({
  fontSize: "3rem",
  opacity: "0.5",
}))

const DateInfo = styled.div((props) => ({
  position: "absolute",
  top: "0",
  right: "0.5rem",
  fontSize: "0.75rem",
  gap: "0.5rem",
}))

const InfoElement = styled.div((props) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& div:first-of-type": {
    fontSize: "1.25rem",
  },
  "& div:last-of-type": {
    opacity: "0.5",
  }
}))

const TypeListItem = (props) => {

  const {
    element
  } = props;

  const [type, setType] = React.useState(null);

  useEffect(() => {
    setType(element);
  }, [element])

  const handleVotingChanged = () => {
    var check = window.confirm("Das Ändern der Stimmberechtigkeit, führt dazu, dass Teilobjekte in Beschlüsse mit einbezogen oder ausgeschlossen werden.\n");
    if(check){
      //setType({...type, voting: !type.voting});

      console.log({...type, voting: !type.voting});
      typeService.update({...type, voting: !type.voting})
        .then((res) => setType(res.data))
        .catch((e) => console.log(e));
    }
    
  }

  if(type === null) return null;

  return(
    <ListItemRoot>
      <StyledIcon/>
      <DateInfo>
        <div>{new Date(type.createdAt).toLocaleTimeString()}</div>
        <div>{new Date(type.createdAt).toLocaleDateString()}</div>
      </DateInfo>
      <InfoElement onClick={()=>console.log("type", type)}>
        <div>{type.title}</div>
        <div>Titel</div>
      </InfoElement>
      <InfoElement>
        <div>
          <Switch checked={type.voting} color="success" onClick={handleVotingChanged}/>
        </div>
        <div>Stimmberechtigt</div>
      </InfoElement>
    </ListItemRoot>
  );
}

export default TypeListItem;