//import { createMuiTheme } from '@material-ui/core/styles';
import {createTheme} from '@mui/material';


/*
  Mit diesem Theme können die Farben der Elemente innerhalb der App verändert werden.
  Es werden folgende Parameter verwendet:
  primary: die Hauptfarbe der App
  secondary: die sekundäre Farbe
  text dark: Farbe für Texte, die dunkel sidn
  text light: Farbe für Text, die hell sind
*/

export default createTheme({
    palette: {
      primary: {
        main: '#14213d'
      },
      secondary: {
        main: "#fca311",
        //main: '#bbbdbe',
        darker: "#868889",
      },
      dark: {
        main: "#000000",
      },
      background: {
        main: '#fff',
      },
      gray: {
        main: "#b1b1b1",
        dark: '#898989',
        light: '#e0e0e0',
      },
      white: { main: "#fff"},
      default: "rgba(0, 0, 0, 0)",
    },
  });
