import communities from "./communites";
import devices from "./devices";
import meetings from "./meetings";
import objects from "./objects";
import options from "./options";
import owners from "./owners";
import subobjects from "./subobjects";
import types from "./types";
import votes from "./votes";

var texts = {};

texts= {
  objects: objects,
  subobjects: subobjects,
  owners: owners,
  meetings: meetings,
  votes: votes,
  types: types,
  devices: devices,
  options: options,
  communities: communities,
}

export default texts;