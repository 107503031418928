const sendMessages =  {
  approve: () => ({
    type: "approve",
  }),
  auth: {
    type: "auth",
  },
  GetProcessingVote: {
    type: "get processing vote",
  },
  clientInfo: {
    type: "client info",
    data: window.navigator.userAgent
  },
  adminAuth : {
    type: "admin",
    data: process.env.REACT_APP_WS_ADMIN_AUTH_TOKEN,
  },
  modAuth: {
    type: "moderator",
    data: process.env.REACT_APP_WS_MOD_AUTH_TOKEN,
  },
  startVote: (id) => {
    return {
      type: "start vote",
      data: id
    }
  },
  findLayout: {
    type: "find layout",
  },
  findUsers: {
    type: "find users",
  },
  findDevices:{
    type: "find devices",
  },
  connectToThingsboard: {
    type: "connect to thingsboard"
  },
  connectToDevices: {
    type: "connect to devices",
  },
  CheckProcessingVote: {
    type: "check processing",
  },
  closeVote: {
    type: "close vote",
  },
  endVote: (id) => {
    return {
      type: "end vote",
      id: id
    }
  },
  manualVote: (data) => {
    return {
      type: "manuel vote",
      seatnum: data.seatnum,
      value: data.value,
    }
  },
  manualSpeaker: (data) => {
    return {
      type: "manual speaker",
      seatnum: data.seatnum,
      value: data.value,
    }
  },
  manualAway: (data) => {
    return {
      type: "manual away",
      seatnum: data.seatnum,
    }
  },
  previewResult: (vote, result) => {
    return {
      type: "preview result",
      vote: vote.id,
      result: result.id
    }
  },
  printActualVote: {
    type: "print actual vote",
    data: process.env.REACT_APP_WS_MOD_AUTH_TOKEN,
  },
  printSeats: {
    type: "print seats",
    data: process.env.REACT_APP_WS_MOD_AUTH_TOKEN,
  },
  printUsers: {
    type: "print users",
    data: process.env.REACT_APP_WS_MOD_AUTH_TOKEN,
  },
  printDevices: {
    type: "print devices",
    data: process.env.REACT_APP_WS_MOD_AUTH_TOKEN,
  },
  printSpeakerList: {
    type: "print speakerlist",
    data: process.env.REACT_APP_WS_MOD_AUTH_TOKEN,
  },
  printAways: {
    type: "print aways"
  }
}

export default sendMessages;