import { Button } from "@mui/material";

const RemoveCommunity = (props) => {

  const {
    community
  } = props;

  return(<div>
    Wirklich löschen?
    <div>
      <Button>Jo</Button>
    </div>
    <div>
      <Button>
        Nö
      </Button>
    </div>
  </div>);
}

export default RemoveCommunity;