import React from 'react';
import styled from '@emotion/styled/macro';
import MyPage from '../../layout-components/MyPage';
import MyPageTitle from '../../layout-components/MyPage/MyPageTitle';
import MyPageContent from '../../layout-components/MyPage/MyPageContent';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { MenuItem, Tab, Tabs, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import objectService from '../../services/object.service';
import GridTable from '../../layout-components/GridTable';
import GridTableHead from '../../layout-components/GridTable/GridTableHead';
import {MyLayoutGrid as MyLayout} from '../../layout-components/MyLayout';
import texts from '../../texts';
import { useDispatch } from 'react-redux';
import { addAlert } from '../../redux/slices/alertsSlice';

//#region styles
const ObjectRoot = styled.div((props) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
}))

const StyledTabPanel = styled.div((props) => ({
  //border: "1px solid red",
  height: "100%",
  padding: "1rem",
  paddingTop: "0rem",
  overflow: "auto",
  marginTop: "0.5rem",
}))

const InfoElementsGrid = styled.div((props) => ({
  display: "grid",
  gridTemplateColumns: "0.5fr 1fr",
  alignItems: "stretch",
}))

const InfoElement = styled.div((props) => ({
  display: "contents",
}));

const InfoElementLabel = styled.div((props) => ({
  color: "rgba(0, 0, 0, 0.7)",
  borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
  padding: "1rem 3rem 1rem 1rem",
  display: "flex",
  alignItems: "center",
}))

const InfoElementValue = styled.div((props) => ({
  fontSize: "1.2rem",
  fontWeight: "600",
  borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
  padding: "1rem 1rem 1rem 1rem",
  display: "flex",
  alignItems: "center",
}));

const InfoElementSelect = styled(TextField)((props) => ({
  //padding: "0px",
  borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
  alignSelf: "center",
  padding: "1rem 1rem 1rem 1rem",
}))

//#endregion

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return(
    <StyledTabPanel hidden={value !== index}>
      {children}
    </StyledTabPanel>
  );

}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Object = () => {

  const [id, setId] = React.useState(null);
  const [element, setElement] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [tabIndex, setTabIndex] = React.useState(0);

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(()=>{
    var hash = location.hash.slice(1);
    setId(hash);
  }, [location]);

  useEffect(() => {
    if(id !== null){
      init();
    }
  }, [id])

  const init = () => {
    setLoading(true);
    objectService.getOne(id)
      .then((response) => {
        setElement(response.data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const countUniqueOwners = () => {
    var count = 0;
    var temp = [];
    for(let i=0; i <element.subobjects.length; i++){
      if(!temp.includes(element.subobjects[i].owner.name)){
        temp.push(element.subobjects[i].owner.name);
        count++;
      }
    }
    return count;
  }

  const sumUpMea = () => {
    var count = 0;
    for(let i=0; i<element.subobjects.length; i++){
      var value = element.subobjects[i].mea;
      value = value.replace(',', '.');
      count += Number(value);
    }
    count.toString().replace(".", ",");
    return count;
  }

  const handleChangeVotingType = (e) => {
    console.log(e.target.value);

    objectService.update({...element, votingtype: e.target.value})
      .then((response) => {
        dispatch(addAlert({
          type: "info",
          msg: "Objekt erfolgreich aktualisiert!"
        }));
        console.log(response);
        objectService.getOne(id)
          .then((res) => {
            setElement(res.data);
            setLoading(false);
          })
          .catch((error) => console.log(error));
      })
      .catch((e) => {
        console.log(e);
        dispatch(addAlert({
          type: "error",
          msg: "Objekt konnte nicht aktualisiert werden!"
        }));
      })

  }

  console.log("element", element);

  if(loading){
    return(
      "loading ..."
    );
  }

  return(
    <MyPage>
      <MyPageTitle>
        {texts.objects.h_sing}
      </MyPageTitle>
      <MyPageContent>
        <ObjectRoot>
          <Tabs value={tabIndex} onChange={handleChange}>
            <Tab label="Details" />
            <Tab label="Subobjekte" />
          </Tabs>
          <CustomTabPanel value={tabIndex} index={0}>
            <InfoElementsGrid>
              <InfoElement>
                <InfoElementLabel>Name</InfoElementLabel>
                <InfoElementValue>{element.name}</InfoElementValue>
              </InfoElement>
              <InfoElement>
                <InfoElementLabel>Nummer</InfoElementLabel>
                <InfoElementValue>{element.number}</InfoElementValue>
              </InfoElement>
              <InfoElement>
                <InfoElementLabel>ID</InfoElementLabel>
                <InfoElementValue>{element.id}</InfoElementValue>
              </InfoElement>
              <InfoElement>
                <InfoElementLabel>Erstellt am</InfoElementLabel>
                <InfoElementValue>{new Date(element.createdAt).toLocaleTimeString()} {new Date(element.createdAt).toLocaleDateString()}</InfoElementValue>
              </InfoElement>
              <InfoElement>
                <InfoElementLabel>Subobjekte</InfoElementLabel>
                <InfoElementValue>{element.subobjects.length}</InfoElementValue>
              </InfoElement>
              <InfoElement>
                <InfoElementLabel>Gesamt MEA</InfoElementLabel>
                <InfoElementValue>{sumUpMea()}</InfoElementValue>
              </InfoElement>
              <InfoElement>
                <InfoElementLabel>Anzahl Eigentümer</InfoElementLabel>
                <InfoElementValue>{countUniqueOwners()}</InfoElementValue>
              </InfoElement>
              <InfoElement>
                <InfoElementLabel>Veraltet</InfoElementLabel>
                <InfoElementValue>{element.outdated.toString()}</InfoElementValue>
              </InfoElement>
              <InfoElement>
                <InfoElementLabel>Beschluss Typ</InfoElementLabel>
                
                
                <InfoElementSelect select value={element.votingtype} onChange={handleChangeVotingType} size="small">
                  <MenuItem value="mea">
                    MEA
                  </MenuItem>
                  <MenuItem value="owner">
                    Anzahl Stimmen
                  </MenuItem>
                </InfoElementSelect>
                  
              </InfoElement>
            </InfoElementsGrid> 
          </CustomTabPanel>
          <CustomTabPanel value={tabIndex} index={1}>
            {element.subobjects.length <= 0
            ? "-" 
            : <GridTable cols="5">
                <GridTableHead>
                  <tr>
                    <th>Name</th>
                    <th>Nummer</th>
                    <th>Typ</th>
                    <th>Eigentümer</th>
                    <th>MEA</th>
                  </tr>
                </GridTableHead>
                <tbody>
                  {element.subobjects.map((subobject, index) => 
                    <tr key={index}>
                      <td>{subobject.name}</td>
                      <td>{subobject.number}</td>
                      <td>{subobject.type.title}</td>
                      <td>{subobject.owner.name}</td>
                      <td>{subobject.mea}</td>
                    </tr>
                  )}
                </tbody>
              </GridTable>
            }
          </CustomTabPanel>
          
        </ObjectRoot>
      </MyPageContent>
    </MyPage>
  );
}

export default Object;