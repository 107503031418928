import React, { useEffect, useRef } from "react";
import { 
  Checkbox, 
  FormControlLabel, 
  Grow, 
  IconButton, 
  InputLabel, 
  MenuItem, 
  TextField 
} from "@mui/material";
import MyButton from "../../layout-components/MyButton/MyButton";
import MyList from "../../layout-components/MyList";
import { ThemeProvider, useTheme } from '@mui/material';
import { CallMissedSharp, Check } from "@mui/icons-material";
import AddFlat from "./AddFlat";
import SecondarySidebar from "../../layout-components/SecondarySidebar";
import mandantService from "../../services/mandant.service";
import { useLocation, useNavigate } from "react-router-dom";
import typeService from "../../services/type.service";
import styled from "@emotion/styled";
import ownerService from "../../services/owner.service";
import GridTable from "../../layout-components/GridTable";
import GridTableHead from "../../layout-components/GridTable/GridTableHead";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import objectService from "../../services/object.service";
import MyPage from "../../layout-components/MyPage";
import MyPageTitle from "../../layout-components/MyPage/MyPageTitle";
import MyPageContent from "../../layout-components/MyPage/MyPageContent";

const AddObjectRoot = styled.div((props) => ({
  width: "calc(100% - 1em - 2em)",
  height: "calc(100% - 1em - 2em)",
  margin: "0.5em",
  padding: "1em",
  borderRadius: "0.5em",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  position: "relative",
}));

const AddObjectHeader = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: props.theme.palette.primary.main,
  background: "white",
  background: `linear-gradient(90deg, ${props.theme.palette.primary.light} 0%, ${props.theme.palette.secondary.main} 75%)`,
  padding: "1rem",
  borderRadius: "0.5rem",
}));

const AddObjectHeaderButtons = styled.div((props) => ({
  display: "flex",
  gap: "10px",
  marginLeft: "auto",
  marginRight: "0px",
  minWidth: "500px"
}));

const AddObjectContent = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  height: "100%",
  border: "1px solid red",
  overflowY: "auto",
}));

const AddObjectInfoContainer = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  backgroundColor: "white",
  padding: "1rem",
  borderRadius: "0.5rem",
}));

const AddObjectInfoInputs = styled.div((props) => ({
  display: "flex",
  gap: "2rem",
  padding: "1rem",
})); 

const AddObjectInfoNumbers = styled.div((props) => ({
  display: "flex",
  gap: "1rem",
  padding: "1rem",
}));

const AddObjectNumberLabel  = styled.div((props) => ({
  width: "100%",
  borderRadius: "0.5rem",
  padding: "1rem",
  backgroundColor: props.theme.palette.secondary[ props.color ? props.color : "main"],
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "0.5rem",
  "& :first-of-type": {
    fontSize: "1.5rem",
  }
}));

const AddObjectSubObjectsContainer = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  backgroundColor: "white",
  padding: "1rem",
  borderRadius: "0.5rem",
}));

const AddObjectSubObjectsHeader = styled.div((props) => ({
  display: "flex",
  alignItems: "flex-end",
  "& :last-child": {
    marginLeft: "auto",
    marginRight: "0px",
  }
}));

const AddObjectIconButton = styled.div((props) => ({
  cursor: "pointer",
  borderRadius: "50%",
  
  //backgroundColor: "blue",
  border: "1px solid rgba(0, 0, 0, 0.2)",
  padding: "3px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  transition: "background-color 0.2s",
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  }
}));

const AddObjectsNoSubObjects = styled.div((props) => ({
  textAlign: "center",
  opacity: "0.7",
  padding: "1rem",
}));

const AddObjectAddSubObjectButtons = styled.div((props) => ({
  display: "flex",
  gap: "1rem",
}));

const AddObjectAddSubObjectContainer = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  border: `2px solid ${props.theme.palette.secondary.main}`,
  padding: "0.5rem",
}));

const AddObjectAddSubObjectInputs = styled.div((props) => ({
  display: "flex",
  gap: "1rem",
  alignItems: "flex-end",
}))

const AddObjectsBreakLine = styled.div((props) => ({
  height: "1px",
  backgroundColor: "rgba(0, 0, 0, 0.2)",
}));
        
const NewOwner = (props) => {

  const {
    owner,
    setOwner,
    handleClose=()=>{}
  } = props;

  const [nameError, setNameError] = React.useState(false);

  const nameRef = useRef();

  const handleSave = async() => {

    const nameValue = nameRef.current.value;

    if(nameValue === ""){
      setNameError(true);
      return;
    }

    try{
      var response = await ownerService.create({
        name: nameValue
      });
      
      var _owner = [...owner];
      _owner.push(response.data);
      setOwner(_owner);
  
      handleClose();
    } catch(e){

    }

    

  }

  const handleNameChanged = () => {
    setNameError(false);
  }

  const handleKeyDown = (e) => {
    if(e.keyCode === 13) { 
      handleSave();
    }
  }

  return(
    <div style={{
      display: "flex",
      flexDirection: "column",
      gap: "2rem",
    }}>
      <h4>Neuen Eigentümer anlegen</h4>
      <TextField 
        label="Name"
        required 
        inputRef={nameRef} 
        error={nameError}
        onChange={handleNameChanged}
        onKeyDown={handleKeyDown}
      />
      <MyButton text="Speichern" onClick={handleSave} />
      <MyButton text="Abbrechen" color="secondary" onClick={handleClose} />
    </div>
  );
}

const NewType = (props) => {

  const {
    types,
    setTypes,
    handleClose=()=>{}
  } = props;

  const [titleError, setTitleError] = React.useState(false);

  const titleRef = useRef();
  const checkRef = useRef();

  const handleSave = async() => {

    const titleValue = titleRef.current.value;
    const checkboxValue = checkRef.current.value;

    if(titleValue === ""){
      setTitleError(true);
      return;
    }

    try{
      var response = await typeService.create({
        title: titleValue,
        voting: checkboxValue
      });
      
      var _types = [...types];
      _types.push(response.data);
      setTypes(_types);
  
      handleClose();
    } catch(e){

    }

  }

  const handleTitleChanged = () => {
    setTitleError(false);
  }

  const handleKeyDown = (e) => {
    if(e.keyCode === 13) { 
      handleSave();
    }
  }

  return(
    <div style={{
      display: "flex",
      flexDirection: "column",
      gap: "2rem",
    }}>
      <h4>Neuen Typen anlegen</h4>
      <TextField 
        label="Titel" 
        required 
        inputRef={titleRef} 
        error={titleError}
        onChange={handleTitleChanged}
        onKeyDown={handleKeyDown}
      />

      <FormControlLabel 
        control={
          <Checkbox color="primary" defaultChecked inputRef={checkRef} />
        }
        label="Für Stimmanteile berücksichtigen"
        labelPlacement="top"
      />
      
      <MyButton text="Speichern" onClick={handleSave} />
      <MyButton text="Abbrechen" color="secondary" onClick={handleClose} />
    </div>
  );
}

const AddObject = () => { 

  const [subObjects, setSubObjects] = React.useState([]);   //subobject from objects
  const [addNew, setAddNew] = React.useState(false);        //if true show form to add new subobject
  const [secondarySidebar, setSecondarySidebar] = React.useState(false);  //show or hide secondarysidebar
  const [sidebarContent, setSidebarContent] = React.useState(null);       //hold content for secondarysidebar
  const [nameInputError, setNameInputError] = React.useState(false);      //true or false based on validation of input
  const [numberInputError, setNumberInputError] = React.useState(false);  //true or false based on validation of input
  const [types, setTypes] = React.useState([]);   //all typed - can be selected when adding new subobject
  const [owners, setOwners] = React.useState([]); //all owerns - can be selected when adding new subobject
  const [hoverTableRowIndex, setHoverTableRowIndex] = React.useState(-1); //hold index of hovered row in subobjects table
  const [isEdit, setIsEdit] = React.useState(false);  //defines if this component adds a new object or is editing an existing 
  const [editElement, setEditElement] = React.useState(null); //holds the subobject thats gets edited
  const [editSub, setEditSub] = React.useState(false);  //defines if a new subobject gets added or an existing is editing
  const [object, setObject] = React.useState({
    name: "",
    number: "",
    subobjects: [],
  });
  const [subobject, setSubobject] = React.useState({
    name: "",
    number: "",
    typeId: "-",
    ownerId: "-",
    mea: "",
  });

  const nameRef = useRef();
  const numberRef = useRef();
  const subObjectNameRef = useRef();
  const subObjectTypeRef = useRef();
  const subObjectOwnerRef = useRef();
  const subObjectMeaRef = useRef();
  const subObjectNumberRef = useRef();

  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    init();
  }, [])

  useEffect(()=>{
    if(location.hash !== ""){
      setIsEdit(true);
      fetchElement(location.hash.slice(1));
    }
  }, 
  [location.hash])

  const init = () => {
    //alert("Lade Typen und Eigentümer")
    typeService.getAll()
    .then((response) => {
      setTypes(response.data);
    })
    .catch((e) => {
      console.timeLog(e);
    });

    ownerService.getAll()
    .then((response) => {
      setOwners(response.data);
    })
    .catch((e) => {
      console.timeLog(e);
    });
  }

  const fetchElement = (id) => {
    objectService.getOne(id)
    .then((response) => {
      setObject(response.data);
    })
    .catch((e) => {
      console.timeLog(e);
    });
  }

  const handleSave = () => {

    var nameValue = nameRef.current.value;
    var numberValue = numberRef.current.value;

    if(validateForm()){
      objectService.createOrUpdate(object);
    }
  }

  const handleCancel = () => {
    navigate('/');
  }

  const validateForm = () => {

    if(object.name === ""){
      setNameInputError(true);
    }

    if(object.number === ""){
      setNumberInputError(true);
    }

    if(object.name !== "" && object.number !== null){
      return true;
    }

    return false;

  }

  const handleSecondarySidebarClosed = () => {
    setSecondarySidebar(false);
  }

  const handleSecondarySidebar = (type) => {
    switch(type){
      case "type":
        setSidebarContent(<NewType types={types} setTypes={setTypes} />);
        break;

      case "owner": 
        setSidebarContent(<NewOwner owner={owners} setOwner={setOwners} />);
        break;

      default:
        setSidebarContent(null);
        break;
    }

    setSecondarySidebar(true);

  }

  const handleSaveSubObject = async() => {


    var _object = {...object};
    var _subobject = {...subobject};


    //find owner and type with given ids
    for(let i=0; i<owners.length; i++){
      if(owners[i].id === _subobject.ownerId){
        _subobject.owner = owners[i];
        break;
      }
    }

    for(let i=0; i<types.length; i++){
      if(types[i].id === _subobject.typeId){
        _subobject.type = types[i];
        break;
      }
    }

    if(_subobject.id !== undefined){
      for(let i=0; i<_object.subobjects.length; i++){
        if(_object.subobjects[i].id === _subobject.id){
          _object.subobjects[i] = _subobject;
          break;
        }
      }
    } else {
      _object.subobjects.push(_subobject);
    }

    setSubobject({
      name: "",
      number: "",
      typeId: "-",
      ownerId: "-",
      mea: "",
    });
    setObject(_object);
    setAddNew(false);

    return;

    var nameValue = subObjectNameRef.current.value;
    var typeValue = subObjectTypeRef.current.value;
    var ownerValue = subObjectOwnerRef.current.value;
    var meaValue = subObjectMeaRef.current.value;
    var numberValue = subObjectNumberRef.current.value;

    var _owner = null;
    for(let i=0; i<owners.length; i++){
      if(owners[i].id === ownerValue){
        _owner = owners[i];
        break;
      } 
    }

    var _typ = null;
    for(let i=0; i<types.length; i++){
      if(types[i].id === typeValue){
        _typ = types[i];
        break;
      }
    }

    var _subObjects = [...subObjects];

    if(editSub){
      for(let i=0; i<_subObjects.length; i++){
        if(_subObjects[i].id === editSub.id){
          _subObjects[i] = {
            name: nameValue,
            type: _typ,
            owner: _owner,
            mea: meaValue,
            number: numberValue
          };
          break;
        }
      }
      setEditSub(false);
    } else {
      _subObjects.push({
        name: nameValue,
        type: _typ,
        owner: _owner,
        mea: meaValue,
        number: numberValue
      });
    }
    setSubObjects(_subObjects);
  }

  const countUniqueOwners = () => {
    var count = 0;
    var temp = [];
    for(let i=0; i <object.subobjects.length; i++){
      if(!temp.includes(object.subobjects[i].owner.name)){
        temp.push(object.subobjects[i].owner.name);
        count++;
      }
    }

    return count;
  }

  const sumUpMea = () => {
    var count = 0;

    for(let i=0; i<object.subobjects.length; i++){
      var value = object.subobjects[i].mea;
      value = value.replace(',', '.');
      count += Number(value);
    }

    count.toString().replace(".", ",");
    return count;
  }

  const handleEditSub = (index) => {
    setSubobject(object.subobjects[index]);
    //setEditSub(object.subobjects[index]);
    setAddNew(true);
  }

  const handleUpdateObject = (e, attr) => {
    var _object = {...object};
    _object[attr] = e.target.value;
    setObject(_object);
  }

  const handleUpdateSubobject = (e, attr) => {
    var _subobject = {...subobject};
    _subobject[attr] = e.target.value;
    setSubobject(_subobject);
  }

  const handleObjectNameChanged = (e) => {
    var _object = {...object};
    _object.name = e.target.value;
    setObject(_object);
  }

  return(
    <ThemeProvider theme={theme}>
      <AddObjectRoot>
        <SecondarySidebar 
          open={secondarySidebar} 
          onClose={handleSecondarySidebarClosed}
          content={sidebarContent}
        />
        <AddObjectHeader>
          <div>
          <h3>
            {!isEdit
            ? "Neues Mietobjekt anlegen"
            : "Mietobjekt bearbeiten"
            }
          </h3>
          {object.name}
          </div>
          <AddObjectHeaderButtons>
            <MyButton text="Abbrechen" color={"secondary"} onClick={handleCancel} />
            <MyButton text="Speichern" color="primary" onClick={handleSave} />
          </AddObjectHeaderButtons>
        </AddObjectHeader>

      <AddObjectContent>
        <AddObjectInfoContainer>
          <div>Grundlegende Informationen</div>
          <AddObjectInfoInputs>
            <TextField 
              label="Name" 
              fullWidth 
              inputRef={nameRef} 
              required
              error={ nameInputError }
              value={object.name}
              onChange={handleObjectNameChanged}
            />
            <TextField 
              label="Number" 
              fullWidth 
              inputRef={numberRef} 
              error={numberInputError}
              value={object.number}
              required
              onChange={(e) => handleUpdateObject(e, "number")}
            />
          </AddObjectInfoInputs>
          <AddObjectInfoNumbers>
            <AddObjectNumberLabel color="dark">
              <div>{object.subobjects.length}</div>
              <div>Anzahl Wohnungen</div>
            </AddObjectNumberLabel>
            <AddObjectNumberLabel color="main">
              <div>{countUniqueOwners()}</div>
              <div>Anzahl Teilhaber</div>
            </AddObjectNumberLabel>
            <AddObjectNumberLabel color="light">
              <div>{sumUpMea()}</div>
              <div>Summe MEA</div>
            </AddObjectNumberLabel>
          </AddObjectInfoNumbers>
        </AddObjectInfoContainer>
      
        <AddObjectSubObjectsContainer>
          <AddObjectSubObjectsHeader>
            <div>Teilobjekte</div>
            <div><MyButton text="Neues Teilobjekt hinzufügen" onClick={()=>setAddNew(!addNew)}/></div>
          </AddObjectSubObjectsHeader>
          <AddObjectsBreakLine />
          
          {object.subobjects.length <= 0
          ? <AddObjectsNoSubObjects>
              Keine Teilobjekte definiert!
            </AddObjectsNoSubObjects>
          : <GridTable>
              <GridTableHead>
                <tr>
                  <th>Name</th>
                  <th>Nummer</th>
                  <th>Typ</th>
                  <th>Eigentümer</th>
                  <th>MEA</th>
                  <th></th>
                </tr>
              </GridTableHead>
              <tbody>
                {object.subobjects.map((subobject, index) => 
                  <tr key={index} onMouseEnter={(e) => setHoverTableRowIndex(index)} onMouseLeave={(e) => setHoverTableRowIndex(-1)}>
                    <td>{subobject.name}</td>
                    <td>{subobject.number}</td>
                    <td>{subobject.type.title}</td>
                    <td>{subobject.owner.name}</td>
                    <td>{subobject.mea}</td>
                    <td>
                      <div style={{opacity: true || hoverTableRowIndex === index ? "1" :" 0", transition: "opacity 0.2s", display: "flex", justifyContent: "flex-end", gap: "10px"}}>
                        <AddObjectIconButton onClick={(e) => handleEditSub(index)}>
                          <EditIcon color="info" />
                        </AddObjectIconButton>
                        <AddObjectIconButton>
                          <DeleteOutlineIcon color="error"/>
                        </AddObjectIconButton>  
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </GridTable>
          }

          <AddObjectsBreakLine />

          <Grow in={addNew} unmountOnExit>
            <AddObjectAddSubObjectContainer>
              <AddObjectAddSubObjectInputs>
                <TextField label="Name" 
                  fullWidth 
                  inputRef={subObjectNameRef} 
                  required
                  value={subobject.name}
                  onChange= {(e) => handleUpdateSubobject(e, "name")}
                />
                <TextField 
                  label="Typ" 
                  select 
                  fullWidth 
                  defaultValue={"-"} 
                  inputRef={subObjectTypeRef} 
                  required
                  value={subobject.typeId}
                  onChange= {(e) => handleUpdateSubobject(e, "typeId")}
                >
                  <MenuItem value="-">-</MenuItem>
                  {types.map((type, index) =>
                    <MenuItem key={index} value={type.id}>{type.title}</MenuItem>
                  )}
                </TextField>
                <TextField 
                  label="Eigentümer" 
                  select 
                  fullWidth 
                  //defaultValue={"-"}
                  inputRef={subObjectOwnerRef} 
                  required
                  value={subobject.ownerId}
                  onChange= {(e) => handleUpdateSubobject(e, "ownerId")}
                >
                  <MenuItem value="-">-</MenuItem>
                  {owners.map((owner, index) =>
                    <MenuItem key={index} value={owner.id}>{owner.name}</MenuItem>
                  )}
                </TextField>
                <TextField 
                  label="MEA" 
                  fullWidth 
                  inputRef={subObjectMeaRef} 
                  required
                  value={subobject.mea}
                  onChange= {(e) => handleUpdateSubobject(e, "mea")}
                />
                <TextField 
                  label="Nummer" 
                  fullWidth 
                  inputRef={subObjectNumberRef} 
                  required 
                  value={subobject.number}
                  onChange= {(e) => handleUpdateSubobject(e, "number")}
                />
              </AddObjectAddSubObjectInputs>
              <AddObjectAddSubObjectButtons >
                <MyButton color="secondary" text="Abbrechen" />
                <MyButton color="secondary" text="Neuer Objekttyp" onClick={()=>handleSecondarySidebar("type")}/>
                <MyButton color="secondary" text="Neuer Eigentümer" onClick={()=>handleSecondarySidebar("owner")}/>
                <MyButton color="primary" text="Speichern" onClick={handleSaveSubObject} />
              </AddObjectAddSubObjectButtons>
            </AddObjectAddSubObjectContainer>
            
          </Grow>

          
        </AddObjectSubObjectsContainer>
      </AddObjectContent>
      
      
      </AddObjectRoot>
    </ThemeProvider>
  );
}

export default AddObject;