
import axios from 'axios';

import { api } from '../services/Api';


//#region image
export const getImage = async(id) => {
  try{
    var response = await fetch(`${api}/uploads/logos/${id}`, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
      }
    });
    response = await response.blob();
    return URL.createObjectURL(response);
  } catch(e){
    return(null);
  }
}
//#endregion

//#region Partys
export const getPartyModel = async() => {
  try{
    var response = await fetch(`${api}/party/model`, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
      }
    });
    var {data, meta, error} = await response.json();

  } catch(e) {
    error = e;
  }

  return {
    data, meta, error
  }
}

export const getPartys = async() => {
  try{
    var response = await fetch(`${api}/party/all`, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
      }
    });
    var {data, meta, error} = await response.json();

  } catch(e) {
    error = e;
  }

  return {
    data, meta, error
  }
}

export const getPartysWithUsers = async() => {
  try{
    var response = await fetch(`${api}/party/all/users`, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
      }
    });
    var {data, meta, error} = await response.json();

  } catch(e) {
    error = e;
  }

  return {
    data, meta, error
  }
}

export const getPartyLogo = async(logo) => {
  try{
    var response = await fetch(`${api}/party/logo/${logo}`, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
      }
    });
    var {data, meta, error} = await response.json();

  } catch(e) {
    error = e;
  }

  return {
    data, meta, error
  }
}

export const createParty = async(element) => {

  var formData = new FormData();
  formData.append("title", element.title);
  formData.append("logo", element.logo);

  try{
    var response = await axios.post(`${api}/party/create`, formData, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN,
        "Content-Type": "multipart/form-data",
      },
    });

    var {data, meta, error} = response.data;

  } catch(e) {
    error = e;
  }

  return {
    data, meta, error
  }
}

export const updateParty = async(element, image) => {

  var formData = new FormData();
  formData.append("id", element.id);
  formData.append("title", element.title);
  formData.append("imageId", element.imageId !== null ? element.imageId : null);
  formData.append("logo", image !== null ? image.value : null);

  try{
    var response = await axios.post(`${api}/party/update/${element.id}`, formData, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN,
        "Content-Type": "multipart/form-data",
      },
    });

    var {data, meta, error} = response.data;

  } catch(e) {
    error = e;
  }

  return {
    data, meta, error
  }
}

export const deleteParty = async(element) => {
  try{
    var response = await axios.post(`${api}/party/delete/${element.id}`, element, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
      }
    });

    var {msg, data, error} = await response.data;

  } catch(e) {
    error = e;
  }

  return {
    msg, data, error
  }
}

//#endregion

//#region votes
export const getVotes = async () => {

  var response = null;
  var data = null;
  var error = null;

  try{
      response = await fetch(api+"/Votes/", { 
        headers: {
          "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
        }
      });
      data = await response.json();

      if(!response.ok){
          error={
              message: data.message
          };
      }
  } catch(e) {
      error = e;
  }

  return {
      response, data, error
  }
}

export const getNextVotes = async() => {

  try{
    var response = await fetch(`${api}/vote/next`, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
      }
    });
    var {data, meta, error} = await response.json();

  } catch(e) {
    error = e;
  }

  return {
    data, meta, error
  }
}

export const getDoneVotes = async() => {
  
  try{
    var response = await fetch(`${api}/vote/done`, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
      }
    });
    var {data, meta, error} = await response.json();

  } catch(e) {
    error = e;
  }

  return {
    data, meta, error
  }

}

export const getVote = async(id) => {

  try{
    var response = await fetch(`${api}/vote/one/${id}`, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
      }
    });

  

    var {data, meta, error} = await response.json();

    try{
      data.seatlayout.cols = JSON.parse(data.seatlayout.cols);
      data.seatlayout.rows = JSON.parse(data.seatlayout.rows);
      data.seatlayout.seats = JSON.parse(data.seatlayout.seats);
    } catch(e){

    }



  } catch(e) {
    error = e;
  }

  return {
    data, meta, error
  }

}

export const saveVote = async(vote) => {
  vote["id_seatlayout"]=1;

  var response=null,error=null;
  try{
      response = await axios.post(api+"/Vote",vote,{ 
        headers: {
          "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
        }});
  } catch(e){
      error=e;
  }

  return {response, error};
}

export const editVote = async(vote) => {

  var response=null,error=null;

  try{
      response = await axios.put(api+"/Vote/"+vote.id,vote, { 
        headers: {
          "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
        }});
  } catch(e){
      error=e;
  }

  return {response, error};
}

export const updateVote = async(element) => {

  try{
    var response = await axios.post(`${api}/vote/update/${element.id}`, {vote: element}, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN,
      },
    });

    var {data, meta, error} = response.data;

  } catch(e) {
    error = e;
  }

  return {
    data, meta, error
  }
}

export const finishVote = async(vote) => {
  try{
    var response = await axios.post(`${api}/vote/finish/${vote.id}`, {vote: vote}, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN,
      },
    });

    var {data, meta, error} = response.data;

  } catch(e) {
    error = e;
  }

  return {
    data, meta, error
  }
}

export const createVote = async(element) => {


  try{
    var response = await axios.post(`${api}/vote/create`, {vote: element}, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN,
      },
    });

    var {data, meta, error} = response.data;

  } catch(e) {
    error = e;
  }

  return {
    data, meta, error
  }
}

export const deleteVote = async(element) => {
  try{
    var response = await axios.post(`${api}/vote/delete/${element.id}`, element, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
      }
    });
    var {msg, data, error} = await response.data;
  } catch(e) {
    error = e;
  }

  return {
    msg, data, error
  }
}

export const reorderVotes = async(items) => {

  if(items === undefined || items === null || items.length === 0){
      return;
  }

  var body=[];

  for(let i=1; i<items.length+1; i++){
    body.push({
      id: items[items.length - i].id,
      title: items[items.length - i].title,
      seq: i
    });
  }




  try{
    var response = await axios.post(`${api}/vote/reorder`, {items: body}, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
      }
    });
    var {msg, data, error} = await response.data;
  } catch(e) {
    error = e;
  }

  return {
    msg, data, error
  }

}

export const getVoteResults = async(id) => {
  return fetch(`${api}/result/vote/${id}`, { 
    headers: {
      "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
    }
  });
}

//#endregion

//#region user
export const getUsers = async() => {

  try{
    var response = await fetch(`${api}/user/all`, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
      }
    });
    var {data, meta, error} = await response.json();

  } catch(e) {
    error = e;
  }

  return {
    data, meta, error
  }

}

export const deleteUser = async(element) => {
  try{
    var response = await axios.post(`${api}/user/delete/${element.id}`, element, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
      }
    });

    var {msg, data, error} = await response.data;

  } catch(e) {
    error = e;
  }

  return {
    msg, data, error
  }
}

export const createUser = async(element) => {

  try{
    var response = await axios.post(`${api}/user/create`, {user:element}, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN,
      },
    });
   
    var {data, meta, error} = await response.data;

  } catch(e) {
    error = e;
  }

  return {
    data, meta, error
  }
}

export const saveUser = async(user) => {
    var response=null,error=null;
    
    try{
      response = await axios.post(api+"/User",user,{ 
        headers: {
          "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
        }});
      /*var formData = new FormData();
      formData.append('USER', JSON.stringify(user));
      response = await fetch(api+"/User/",{
        method: 'POST',
        body: formData
      });*/
      //response["data"]= await response.json();
    } catch(e){
        error=e;
    }

    return {response, error};
}

export const editUser = async(user) => {
  try{
    var response = await axios.post(`${api}/user/update/${user.id}`, {user: user}, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN,
      },
    });
  
    var {data, meta, error} = await response.data;

  } catch(e) {
    error = e;
  }

  return {
    data, meta, error
  }
}

//#endregion

//#region device
export const getDevices = async() => {

  try{
    var response = await fetch(`${api}/device/all`, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
      }
    });
    var {data, meta, error} = await response.json();

  } catch(e) {
    error = e;
  }

  return {
    data, meta, error
  }

}

export const createDevice = async(element) => {
  try{
    var response = await axios.post(`${api}/device/create`, element, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN,
      },
    });

    var {data, meta, error} = await response.data;

  } catch(e) {
    error = e;
  }

  return {
    data, meta, error
  }
}

export const addDevice = async(device) => {
 
    var response=null,error=null;

    try{
        response = await axios.post(api+"/Device",device, { 
          headers: {
            "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
          }});
        /*var formData = new FormData();
        formData.append('DEVICE', JSON.stringify(device));
        response = await fetch(api+"/Device/",{
          method: 'POST',
          body: formData
        });
        response = await response.json();*/
        return{response, error};
    } catch(e){
        error=e;
    }

    return {response, error};

}

export const editDevice = async(device) => {

  try{
    var response = await axios.post(`${api}/device/update/${device.id}`, {device: device}, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN,
      },
    });
  
    var {data, meta, error} = await response.data;

  } catch(e) {
    error = e;
  }

  return {
    data, meta, error
  }

}

export const deleteDevice = async(element) => {
  try{
    var response = await axios.post(`${api}/device/delete/${element.id}`, element, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
      }
    });
    var {msg, data, error} = await response.data;

  } catch(e) {
    error = e;
  }

  return {
    msg, data, error
  }
} 
//#endregion

//#region layouts
export const getAllLayouts = async() => {
  try{
    var response = await fetch(`${api}/layout/all`, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
      }
    });
  
    var {data, meta, error} = await response.json();

  } catch(e) {
    error = e;
  }

  return {
    data, meta, error
  }
}

export const getLayout = async(id) => {
  try{
    var response = await fetch(`${api}/layout/one/${id}`, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
      }
    });
    
    var {data, meta, error} = await response.json();

  } catch(e) {
    error = e;
  }

  return {
    data, meta, error
  }
   
}

export const createLayout = async(element) => {
  try{
    var response = await axios.post(`${api}/layout/create`, element, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
      },
    });
  
    var {data, meta, error} = response.data;

  } catch(e) {
    error = e;
  }

  return {
    data, meta, error
  }
}

export const saveLayout = async(layout) => {

  try{
    var response = await axios.post(`${api}/layout/update/${layout.id}`, {layout:layout}, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
      },
    });
   
    var {data, meta, error} = await response.data;

  } catch(e) {
    error = e;
  }

  return {
    data, meta, error
  }

}

export const getLayoutAsync = (id) => {
  return fetch(`${api}/layout/one/${id}`, { 
    headers: {
      "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
    }
  }); 
}

export const deleteSeatlayout = async(element) => {
  try{
    var response = await axios.post(`${api}/layout/delete/${element.id}`, element, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
      }
    });
    var {msg, data, error} = await response.data;
  } catch(e) {
    error = e;
  }

  return {
    msg, data, error
  }
}
//#endregion 

//#region results
export const saveResults = async(vote, results) => {

  /* abzuspeichern ist: id_vote, id_user, seatnum */
  /* wenn gespeichert wird, dann ist die absimmung bei vergangenen avbstimmungen zu finden */

  try{
    var response = await axios.post(`${api}/result/create`, {voteId: vote.id, value: results}, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
      },
    });
     
    var {data, meta, error} = await response.data;
  
  } catch(e) {
    error = e;
  }
  
  return {
    data, meta, error
  }

}

export const deleteResult = async(element) =>{
  try{
    var response = await axios.post(`${api}/result/delete/${element.id}`, element, { 
      headers: {
        "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
      }
    });
    var {msg, data, error} = await response.data;

  } catch(e) {
    error = e;
  }

  return {
    msg, data, error
  }
}
//#endregion

//#region etc
export const getToken = async() => {
    var response=null,error=null;

    try{
        response = await axios.get(api+"/Token/", { 
          headers: {
            "x-dacor-auth-token": process.env.REACT_APP_AUTH_TOKEN
          }});
    } catch(e){
        error=e;
    }

    if(response !== null && response.data !== undefined){
        return response.data;
    } else if(error !== null){
        return error;
    }
    return null;
}

//#endregion