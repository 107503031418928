/*import MainMenu from "./routes/MainMenu";*/
import React from 'react';
import { 
  ThemeProvider,
} from '@mui/material';
import { 
  Outlet, 
} from 'react-router-dom';
import styled from '@emotion/styled';
import useAuth from './hooks/user.hook';
import useMandantTheme from './hooks/mandantTheme.hook';
import Login from './routes/Login';
import MySidebar from './layout-components/MySidebar';
import MyVoteStateModul from './layout-components/MyVoteStateModul';
import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import MyAlertsProvider from './layout-components/MyAlerts/MyAlertsProvider';

const AppRoot = styled.div((props) => ({
  height: "calc(100vh)",
  width: "calc(100vw)",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  backgroundColor: props.theme.palette.background.main,
  //border: "5px solid red",
}));

const AppContent = styled.div((props) => ({
  height: "calc(100% - 0px)",
  width: "calc(100% - 0px)",
  overflow: "hidden",
  display: "flex",
}))

const AppContentContainer = styled.div((props) => ({
  height: "calc(100% - 0px)",
  width: "calc(100% - 0px)",
}));

/*
  App ist die höchste Komponente
  Die wird immer aufgerufen, wenn die App 
*/

const App = () => {

  /* props */

  /* states */

  /* hooks */
  const user = useAuth();
  const mandantTheme = useMandantTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    if(user.token){
      dispatch({type: 'socket/connect'});
    }
  }, [user.token]);

  if(!user.token){
    return(
      <Login />  
    );
  }
    
  return (
    <ThemeProvider theme={mandantTheme}>
      <AppRoot>
        <AppContent>
          <MySidebar />
          <MyVoteStateModul />
          <MyAlertsProvider />
          <AppContentContainer>
            <Outlet />
          </AppContentContainer>
        </AppContent>
      </AppRoot>
    </ThemeProvider>
  );
}

export default App;
