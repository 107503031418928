/*import MainMenu from "./routes/MainMenu";*/
import React from 'react';
import {
  IconButton, 
  useTheme,
  //makeStyles, 
} from '@mui/material';
import styled from '@emotion/styled';
import { ThemeProvider } from '@emotion/react';

import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import EventSeatOutlinedIcon from '@mui/icons-material/EventSeatOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import GroupIcon from '@mui/icons-material/Group';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupsIcon from '@mui/icons-material/Groups';


import { useDispatch, useSelector } from 'react-redux';

import Logo from "../../images/logo.png";
import MySideBarMenuItem from './MySideBarMenuItem';
import MyImage from '../MyImage';
import MyButton from '../MyButton/MyButton';
import authService from '../../services/auth.service';
import { clearAuth } from '../../redux/slices/authSlice';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { PermScanWifi, Refresh, TypeSpecimen } from '@mui/icons-material';
import SidebarConnectionInfo from './SidebarConnectionInfo';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import texts from '../../texts';

const links = [
  {
    title: texts.meetings.plu,
    route: "/",
    icon: <AssignmentOutlinedIcon />
  },
  {
    title: texts.objects.plu,
    route: "/objects",
    icon: <HomeOutlinedIcon />
  },
  {
    title: texts.owners.plu,
    route: "/owners",
    icon: <AssignmentIndOutlinedIcon />
  },
  {
    title: texts.types.plu,
    route: "/types",
    icon: <TypeSpecimen />
  },
  {
    title: texts.devices.plu,
    icon: <SmartphoneIcon />,
    route: "/devices"
  },
  {
    title: texts.communities.plu,
    icon: <GroupsIcon />,
    route: "/communities",
  }
];


const MySideBarRoot = styled.div((props) => ({
  height: "100%",
  width: props.open ? "250px" : "45px",
  borderRight: "1px solid rgba(0, 0, 0, 0.3)",
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  transition: "width 0.25s",
}));

const MySideBarHeader = styled.div((props)  => ({
  display: "flex",
  padding: props.open ? "5px" : "5px 0px 5px 0px",
  justifyContent: "center",
  borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
  backgroundColor: props.theme.palette.primary.main,
  alignItems: "center",
  flexDirection: "column",
  position: "relative",
}));

const MySideBarHeaderLogo = styled.div((props) => ({
  transition: "width 0.25s",
  width: props.open ? "100%" : "25px",
  height: "100px",
  "& .img": {
    objectFit: "contain",
    height: "100%",
    width: "100%",
    maxHeight: "100%",
  },
}));

const MySideBarCloseIcon = styled.div((props) => ({
  backgroundColor: "rgba(0, 0, 0, 0.4)",
  position: "absolute",
  borderRadius: "100%",
  transition: "transform 0.25s",
  zIndex: "+1",
  transform: props.open ? "rotate(0deg)" : "rotate(180deg)",
  bottom: props.open ? "-25px" : "-15px",
  right: props.open ? "-25px" : "-15px",
}));

const MySideBarItems = styled.div({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  overflowY: "auto",
});

const MySidebar = () => {

  const [open, setOpen] = React.useState(true);

  const theme = useTheme();
  const classes = {}; //useStyles(theme);
  const redux = useSelector((state) => state);
  const socket = useSelector((state) => state.socket);
  const user = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch({type: "socket/disconnect"});
  }

  return(
    <ThemeProvider theme={theme}>
      <MySideBarRoot open={open}>
        <MySideBarHeader open={open}>
          {/* logo */}
          <MySideBarHeaderLogo open={open}>
            <MyImage src={user.mandant.image} />
            
          </MySideBarHeaderLogo>
          <MySideBarCloseIcon open={open}>
            <IconButton onClick={()=>setOpen(!open)} size={open ? "medium" : "small" }>
              <ChevronLeftIcon />
            </IconButton>
          </MySideBarCloseIcon>
        </MySideBarHeader>
        <MySideBarItems>
          {links.map((link, index) => 
            <MySideBarMenuItem key={index} title={link.title} icon={link.icon} route={link.route} open={open} />
          )}
          <MySideBarMenuItem title={texts.options.plu} icon={<SettingsIcon />} open={open} route={"/options"}/>
        </MySideBarItems>

        <SidebarConnectionInfo sidebarClosed={!open}/>
        {/*<SocketInfo socket={socket} />*/}
        
        
          {open 
          ? <div style={{
              padding: "2vh",
            }}>
              <MyButton text="Ausloggen" onClick={handleLogout} startIcon={<LogoutOutlinedIcon />}/>
            </div>
          : <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "1rem 0rem"
            }}>
              <IconButton color="primary">
                <LogoutOutlinedIcon />
              </IconButton>
            </div> 
          
          }
        
      </MySideBarRoot>
    </ThemeProvider>
  );
}

export default MySidebar;