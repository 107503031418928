import React, { useEffect } from "react";
import MyList from "../../layout-components/MyList/index";
import ObjectListItem from "./ObjectListItem";
import objectService from "../../services/object.service";
import MyPage from "../../layout-components/MyPage";
import MyPageContent from "../../layout-components/MyPage/MyPageContent";
import MyPageTitle from "../../layout-components/MyPage/MyPageTitle";
import texts from "../../texts";


const Objects = () => {

  const [objects, setObjects] = React.useState([]);

  useEffect(() => {
    init();
  }, []);

  const init = async() => {
    objectService.getAll()
    .then((response) => {
      setObjects(response);
    })
  }

  return(
    <MyPage>
      <MyPageTitle>
        {texts.objects.h_plu}
      </MyPageTitle>
      <MyPageContent style={{display: "flex", flexDirection: "column",}}>
        <MyList 
          component={<ObjectListItem />} 
          elements={objects}
          searchProps={"name"}
          addButton={
          <div>
           {/*<MyButton text="Neu" startIcon={<AddCircleOutlineOutlinedIcon color="secondary" />} onClick={() => navigate('/object/add')} />*/}
          </div>
          }
        />
      </MyPageContent>
    </MyPage>
  );

}

export default Objects;