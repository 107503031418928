import { Routes, Route } from "react-router-dom"
import Login from "./Login";
import App from "../App";
import NoMatch from "./NoMatch/index.js";
///import Profile from "./Profile/index.js";
import Objects from "./Objects";
import AddObject from "./Objects/AddObject.js";
import Owners from "./Owners/index.js";
import OwnerList from "./Owners/OwnerList.js";
import DeviceList from "./Devices/DeviceList.js";
import OwnerAdd from "./Owners/OwnerAdd.js";
import NextVotes from "./Meetings/index.js";
import AddMeeting from "./Meetings/AddMeeting.js";
import Vote from "./Vote/index.js";
import Options from "./Options/index.js";
import Types from "./Types/index.js";
import Object from "./Object/index.js";
import ProcessingMeeting from "./ProcessingMeeting/index.js";
import CommunityList from "./Communties/CommunityList.js";
import Meeting from "./Meeting/index.js";
import Run from "./Run/index.js";

const RouteProvider = () => {
  return(
    <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<NextVotes />} />
          <Route path="/meeting/add" element={<AddMeeting />} />
          <Route path="/meeting" element={<Meeting />} />
          <Route path="/objects" element={<Objects />} />
          <Route path="/object/add" element={<AddObject />} />
          <Route path="/object/edit" element={<AddObject />} />
          <Route path="/object" element={<Object />} />
          <Route path="/owners" element={<OwnerList />} />
          <Route path="/owner/add" element={<OwnerAdd />} />
          <Route path="/devices" element={<DeviceList />} />
          <Route path="/vote" element={<Vote />} />
          <Route path="/options" element={<Options />} />
          <Route path="/types" element={<Types />} />
          <Route path='/run' element={<Run />} />
          <Route path='/communities' element={<CommunityList />} />
          {/*
          <Route path="profile" element={<Profile />} />
          <Route path="*" element={<NoMatch />} />*/}
        </Route>

        <Route path="login" element={<Login />} />
      
        <Route path="*" element={<NoMatch />} />
    </Routes>
  )
}

export default RouteProvider;