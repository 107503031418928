import React from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import SyncIcon from '@mui/icons-material/Sync';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { keyframes } from '@emotion/react';

const StartVoteOverlayRoot = styled.div((props) => ({
  border: "1px solid rgba(0, 0, 0, 0.2)",
  padding: "1rem",
  position: "absolute",
  right: "0rem",
  top: "1rem",
  backgroundColor: props.theme.palette.primary.main,
  zIndex: "+1",
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  borderRadius: "75px 0px 0px 75px",
  transition: "width 0.25s",
  transform: props.hide ? "translateX(500px)" : "translateX(0px)",
  transition: "transform 1s",
}))

const RotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg)
  }
`
const IconContainer = styled.div((props) => ({
  height: "75px",
  width: "75px",
  backgroundColor: props.theme.palette.secondary.main,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
}))

const StyledSyncIcon = styled(SyncIcon)((props) => ({
  width: "100%",
  height: "100%",
  color: "rgba(0, 0, 0, 0.7)",
  animation: `${RotateAnimation} 2s infinite`,
}))

const StyledInfoIcon = styled(InfoOutlinedIcon)((props) => ({
  width: "100%",
  height: "100%",
  color: "rgba(0, 0, 0, 0.7)",
}))

const StartVoteOverlay = (props) => {

  const {

  } = props;

  
  const [hide, setHide] = React.useState(false);
  const vote = useSelector((state) => state.vote);

  return null;

  if(!vote.started){
    return null;
  }

  if(vote.started && vote.prepared){
    setTimeout(() => {
      setHide(true);
    }, 2500)
    return(
      <StartVoteOverlayRoot hide={hide}>
        <IconContainer>
          <StyledInfoIcon />
        </IconContainer>
        <div>
          <div style={{fontSize: "1.3rem"}}>Abstimmung läuft</div>
        </div>
      </StartVoteOverlayRoot>
    );
    
  }

  return(
    <StartVoteOverlayRoot>
      <IconContainer>
        <StyledSyncIcon />
      </IconContainer>
      <div>
        <div style={{fontSize: "1.3rem"}}>Abstimmung wird vorbereitet</div>
        <div style={{fontSize: "0.85rem"}}>Mehr Informationen...</div>
      </div>
      {/*
      <div style={{
        display: "grid",
        gridTemplateColumns: "auto auto",
      }}>
        <div>
          {vote.prepared.found !== null
          ? vote.prepared.found
            ? "found"
            : "not found"
          : "not init"
          }
        </div>
        <div>Finde die Abstimmung</div>

        <div>
          {vote.prepared.checkProcessing !== null
          ? vote.prepared.checkProcessing
            ? "found"
            : "not found"
          : "not init"
          }
        </div>
        <div>Überprüfe bereits laufende Abstimmungen</div>

        <div>
          {vote.prepared.layout !== null
          ? vote.prepared.layout
            ? "found"
            : "not found"
          : "not init"
          }
        </div>
        <div>Finde Sitzplan</div>

        <div>
          {vote.prepared.users !== null
          ? vote.prepared.users
            ? "found"
            : "not found"
          : "not init"
          }
        </div>
        <div>Finde Teilnehmer</div>

        <div>
          {vote.prepared.devices !== null
          ? vote.prepared.devices
            ? "found"
            : "not found"
          : "not init"
          }
        </div>
        <div>Finde Abstimmgeräte</div>

        <div>
          {vote.prepared.tbConnect !== null
          ? vote.prepared.tbConnect
            ? "found"
            : "not found"
          : "not init"
          }
        </div>
        <div>Verbinde mit Thingsboard</div>

        <div>
          {vote.prepared.overall !== null
          ? vote.prepared.overall
            ? "found"
            : "not found"
          : "not init"
          }
        </div>
        <div>Overall</div>
      </div>
      */}
    </StartVoteOverlayRoot>
  );
}

export default StartVoteOverlay;