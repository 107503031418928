import { createSlice } from '@reduxjs/toolkit'

export const voteSlice = createSlice({
  name: 'vote',
  initialState: {},
  reducers: {
    setVote: (state, action) => {
      //state = action.payload;
      return {
        ...action.payload
      };
    },
    clearVote: (state) => {
     
    },
    startVote: (state) => {
      
    },
    pauseVote: (state) => {
      
    },
    stopVote: (state) => {
     
    },
    votePrepared: (state) => {
      
    },
    prepare: (state, action) => {
      
    },
    clearResults: (state) => {
      
    },
    setResults: (state, action) => {
      //state = action.payload;
      return {
        ...state,
        ...{results: action.payload}
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setVote, startVote, pauseVote, stopVote, prepare, votePrepared, clearResults, setResults, clearVote} = voteSlice.actions

export default voteSlice.reducer