import axios from "axios";
import {store} from '../redux/store';

const api = process.env.REACT_APP_API_URL;
const dacorAuthToken = process.env.REACT_APP_AUTH_TOKEN;

const getOne = (voteId) => {
  var state = store.getState();

  return axios
    .get(`${process.env.REACT_APP_API_URL}/user/vote/one/${voteId}`, {
      headers: {
        "x-dacor-auth-token": dacorAuthToken,
        "x-access-token": state.auth.token,
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    })
}

const getNextVotes = () => {
  var state = store.getState();

  return axios
    .get(`${process.env.REACT_APP_API_URL}/user/vote/next/all`, {
      headers: {
        "x-dacor-auth-token": dacorAuthToken,
        "x-access-token": state.auth.token
      }
    })
    .then((response) => {
      return response.data;
    });
}

const create = (vote, objectId) => {
  var state = store.getState();
  vote.objectId = objectId;

  return axios
    .post(`${api}/user/vote/create`, vote, {
      headers: {
        "x-dacor-auth-token": dacorAuthToken,
        "x-access-token": state.auth.token
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    })
}

const reorderSubvotes = (headvote) => {
  var state = store.getState();

  return axios
    .post(`${api}/user/vote/reorder`, headvote, {
      headers: {
        "x-dacor-auth-token": dacorAuthToken,
        "x-access-token": state.auth.token
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    })
}

const getVoteResult = (id) => {
  var state = store.getState();

  return axios
    .post(`${api}/user/vote/result`, {id:id}, {
      headers: {
        "x-dacor-auth-token": dacorAuthToken,
        "x-access-token": state.auth.token
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    })
}

const getVoteResults = (id) => {
  var state = store.getState();

  return axios
    .post(`${api}/vote/results/all`, {id:id}, {
      headers: {
        "x-dacor-auth-token": dacorAuthToken,
        "x-access-token": state.auth.token
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    })
}

const exportVote = (id) => {
  var state = store.getState();

  return axios
    .post(`${api}/user/vote/export`, {id:id}, {
      headers: {
        "x-dacor-auth-token": dacorAuthToken,
        "x-access-token": state.auth.token,
      },
      responseType: 'blob'
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    })
}

const exportDevices = (id) => {
  var state = store.getState();

  return axios
    .post(`${api}/user/vote/devices/export`, {id:id}, {
      headers: {
        "x-dacor-auth-token": dacorAuthToken,
        "x-access-token": state.auth.token,
      },
      responseType: 'blob'
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    })
}

const removeVote = (vote) => {
  var state = store.getState();

  return axios
    .post(`${api}/user/vote/remove`, {id:vote.id}, {
      headers: {
        "x-dacor-auth-token": dacorAuthToken,
        "x-access-token": state.auth.token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    })
}

const generateNewSeatlayout = (vote) => {
  var state = store.getState();

  return axios
    .post(`${api}/user/vote/seatlayout/generate`, {voteId: vote.id}, {
      headers: {
        "x-dacor-auth-token": dacorAuthToken,
        "x-access-token": state.auth.token,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => console.log(e));
}

export default{
  getOne,
  getNextVotes,
  create,
  reorderSubvotes,
  getVoteResults,
  getVoteResult,
  exportVote,
  exportDevices,
  removeVote,
  generateNewSeatlayout
}