import React from 'react';
import { Collapse, Grow, IconButton } from '@mui/material';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import GridTable from '../../layout-components/GridTable';
import GridTableHead from '../../layout-components/GridTable/GridTableHead';
import { useNavigate } from 'react-router-dom';

const ObjectListItem = (props) => {

  const {
    element
  } = props;

  const [showSubs, setShowSubs] = React.useState(false);
  const navigate = useNavigate();

  const countUniqueOwners = () => {
    var count = 0;
    var temp = [];
    for(let i=0; i < element.subobjects.length; i++){
      if(!temp.includes(element.subobjects[i].owner.name)){
        temp.push(element.subobjects[i].owner.name);
        count++;
      }
    }

    return count;
  }

  const sumUpMea = () => {
    var count = 0;

    for(let i=0; i<element.subobjects.length; i++){
      var value = element.subobjects[i].mea;
      value = value.replace(',', '.');
      count += Number(value);
    }

    count = count.toFixed(2);

    count.toString().replace(".", ",");
    return count;
  }

  return(
    <div style={{
      display: "flex",
      borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
      //border: "1px solid black",
      gap: "1rem",
      padding: "1rem 1rem",
    }}>
      <HouseOutlinedIcon style={{
        fontSize: "3rem",
        opacity: "0.5"
      }}/>
      <div style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        height: "100%",
        gap: "1rem",
      }}>
        <div style={{
          display: "flex",
          
        }}>
          <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
            <div style={{
              fontSize: "1.25rem"
            }}>{element.name}</div>
            <div style={{
              opacity: "0.5"
            }}>Name</div>
          </div>

          <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
            <div style={{
              fontSize: "1.25rem"
            }}>{element.number}</div>
            <div style={{
              opacity: "0.5"
            }}>Nummer</div>
          </div>
        </div>
        

        <div style={{
          display: "flex",
          marginTop: "auto",
          marginButton: "0px",
        }}>
          <div style={{
            width: "100%",
            textAlign: "left",
          }}>
            {element.subobjects.length} Teilobjekte mit {sumUpMea()}m² und {countUniqueOwners()} verschiedenen Anteilseignern.
            <div style={{
              fontSize: "0.8rem",
              opacity: "0.7",
              cursor: "pointer",
              fontStyle: "italic",
            }}
            onClick={()=>setShowSubs(true)}
            >Anzeigen</div>
          </div>
          
        </div>
        
        <Collapse in={showSubs}>
          <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}>
            <div style={{
              display: "flex",
              gap: "15px",
              alignItems: "flex-end",
            }}>
              <div>Teilobjekte</div>
              <div style={{
                fontSize: "0.8rem",
                opacity: "0.7",
                cursor: "pointer",
                fontStyle: "italic",
              }}
              onClick={()=>setShowSubs(false)}
              >Ausblenden</div>
            </div>
            <GridTable cols={6}>
              <GridTableHead>
                <tr>
                  <th>Name</th>
                  <th>Nummer</th>
                  <th>MEA</th>
                  <th>Typ</th>
                  <th>Eigentümer</th>
                  <th>UG</th>
                </tr>
              </GridTableHead>
              {element.subobjects.map((sub, index) =>
                <tbody key={index}>
                  <tr>
                    <td>{sub.name}</td>
                    <td>{sub.number}</td>
                    <td>{sub.mea}</td>
                    <td>{sub.type.title}</td>
                    <td>{sub.owner.name}</td>
                    <td>{sub.community}</td>
                  </tr>
                </tbody>
              )}
            </GridTable>
          </div>
        </Collapse>
      </div>
    </div>
  );

}

export default ObjectListItem;