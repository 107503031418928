import React from 'react';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

const ListItemRoot = styled.div((props) => ({
  display: "flex",
  borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
  gap: "1rem",
  padding: "1rem 1rem",
  cursor: "pointer",
  position: "relative",
  transition: "background-color 0.2s",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.15)"
  }
}));

const StyledIcon = styled(HouseOutlinedIcon)((props) => ({
  fontSize: "3rem",
  opacity: "0.5",
  color: props.outdated === "true" ? "red" : "unset",
}));

const DateInfo = styled.div((props) => ({
  position: "absolute",
  top: "0",
  right: "0",
  fontSize: "0.75rem",
  gap: "0.5rem",
}))

const InfoElement = styled.div((props) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& div:first-of-type": {
    fontSize: "1.25rem",
  },
  "& div:last-of-type": {
    opacity: "0.5",
  }
}))

const ObjectListItem = (props) => {

  const {
    element
  } = props;

  const navigate = useNavigate();

  return(
    <ListItemRoot onClick={() => navigate(`/object#${element.id}`)}>
      <StyledIcon outdated={element.outdated.toString()} />
      <DateInfo>
        <div>{new Date(element.createdAt).toLocaleTimeString()}</div>
        <div>{new Date(element.createdAt).toLocaleDateString()}</div>
      </DateInfo>
      <InfoElement>
        <div>{element.name}</div>
        <div>Name</div>
      </InfoElement>
      <InfoElement>
        <div>{element.number}</div>
        <div>Nummer</div>
      </InfoElement>
      <InfoElement>
        <div>{element.mea}</div>
        <div>MEA</div>
      </InfoElement>
    </ListItemRoot>
  );

}

export default ObjectListItem;