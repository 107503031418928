import React,{ useEffect } from "react";
import { useLocation } from "react-router-dom";
import voteService from "../../services/vote.service";
import RunMeeting from "./RunMeeting";
import RunVote from "./RunVote";
import { useDispatch, useSelector } from "react-redux";

const Run = () => {
  
  const [id, setId] = React.useState(null);
  const [vote, setVote] = React.useState(null);

  const location = useLocation();
  const socket = useSelector((state) => state.socket);

  const dispatch = useDispatch();

  useEffect(() => {
    if(!socket.connected){
      dispatch({type: 'socket/reconnect'})
    }
  }, [])


  useEffect(() => {
    var hash = location.hash.slice(1);
    if(hash !== ""){
      setId(hash);
    }
  }, [location.hash]);
  
  useEffect(() => {
    if(id !== null){
      voteService.getOne(id)
        .then((res) => setVote(res.data))
        .catch((e) => console.log(e));
    }
  }, [id]);

  if(!socket.connected){
    return(
      <div>
        <div>Nicht Verbunden!</div>
        <div>Verbinde...</div>
      </div>
    );
  }

  if(vote === null){
    return("loading");
  }

  if(vote.headvote === null){
    return(
      <RunMeeting vote={vote} />
    );
  }

  return(
    <RunVote vote={vote} />
  );
}

export default Run;