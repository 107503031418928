import styled from '@emotion/styled/macro';
import MyPage from '../../layout-components/MyPage';
import MyPageTitle from '../../layout-components/MyPage/MyPageTitle';
import MyPageContent from '../../layout-components/MyPage/MyPageContent';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogTitle, DialogContent, IconButton, keyframes, Tooltip, Menu, MenuItem, Divider, ListItemText, ListItemIcon, Typography } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {MyLayoutList as MyLayout} from '../../layout-components/MyLayout';
import voteService from '../../services/vote.service';
import MyLineChart from '../../layout-components/MyLineChart';
import { setMeeting } from '../../redux/slices/meetingSlice';
import SyncIcon from '@mui/icons-material/Sync';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import GridTable from '../../layout-components/GridTable';
import GridTableHead from '../../layout-components/GridTable/GridTableHead';
import UserList from '../../layout-components/UserList';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import GroupsIcon from '@mui/icons-material/Groups';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SettingsIcon from '@mui/icons-material/Settings';
import texts from '../../texts';
import { Check, CheckBox, Close } from '@mui/icons-material';


/*
  ProcessingMeeting.js
  Übersichtseite einer laufenden Abstimmung

  update: 18.09 - layout änderungen, weil auf kleineren displays elemente zu viel platz wegnehmen

*/

const ProcessingMeetingRoot = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1vh",
  height: "100%",
  width: "100%", 
}));

const ProcessingMeetingContent = styled.div((props) => ({
  height: "calc(100% - 2px)",
  width: "calc(100% - 2px)",
  display: "flex",
  overflow: "hidden",
  gap: "2vh",
}));

const Column = styled.div((props) => ({
  height: "calc(100% - 2px)",
  width: "calc(100% - 2px)",
  borderRadius: "1vh",
  border: "1px solid rgba(0, 0, 0, 0.2)",
  display: "flex",
  flexDirection: "column",
}));

const ProcessingMeetingTitle = styled.div((props) => ({
  padding: "1vh",
  border: "1px solid rgba(0, 0, 0, 0.2)",
  borderRadius: "1vh",
  boxShadow: "1px 1px 1px 0px rgba(0, 0, 0, 0.2)",
  gridArea: "header",
  display: "flex",
  alignItems: "center",
}));

const ProcessingMeetingVotes = styled.div((props) => ({
  gridArea: "votes",
  width: "100%",
  height: "calc(100%)",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
}));

const ProcessingMeetingVotesHeading = styled.div((props) => ({
  border: `1px solid ${props.theme.palette.gray.main}`,
  borderRadius: "0.5rem 0.5rem 0 0",
  padding: "0.5rem",
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  //fontWeight: "600",
  backgroundColor: props.theme.palette.gray.dark,
  color: "black"
}));

const ProcessingMeetingVotesContainer = styled.div((props) => ({
  border: "1px solid rgba(0, 0, 0, 0.2)",
  display: "grid",
  gridTemplateColumns: "auto auto 1fr auto auto",
  //alignItems: "start",
  justifyContent: "center",
  gridAutoRows: "60px",
  alignItems: "stretch", 
  //height: "calc(100% - 2px)",
  borderRadius: "0rem 0rem 0.5rem 0.5rem",
  height: "calc(100% - 2px)",
  overflowY: "auto",
}));

const ProcessingMeetingVote = styled.div((props) => ({
  display: "contents",
  minHeight: "0",
  [ProcessingMeetingVoteTitle]: {
    transition: "font-size 1s, font-weight 1s",
    color: props.selected === true ? "blue" : "inherit",
    fontSize: props.selected === true ? "1.3rem" : "inherit",
    textDecoration: props.selected === true ? "underline" : null,
    color: props.active ? "red" : null,
    fontSize: props.active ? "1.3rem" : "inherit",
  } 
}));

const ProcessingMeetingVoteDone = styled.div((props) => ({
  padding: "1rem",
  //borderLeft: "1px solid rgba(0, 0, 0, 0.2)",
  borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
  //border: "1px solid red",
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
  //backgroundColor: "grey",
}));

const ProcessingMeetingVoteTitle = styled.div((props) => ({
  //backgroundColor: "yellow",
  padding: "1rem",
  borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
  //border: "1px solid blue",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  "&:hover": {
    opacity: "0.7",
  }
}));

const ProcessingMeetingVoteResult = styled.div((props) => ({
  //backgroundColor: "yellow",
  padding: "1rem",
  borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
  //borderRight: "1px solid rgba(0, 0, 0, 0.2)",
  //border: "1px solid green",
}))

const ProcessingMeetingLayout = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  height: "calc(100% - 2px - 1rem)",
  gridArea: "layout",
  borderTop: "1px solid rgba(0, 0, 0, 0.2)",
  padding: "1vh",
  overflow: "auto",
}))

const ProcessingMeetingLayoutHeader = styled.div((props) => ({
  //backgroundColor: props.theme.palette.primary.main,
  //color: props.theme.palette.secondary.main,
  padding: "1vh",
}));

const ProcessingMeetingLayoutContent = styled.div((props) => ({
  height: "100%",
  //backgroundColor: "red",
  overflow: "auto",
}));

const ProcessingMeetingButtons = styled.div((props) => ({
  gridArea: "buttons",
  //backgroundColor: "red",
  border: "1px solid rgba(0, 0, 0, .2)",
  borderRadius: "0.5rem",
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
}))

const ProcessingMeetingInfos = styled.div((props) => ({
  gridArea: "info",
  //backgroundColor: "red",
  border: "1px solid rgba(0, 0, 0, .2)",
  borderRadius: "0.5rem",
}))

const ProcessingMeetingProgress = styled.div((props) => ({
}))

const StlyedSyncIcon = styled(SyncIcon)((props) => ({
  fontSize: "1.5rem",
  animation: `${RotateAnimation} 2s infinite`,
}));

const RotateAnimation = keyframes`
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg)
  }
`;

const StyledCheckContainer = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "2px",
  backgroundColor: props.theme.palette.success.main,
  color: "white",
}))

const StyledCrossContainer = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "2px",
  backgroundColor: props.theme.palette.error.main,
  color: "white",
}))

/*
  Diese Seite funktioniert nur mit Websocket Informationen
  Diese Seite wirdaufgerufen, wenn anhand der URL-ID eine Abstimmung vom Server geholt wird und die
  dazugehörige Abstimmung ein Meeting ist (headvote === null).

  Aktuell:
  Beim Aufrufen dieser Seite wird die zuvorgeholte Abstimmung übergeben und als headvote im State gespeichert.
  Wenn sich dadurch dann der headvote-state ändert, wird eine Websocket-Message geschickt, die das Init dieses
  Meeting auf dem Server startet.
  
*/


const RunMeeting = (props) => {
  
  const {
    vote=null,
  } = props;

  const [id, setId] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [index, setIndex] = React.useState(-1);
  const [headvote, setHeadvote] = React.useState(null);
  const [selectedSubvote, setSelectedSubvote] = React.useState(null);
  const [openDevicesRegistration, setOpenDeviceRegistration] = React.useState(false);
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const menuOpen = Boolean(menuAnchor);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const reduxMeeting = useSelector((state) => state.meeting);
  const reduxVote = useSelector((state) => state.vote);

  useEffect(() => {
    if(vote !== null){
      setHeadvote(vote);
    }
  },[vote]);
  
  useEffect(() => {
    if(headvote !== null){
      //feuer nicht direkt das init

      dispatch({type: 'meeting/init', data: headvote.id});
    }
  }, [headvote])

  useEffect(() => {
    
  }, [reduxMeeting]);

  /*useEffect(() => {
    if(reduxMeeting !== null && reduxMeeting.id !== undefined){
      setIndex(reduxMeeting.voteIndex);
    } else if (vote !== null) {
      voteService.getOne(vote.id)
        .then((response) => setHeadvote(response.data))
        .catch((e) => console.log(e));
    }
  }, [reduxMeeting, vote]);*/


  const handleIndex = (val) => {
    if(val === 0 ){
      setIndex(0);
    } else {
      setIndex(index + val)
    }
  }

  const handleStart = () => {
    dispatch({type: 'meeting/start', data: headvote.id});
  }

  const handleStop = () => {
    dispatch({type: 'meeting/stop', data: headvote.id});
    //dispatch(setMeeting(null));
  }

  const handleEnd = () => {
    dispatch({type: 'meeting/end', data: headvote.id});
    navigate('/');
  }

  const handleReopen = () => {
    //dispatch({type: 'meeting/end', data: headvote.id});
  }

  const handleNext = () => {
    dispatch({type: 'meeting/next', data: headvote.id});
  }

  const deviceRegistration = () => {

  }

  const handleMenuItemClick = () => {

  }

  const openPresentation = () => {
    window.open(process.env.REACT_APP_PRES_URL, '_blank');
  }

  const handleSelectSubvote = (sub, index) => {
    if(selectedSubvote === null){
      if(reduxMeeting.subvotes[index].id !== reduxVote.id){
        setSelectedSubvote(index)
      }
    } else {
      if(index !== selectedSubvote && reduxMeeting.subvotes[index].id !== reduxVote.id){
        setSelectedSubvote(index)
      } else {
        setSelectedSubvote(null);
      }
    }
  }


  console.log("headvote", headvote);
  console.log("reduxMeeting", reduxMeeting);

  if(headvote === null){
    return (
      <div>
        <div>loading...</div>
      </div>
    );
  }

  if(reduxMeeting === null || Object.keys(reduxMeeting).length <= 0){
    return (
      <div>
        <div>loading websocket ...</div>
        <div style={{display: "none"}}>
          <Button onClick={() => dispatch({type: 'meeting/init', data: headvote.id})}>Init Debug</Button>
        </div>
      </div>
    );
  }

  /* 
    wenn diese seite aufgerufen wird, dann ist eine sitzung gestartet
    allerdings muss die erste abstimmung noch gestartet werden
    => ich brauch wieder einen start button
  */
  
    console.log("selectedSup", selectedSubvote);
    console.log("reduxMeeting", reduxMeeting);

  return(
    <MyPage>
      <MyPageTitle size="small">
        {texts.votes.sing} durchführen
        <div style={{marginRight: "0", marginLeft: "auto"}}>
          <Button variant="contained" color="primary" onClick={() => navigate(-1)}>Zurück</Button>
        </div>
      </MyPageTitle>

      <MyPageContent style={{overflow: "hidden"}}>
        <ProcessingMeetingRoot>
          <ProcessingMeetingTitle size="small">
            <div style={{ display: "flex", gap: "0.5rem", alignItems: "center"}}>
              {headvote.type === "incognito"
              ? <Tooltip title="Nicht namentliche Abstimmung">
                  <VisibilityOffIcon color="gray" />
                </Tooltip>
              : null
              }
              {headvote.title}
            </div>
           
            <div style={{display: "flex", gap: "0.5rem", marginRight: "0", marginLeft: "auto"}}>
              <button onClick={()=>console.log("reduxVote", reduxVote)}>print</button>

              {/* 
                reduxMeeting === null => sollte eigentlich nicht mehr vorkommen, weil beim aufruf ein meeting abgerufen wird

                reduxMeeting === processing ist eigentlich gleich mit started !== null
                  -> bedeutet, dass ein Meeting gestartet wurde und durchgeführt wird
                
                reduxMeeting === processing && reduxVote === null bzw. nicht gesetzt
                  -> meeting wurde unterbrochen, muss wieder fortgesetzt werden
              */}

              {!reduxMeeting.model.processing
              ? <Button variant='contained' onClick={handleStart}>Start</Button>
              : null
              }

              {reduxMeeting.model.processing && reduxMeeting.model.stopped && reduxVote.id === undefined
              ? <Button variant='contained' onClick={handleStart}>Fortsetzen</Button>
              : null
              }

              {reduxMeeting.model.processing && reduxVote.id !== undefined && reduxMeeting.voteIndex < reduxMeeting.subvotes.length -1
              ? <Button variant='contained' onClick={handleNext}>{texts.votes.b_next}</Button> 
              : null
              }

              {reduxMeeting.model.processing && reduxVote.id !== undefined 
              ?  <Button variant='contained' onClick={handleStop}>Stop</Button> 
              : null
              }

              {reduxMeeting.model.processing && reduxMeeting.model.stopped 
              ?  <Button variant='contained' onClick={handleEnd}>Beenden</Button> 
              : null
              }


              <Button startIcon={<OpenInNewIcon />} onClick={openPresentation}>
                Präsentation
              </Button>

              
            </div>
          </ProcessingMeetingTitle>
          <ProcessingMeetingContent>
            <Column>
              <ProcessingMeetingVotes>
                <ProcessingMeetingVotesHeading>
                  {texts.votes.plu}
                </ProcessingMeetingVotesHeading>
                <ProcessingMeetingVotesContainer>
                  {reduxMeeting.subvotes.map((sub, i) =>
                    <ProcessingMeetingVote key={i} active={sub.id === reduxVote.id} selected={selectedSubvote !== null && reduxMeeting.subvotes[selectedSubvote].id === sub.id ? true : false}>
                      <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
                      }}>
                      {reduxVote.id === sub.id
                      ? <StlyedSyncIcon />
                      : null
                      }
                      </div>
                      
                      <ProcessingMeetingVoteDone>
                        {sub.model.done 
                        ? <Tooltip title="Bereits durchgeführt">
                            <CheckCircleOutlineOutlinedIcon color="success"/>
                          </Tooltip> 
                        : <Tooltip title="Noch nicht durchgeführt">
                            <CancelOutlinedIcon color="error" />
                          </Tooltip>
                        }
                        {sub.model.type === "qualified"
                        ? <Tooltip title="Qualifizierter Beschluss">
                            <NewReleasesIcon color="gray" />
                          </Tooltip>
                        : <NewReleasesIcon color="gray" sx={{opacity: "0"}}/>
                        }
                        {sub.model.communities !== undefined && sub.model.communities.length > 0
                        ? <Tooltip title="Beschluss für Untergemeinschaften">
                            <GroupsIcon color="gray" />
                          </Tooltip>
                        : <GroupsIcon color="gray" sx={{opacity: "0"}}/>
                        }

                        

                        
                        {sub.model.success !== null 
                        ? sub.model.success 
                          ? <Tooltip title="Angenommen">
                              <StyledCheckContainer onClick={()=>console.log(sub)}>
                                <Check />
                              </StyledCheckContainer>
                            </Tooltip> 
                          : <Tooltip title="Abgelehnt">
                              <StyledCrossContainer onClick={()=>console.log(sub)}>
                                <Close />
                              </StyledCrossContainer>
                            </Tooltip> 
                        : null}
                        

                        
                      </ProcessingMeetingVoteDone>
                      <ProcessingMeetingVoteTitle onClick={() => handleSelectSubvote(sub, i)}>
                        {sub.title}
                      </ProcessingMeetingVoteTitle>
                      <ProcessingMeetingVoteResult>
                      </ProcessingMeetingVoteResult>

                      <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
                      }}>
                        <Tooltip title="Detailansicht Beschluss">
                          <IconButton onClick={() => navigate(`/vote#${sub.id}`)}>
                            <OpenInNewIcon />
                          </IconButton>
                        </Tooltip>
                      </div>

                    </ProcessingMeetingVote>
                  )}
                </ProcessingMeetingVotesContainer>
              </ProcessingMeetingVotes>
            </Column>

            <Column>
              <ProcessingMeetingProgress>
                
                {selectedSubvote !== null
                  ? <MyLineChart 
                      users={reduxMeeting.subvotes[selectedSubvote].users} 
                      results={reduxMeeting.subvotes[selectedSubvote].results} 
                      mea={reduxMeeting.subvotes[selectedSubvote].mea}
                    />
                  : reduxVote.id !== undefined 
                    ? <MyLineChart 
                        layout={reduxVote.users}
                        users={reduxVote.users}
                        results={reduxVote.results}
                        mea={reduxVote.mea}
                      />  
                    : <MyLineChart  
                      layout={reduxMeeting.users}
                      users={reduxMeeting.users}
                      results={[]}
                      mea={reduxMeeting.mea}
                      />
                }


                {/*reduxVote.id !== undefined 
                ? <MyLineChart 
                    layout={reduxVote.users}
                    users={reduxVote.users}
                    results={reduxVote.results}
                  />
                : <MyLineChart 
                    //layout={headvote.subvotes[selectedSubvote].seatlayout}
                    //results={headvote.subvotes[selectedSubvote].results.length > 0 ? headvote.subvotes[selectedSubvote].results[0].resultsets : null}
                  />
                */}
              </ProcessingMeetingProgress>
              <ProcessingMeetingLayout>
                <ProcessingMeetingLayoutHeader>
                  {/*reduxVote.id === undefined 
                  ? selectedSubvote !== null 
                    ? "subvote layout" 
                    : "headvote layout"
                  : null
                  */}
                </ProcessingMeetingLayoutHeader>
                <ProcessingMeetingLayoutContent>
                  {selectedSubvote !== null
                  ? <UserList users={reduxMeeting.subvotes[selectedSubvote].users} results={reduxMeeting.subvotes[selectedSubvote].results} />
                  : reduxVote.id !== undefined 
                    ? <UserList users={reduxVote.users} results={reduxVote.results} />  
                    : <UserList users={reduxMeeting.users} />
                  }
                </ProcessingMeetingLayoutContent>
              </ProcessingMeetingLayout>
            </Column>

          </ProcessingMeetingContent>
          
        </ProcessingMeetingRoot>
      </MyPageContent>
    </MyPage>
  );
}

export default RunMeeting;