import React from "react";
import MyImage from "../MyImage";
import styled from '@emotion/styled';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {
  Replay,
  Check,
  Close,
  VoiceOverOff,
  Mic,
  Smartphone
} from '@mui/icons-material';


import { 
  Zoom,
  Button,
  IconButton,
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    //maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '2px solid rgba(0, 0, 0, 1)',
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    //gap: "10px",
    paddingLeft: "0",
    paddingRight: "0",
    paddingTop: "0",
    paddingBottom: "0",
    borderRadius: "5px",  
    "& > .item":{
      padding: theme.spacing(1.5),
      display: "flex", 
      alignItems: "center", 
      gap: "10px", 
      cursor: "pointer",
      transition: "background-color 0.3s",
      backgroundColor: "#f5f5f9",
      "&.first":{
        borderRadius: "5% 5% 0 0",
        backgroundColor: "red",
      },
      "&.last":{
        borderRadius: "0% 0% 5% 5%",
      },
      "&:hover":{
        backgroundColor: "#e7e7f2",
      },
      "& .icon":{
        padding: "5px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&.pro":{
          backgroundColor: "rgba(0,200,0,0.4)",
          color: "green",
        },
        "&.contra":{
          backgroundColor: "rgba(200,0,0,0.4)",
          color: "red"
        },
      },
      "& .title": {
        fontSize: "1.1em",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
      "&.user":{
        borderRadius: "5px 5px 0px 0px",
        backgroundColor: "#ffcc00",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: "600"
      }
      },  
      "& .refresh":{
        backgroundColor: theme.palette.secondary.main,
      },
      "& .info":{
        //backgroundColor: "rgba(0,0,200,0.4)",
        "& > *":{
          color: "blue"
        },
      },
      "& .notInterested":{
        "& > *":{
          color: "rgba(255,0,0,0.7)"
        },
      },
      "& .interested":{
        "& > *":{
          color: "rgba(0,255,0,0.7)"
        },
      }
  },
}));

//#endregion

//#region tooltip titles
const toolTipTitles= {
  default: "Für mehr Informationen klicken",
  change: (
    user, 
    setVoteOnSeat = () => {}, 
    handleAway,
    handleAnnouncement
   ) => {
      
      return (
          <React.Fragment>
            <div className={"item first user"}>{user.owner.name}</div>
              <div className={"item"} onClick={() => setVoteOnSeat(1)}>
                <div className={"icon pro"}>
                  <Check />
                </div>
                <div className={"title"}>Stimme für Pro</div>
              </div>

              <div style={{width: "100%", height: "1px", backgroundColor: "rgba(0, 0, 0, 0.1)"}}></div>

              <div className={"item"} onClick={() => setVoteOnSeat(-1)}>
                <div className={"icon contra"}>
                  <Close />
                </div>
                <div className={"title"}>Stimme für Contra</div>
              </div>

              <div style={{width: "100%", height: "1px", backgroundColor: "rgba(0, 0, 0, 0.1)"}}></div>

              <div className={"item"} onClick={() => setVoteOnSeat(0)}>
                <div className={"icon"}>
                  <Replay />
                </div>
                <div className={"title"}>Stimme zurücksetzen</div>
              </div>

              <div style={{width: "100%", height: "1px", backgroundColor: "rgba(0, 0, 0, 0.1)"}}></div>
              
              
              <div className={"item"} onClick={() => handleAway(1)}>
                <div className={"icon"}>
                  <VoiceOverOff />
                </div>
                <div className={"title"}>an/aus Abwesenheit</div>
              </div>

              <div style={{width: "100%", height: "1px", backgroundColor: "rgba(0, 0, 0, 0.1)"}}></div>

              <div className={"item"} onClick={() => handleAnnouncement()}>
                <div className={"icon"}>
                  <Mic />
                </div>
                <div className={"title"}>an/aus Redewunsch</div>
              </div>

              <div style={{width: "100%", height: "1px", backgroundColor: "rgba(0, 0, 0, 0.1)"}}></div>

              <div className={"item last"}>
                <div className={"icon"}>
                  <Smartphone />
                </div>
                <div className={"title"}>
                  {user.device
                  ? <div style={{
                    display: "flex",
                    flexDirection: "column",
                    //gap: "7px"
                  }}>
                      {user.device.title}
                      <div style={{fontSize: "0.9em", opacity: "0.7"}}>{user.device.entityId}</div>
                    </div>
                  : "Kein Gerät zugewiesen"
                  }
                </div>
              </div>
              

              
          </React.Fragment>
      );
  } 
  
          
     
}
//#endregion

const SeatRoot = styled.div((props) => ({
  position: "relative",
  backgroundColor: "white",
  border: props.pro ? `3px solid ${props.theme.palette.success.main}` : props.contra ? `3px solid ${props.theme.palette.error.main}` : `3px solid rgba(0, 0, 0, 0.2)`,
  backgroundColor: props.pro ? `rgba(0, 255, 0, 0.3)` : props.contra ? `rgba(255, 0, 0, 0.3)` : `unset`,
  alignItems: "center",
  gap: "10px",
  display: "flex",
  overflow: "hidden",
  paddingLeft: "3px",
  paddingTop: "3px",
  paddingBottom: "3px",
  transition: "border 0.5s linear, background-color 0.5s",
  "& .options": {
    position: "absolute",
    top: "1rem",
    left: "0",
    right: "0",
    //height: "100%",
    //width: "100%",
    //backgroundColor: "red",
    opacity: "0",
    transition: "opacity 0.5s linear",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridmTemplateRows: "1fr 1fr 1fr",
    zIndex: "+1",
    "> div": {
      height: "100%",
      width: "100%",
      //border: "1px solid white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      "&:hover": {
        //backgroundColor: "blue",
      }
    }
  },
  "& .content": {
    transition: "opacity 0.5s linear",
  },
  "&:hover": {
    "& .content": {
      //opacity: "0",
    },
    "& .options": {
      opacity: "1",
    }
  }
}));

const SeatCheckIcon = styled(Check)((props) => ({
  opacity: props.show === 'true' ? "1" : "0",
  position: "absolute",
  right: "0",
  left: "0",
  bottom: "2rem",
  width: "100%",
  fontSize: "3rem",
  transition: "opacity 0.5s linear",
}));

const SeatCloseIcon = styled(Close)((props) => ({
  opacity: props.show === 'true' ? "1" : "0",
  position: "absolute",
  right: "0",
  left: "0",
  bottom: "2rem",
  width: "100%",
  fontSize: "3rem",
  transition: "opacity 0.5s linear",
}));




const Seat = (props) => {

  //#region props
  var {
    user,   //contains seat.user infos
    userProps={},
  } = props;
  //#endregion

  //#region hooks
  const classes = {}; //useStyles();
  const dispatch = useDispatch();
  
  //#endregion

  const setVoteOnSeat = (value) => {
    dispatch({type: "vote/manual", data: {
      seatnum: props.seatnum,
      value: value,
    }})
  }

  return(
    <SeatRoot pro={userProps.pro && !userProps.contra} contra={!userProps.pro && userProps.contra}>
        <div className="options">
          <div>
            <IconButton onClick={() => setVoteOnSeat(1)}>
              <Check color="success" />
            </IconButton>
          </div>
          <div>
            <IconButton onClick={() => setVoteOnSeat(-1)}>
              <Close color="error"/>
            </IconButton>
           
          </div>
          <div>
            <IconButton onClick={() => setVoteOnSeat(0)}>
              <Replay color="info"/>
            </IconButton>
          </div>

          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="content" style={{
          display: "flex", 
          gap: "10px", 
          height: "100%", 
          width: "100%", 
          alignItems: "center", 
          //justifyContent: "center"
        }}>
          {/* user */}
          <div style={{maxWidth: "15%", height: "100%", display: "flex", position: "relative"}}>
          </div>
          <div className={"user"}>
            <div className={"value"} style={{textAlign: "center", display: "flex", alignItems: "center", gap: "0.5rem"}}>
              {user.deviceId !== null
              ? <Smartphone color={user.deviceId !== null ? "" : "error"}/>
              : null
              }
                
              {user.owner !== undefined 
              ? user.owner.name
              : "k.A." 
              }
            </div>
          </div>
          {/* user end */}

          {/* vote */}
          {/*<div className={"icons"}>*/}
          <SeatCheckIcon
            show={userProps.pro ? 'true' : 'false'}
          />
          <SeatCloseIcon
            show={userProps.contra ? 'true' : 'false'}
          />
          
          {/*</div> */}

          {/* away */} 
          {userProps.away
          ? <div className={`${userProps.away ? classes.away : null}`}>
              <VoiceOverOff className={`${classes.icon} icon ${userProps.away ? classes.fadeIn : classes.fadeOut}`}/>
            </div>
          : null
          }

        </div>
    </SeatRoot>
  );

}


export default Seat;