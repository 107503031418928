import React from 'react';
import styled from '@emotion/styled/macro';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import texts from '../../texts';

const ObjectListItemRoot = styled.div((props) => ({
  display: "flex",
  borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
  gap: "1rem",
  padding: "1rem 1rem",
  position: "relative",
  transition: "background-color 0.2s",
}));

const StyledPersonIcon = styled(PersonOutlineOutlinedIcon)((props) => ({
  fontSize: "3rem",
  opacity: "0.5",
}))

const DateInfo = styled.div((props) => ({
  position: "absolute",
  top: "0",
  right: "0.5rem",
  fontSize: "0.75rem",
  gap: "0.5rem",
}))

const InfoElement = styled.div((props) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& div:first-of-type": {
    fontSize: "1.25rem",
  },
  "& div:last-of-type": {
    opacity: "0.5",
  }
}))

const OwnerListItem = (props) => {

  const {
    element
  } = props;

  return(
    <ObjectListItemRoot>
      <StyledPersonIcon outdated={element.outdated.toString()} />
      <DateInfo>
        <div>{new Date(element.createdAt).toLocaleTimeString()}</div>
        <div>{new Date(element.createdAt).toLocaleDateString()}</div>
      </DateInfo>
      <InfoElement>
        <div>{element.name}</div>
        <div>Name</div>
      </InfoElement>
      <InfoElement>
        <div>{element.subobjects.length}</div>
        <div>{texts.subobjects.plu}</div>
      </InfoElement>
    </ObjectListItemRoot>
  );

}

export default OwnerListItem;