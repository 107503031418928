import React, { useRef } from 'react';
import { 
  Fade,
  Tooltip,
  InputBase,
  IconButton
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';

const HeaderRoot = styled.div({
  borderBottom: "1px solid rgba(0,0,0,1)",
  padding: "0.5em",
  
});

const HeaderSearch = styled.div({
  display: "flex",
  gap: "10px",
});

const HeaderAddButtonContainer = styled.div({
  marginRight: "0px",
  marginLeft: "auto",
})

const HeaderDefault = (props) => {

	const {
		handleSearchChanged,
		addButton,
    itemCount
	} = props;

  const [searchstring, setSearchString] = React.useState("");

  const stringChanged = (event) => {
    setSearchString(event.target.value);
    handleSearchChanged(event.target.value)
  }

  const handleClearSearch = () => {
    setSearchString("");
    searchInput.current.value="";
    handleSearchChanged("");
  }

	const classes = {};
	const searchInput = useRef(null);

  return(
    <HeaderRoot className={classes.header}>
      <div style={{
        display: "flex",
        alignItems: "center",
        width: "calc(100% - 1em)",
      }}>
        <HeaderSearch>
          <div className="icon">
            <IconButton size="small"  onClick={() => {searchInput.current.focus()}} >
              <Tooltip title="Suchen" enterDelay={750} enterNextDelay={750}>
                <SearchIcon/>
              </Tooltip>
            </IconButton>
          </div>
          <InputBase
            inputRef={searchInput}
            className="input"
            placeholder="Suche.."
            inputProps={{ 'aria-label': 'search' }}
            onChange={stringChanged}
          />
          <Fade in={searchstring !== ""}>
              <IconButton size="small" onClick={handleClearSearch}>
                <CloseIcon />
              </IconButton>
          </Fade>

        </HeaderSearch>
      
        {/* Add Button: Neues Element hinzufügen */}
        <HeaderAddButtonContainer>
          {addButton !== undefined
            ? addButton
            : null
          }
        </HeaderAddButtonContainer>
      </div>
      <div style={{
        fontSize: "0.7rem",
        textAlign: "right",
        padding: "0.25rem 1rem 0rem 1rem",
      }}>
        Anzahl: {itemCount}
      </div>
    </HeaderRoot>
  );

}

export default HeaderDefault;