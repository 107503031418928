import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { createTheme, useTheme } from '@mui/material';

const useMandantTheme = () => {
 
  const authUser =  useSelector((state) => state.auth); 
  const theme = useTheme();

  if(authUser.mandant){
    return createTheme({
      ...theme,
      palette: {
        ...theme.palette,
        primary: {
          main: authUser.mandant.primaryColor,
        },
        secondary: {
          main: authUser.mandant.secondaryColor,
        },
      },
    })
  }

  return createTheme({});

};

export default useMandantTheme;



/*
  Mit diesem Theme können die Farben der Elemente innerhalb der App verändert werden.
  Es werden folgende Parameter verwendet:
  primary: die Hauptfarbe der App
  secondary: die sekundäre Farbe
  text dark: Farbe für Texte, die dunkel sidn
  text light: Farbe für Text, die hell sind
*/


