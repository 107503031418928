var objects = {};

objects = {
  sing: "Objekt",
  plu: "Objekte",
  h_plu: "Übersicht der Objekte",
  h_sing: "Objekt - Detailansicht",
  add: "Neues Objekt",
}

export default objects;