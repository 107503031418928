import styled from "@emotion/styled";
import { 
  Fade, 
  Slide, 
  ThemeProvider, 
  useTheme 
} from "@mui/material";
import React, { useEffect } from "react";


const SecondarySidebarRoot = styled.div((props) => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  top: "0",
  right: "0",
  zIndex: props.show ? "9" :"-1",
}));

const SecondarySidebarBackdrop = styled.div({
  zIndex: "9",
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  width: "100%",
  height: "100%",
});


const SecondarySidebarContent = styled.div((props) => ({
  backgroundColor: "white",//props.theme.palette.secondary.main,
  width: "30%",
  maxWidth: "300px",
  height: "calc(100% - 2vh)",
  position: "absolute",
  right: "0",
  top: "0",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "1vh",
}));

const SecondarySidebar  = (props) => {

  const {
    open=false,
    onClose,
    content,
  } = props;

  const [show, setShow] = React.useState(true);
  const [hide, setHide] = React.useState(true);

  const theme = useTheme();

  useEffect(()=>{
    setHide(!open);
    setShow(open);
  },[open])

  const handleClose = (e) => {
    setShow(false);
    setTimeout(()=>{
      setHide(true);
      onClose();
    }, 150);
  }

  return(
    <ThemeProvider theme={theme}>
      <SecondarySidebarRoot show={!hide}>
        <Fade in={show}>
          <SecondarySidebarBackdrop onClick={handleClose}/>
        </Fade>
        <Slide in={show} direction="left">
          <SecondarySidebarContent>
            {content
            ? React.cloneElement(content, {handleClose: handleClose})
            : null
            }
          </SecondarySidebarContent>
        </Slide>
      </SecondarySidebarRoot>
    </ThemeProvider>
  );
}

export default SecondarySidebar;