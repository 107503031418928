import { ThemeProvider, useTheme } from "@emotion/react";
import styled from "@emotion/styled";

const GridTableHeadRoot = styled.thead((props) =>  ({
  
}));

const GridTableHead = (props) => {
  
  const { 
    children=[]
  } = props;

  const theme = useTheme();

  return(
    <ThemeProvider theme={theme}>
      <GridTableHeadRoot>
        {children}
      </GridTableHeadRoot>
    </ThemeProvider>
  );
}

export default GridTableHead;