import React, { useEffect, useRef } from "react";
import { 
  Checkbox, 
  FormControlLabel, 
  Grow, 
  IconButton, 
  InputLabel, 
  MenuItem, 
  TextField 
} from "@mui/material";
import MyButton from "../../layout-components/MyButton/MyButton";
import MyList from "../../layout-components/MyList";
import { ThemeProvider, useTheme } from '@mui/material';
import { CallMissedSharp, Check, MoreHoriz, SmartphoneOutlined } from "@mui/icons-material";
import SecondarySidebar from "../../layout-components/SecondarySidebar";
import mandantService from "../../services/mandant.service";
import { useLocation, useNavigate } from "react-router-dom";
import typeService from "../../services/type.service";
import styled from "@emotion/styled";
import ownerService from "../../services/owner.service";
import GridTable from "../../layout-components/GridTable";
import GridTableHead from "../../layout-components/GridTable/GridTableHead";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import objectService from "../../services/object.service";
import deviceService from "../../services/device.service";

import SmartphoneIcon from '@mui/icons-material/Smartphone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const AddObjectRoot = styled.div((props) => ({
  width: "calc(100% - 1em - 2em)",
  height: "calc(100% - 1em - 2em)",
  margin: "0.5em",
  padding: "1em",
  borderRadius: "0.5em",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  position: "relative",
}));

const AddObjectHeader = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: props.theme.palette.primary.main,
  background: "white",
  background: `linear-gradient(90deg, ${props.theme.palette.primary.light} 0%, ${props.theme.palette.secondary.main} 75%)`,
  padding: "1rem",
  borderRadius: "0.5rem",
}));

const AddObjectHeaderButtons = styled.div((props) => ({
  display: "flex",
  gap: "10px",
  marginLeft: "auto",
  marginRight: "0px",
  minWidth: "500px"
}));

const AddObjectContent = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  height: "100%",
  border: "1px solid red",
}));

const AddObjectInfoContainer = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  backgroundColor: "white",
  padding: "1rem",
  borderRadius: "0.5rem",
}));

const AddObjectInfoInputs = styled.div((props) => ({
  display: "flex",
  gap: "2rem",
  padding: "1rem",
})); 

const AddObjectInfoNumbers = styled.div((props) => ({
  display: "flex",
  gap: "1rem",
  padding: "1rem",
}));

const AddObjectNumberLabel  = styled.div((props) => ({
  width: "calc(100% - 2rem)",
  height: "calc(100% - 2rem)",
  borderRadius: "0.5rem",
  padding: "1rem",
  backgroundColor: props.theme.palette.secondary[ props.color ? props.color : "main"],
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "0.5rem",
  "& :first-of-type": {
    fontSize: "1.5rem",
  }
}));

const AddObjectSubObjectsContainer = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  backgroundColor: "white",
  padding: "1rem",
  borderRadius: "0.5rem",
}));

const AddObjectSubObjectsHeader = styled.div((props) => ({
  display: "flex",
  alignItems: "flex-end",
  "& :last-child": {
    marginLeft: "auto",
    marginRight: "0px",
  }
}));

const AddObjectIconButton = styled.div((props) => ({
  cursor: "pointer",
  borderRadius: "50%",
  
  //backgroundColor: "blue",
  border: "1px solid rgba(0, 0, 0, 0.2)",
  padding: "3px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  transition: "background-color 0.2s",
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  }
}));

const AddObjectsNoSubObjects = styled.div((props) => ({
  textAlign: "center",
  opacity: "0.7",
  padding: "1rem",
}));

const AddObjectAddSubObjectButtons = styled.div((props) => ({
  display: "flex",
  gap: "1rem",
}));

const AddObjectAddSubObjectContainer = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  border: `2px solid ${props.theme.palette.secondary.main}`,
  padding: "0.5rem",
}));

const AddObjectAddSubObjectInputs = styled.div((props) => ({
  display: "flex",
  gap: "1rem",
  alignItems: "flex-end",
}))

const AddObjectsBreakLine = styled.div((props) => ({
  height: "1px",
  backgroundColor: "rgba(0, 0, 0, 0.2)",
}));

const DeviceMenuItem = styled.div((props) => ({
  width: "calc(100% - 2rem)",
  //backgroundColor: "red",
  padding: "1rem",
  display: "flex",
  gap: "1rem",
  alignItems: "center",
  borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
  
  transition: "background-color 0.25s",
  backgroundColor: props.used ? props.theme.palette.background.secondary : "unset",
  "&:hover": props.hover 
    ? {
      backgroundColor: props.used ? props.theme.palette.background.primary : props.theme.palette.background.secondary,
      cursor: "pointer",
    } 
    : {

    }
}));

const DeviceSelect = (props) => {

  const {
    devices=[],
    ownerDevices=[],
    owner,
    callback
  } = props;

  const [elements, setElements] = React.useState([]);
  const [showUsed, setShowUsed] = React.useState(true);
  const [showOnlyOwn, setShowOnlyOwn] = React.useState(false);

  useEffect(() => {
    setElements(devices);
  }, [devices, ownerDevices]);

  const isInOwnerDevices = (device) => {
    for(let i = 0; i<ownerDevices.length; i++){
      if(ownerDevices[i].id === device.id){
        return true;
      }
    }
    return false;
  }

  const handleDeviceCallback = (device, index) => {
    callback(device, index);
  }
  return(
    <div style={{
      width: "100%",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      height: "100%",
    }}>
      Abstimmgerät bearbeiten
      <div style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}>
        {elements.sort((a, b) => {
          if(a.title > b.title) return 1;
          if(a.title < b.title) return -1;
          return 0;
        }).map((device, index) => {

          var used = isInOwnerDevices(device);

          if(showOnlyOwn && device.ownerId !== owner.id) return null;
          if(used && !showUsed) return null; 

          return(
            <DeviceMenuItem key={index} used={used} onClick={() => handleDeviceCallback(device, index)} hover={true}>
              <SmartphoneIcon />
              <div>
                <div>{device.title}</div>
                {device.owner 
                ? <div style={{fontSize: "0.7rem", fontStyle: "italic"}}>{device.owner.name}</div>
                : null
                }
              </div>
              
              {used
              ? <CheckCircleIcon style={{marginRight: "0", marginLeft: "auto"}} color="success" />
              : null
              }
            </DeviceMenuItem>
          );
        })}
      </div>
      <div style={{
        border: "1px solid rgba(255, 255, 255, 0.25)",
        padding: "2rem 1rem",
      }}>
        <FormControlLabel control={<Checkbox checked={showOnlyOwn} onChange={() => setShowOnlyOwn(!showOnlyOwn)} color="secondary" />} label="Zeige nur eigene Geräte" />
        <FormControlLabel control={<Checkbox checked={showUsed} onChange={() => setShowUsed(!showUsed)} color="secondary" />} label="Zeige vergebene Geräte" />
      </div>
    </div>
  );
}

const OwnerAdd = () => { 

  const [subObjects, setSubObjects] = React.useState([]);   //subobject from objects
  const [addNew, setAddNew] = React.useState(false);        //if true show form to add new subobject
  const [secondarySidebar, setSecondarySidebar] = React.useState(false);  //show or hide secondarysidebar
  const [sidebarContent, setSidebarContent] = React.useState(null);       //hold content for secondarysidebar
  const [nameInputError, setNameInputError] = React.useState(false);      //true or false based on validation of input
  const [numberInputError, setNumberInputError] = React.useState(false);  //true or false based on validation of input
  const [types, setTypes] = React.useState([]);   //all typed - can be selected when adding new subobject
  const [owners, setOwners] = React.useState([]); //all owerns - can be selected when adding new subobject
  const [hoverTableRowIndex, setHoverTableRowIndex] = React.useState(-1); //hold index of hovered row in subobjects table
  const [isEdit, setIsEdit] = React.useState(false);  //defines if this component adds a new object or is editing an existing 
  const [editElement, setEditElement] = React.useState(null); //holds the subobject thats gets edited
  const [editSub, setEditSub] = React.useState(false);  //defines if a new subobject gets added or an existing is editing
  const [owner, setOwner] = React.useState({
    name: "",
    number: "",
    deviceId: "",
    device: null,
  });
  const [devices, setDevices] = React.useState([]);
  

  const nameRef = useRef();
  const numberRef = useRef();

  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    init();
  }, [])

  useEffect(()=>{
    if(location.hash !== ""){
      setIsEdit(true);
      fetchElement(location.hash.slice(1));
    }
  }, 
  [location.hash])

  const init = () => {
    //alert("Lade Typen und Eigentümer")
    deviceService.getAll()
      .then((response) => {
        setDevices(response.data);
      })

  }

  const fetchElement = (id) => {
    
  }

  const handleSave = () => {

    var nameValue = nameRef.current.value;
    var numberValue = numberRef.current.value;

    if(validateForm()){
      ownerService.create(owner)
        .then((response) => {
        })
        .catche((e) => console.log(e));
    }
  }

  const handleCancel = () => {
    navigate('/');
  }

  const validateForm = () => {

    if(owner.name === ""){
      setNameInputError(true);
    }

    if(owner.number === ""){
      setNumberInputError(true);
    }

    if(owner.name !== "" && owner.number !== null){
      return true;
    }

    return false;

  }


  const editDevicesCallback = async(device, index) => {


    var _owner ={...owner};
    _owner.device = device;
    _owner.deviceId = device.id;
    setOwner(_owner);
    setSecondarySidebar(false);
    return;

  }

  const handleSecondarySidebarClosed = () => {
    setSecondarySidebar(false);
  }



  const handleSecondarySidebar = (type) => {
    switch(type){
      case "devices":
        setSidebarContent(
          <DeviceSelect 
            devices={devices} 
            ownerDevices={owner.devices} 
            owner={owner} 
            callback={editDevicesCallback}
          />
        );
        break;

      default:
        setSidebarContent(null);
        break;
    }

    setSecondarySidebar(true);

  }

  const handleUpdateObject = (e, attr) => {
    var _owner = {...owner};
    _owner[attr] = e.target.value;
    setOwner(_owner);
  }

  return(
    <ThemeProvider theme={theme}>
      <SecondarySidebar />
      <AddObjectRoot>
        <SecondarySidebar 
          open={secondarySidebar} 
          onClose={handleSecondarySidebarClosed}
          content={sidebarContent}
        />
        <AddObjectHeader>
          <div>
          <h3>
            {!isEdit
            ? "Neuen Anteilseigner anlegen"
            : <div>
                <div>Anteileigner bearbeiten</div>
                <div>{owner.name}</div>
              </div>
            }
          </h3>
          
          </div>
          <AddObjectHeaderButtons>
            <MyButton text="Abbrechen" color={"secondary"} onClick={handleCancel} />
            <MyButton text="Speichern" color="primary" onClick={handleSave} />
          </AddObjectHeaderButtons>
        </AddObjectHeader>

      <AddObjectContent>
        <AddObjectInfoContainer>
          <div>Grundlegende Informationen</div>
          <AddObjectInfoInputs>
            <TextField 
              label="Name" 
              fullWidth 
              inputRef={nameRef} 
              required
              error={ nameInputError }
              value={owner.name}
              onChange={(e) => handleUpdateObject(e, "name")}
            />
            <TextField 
              label="Number" 
              fullWidth 
              inputRef={numberRef} 
              error={numberInputError}
              value={owner.number}
              required
              onChange={(e) => handleUpdateObject(e, "number")}
            />
          </AddObjectInfoInputs>

          <div style={{
            display: "grid",
            gridTemplateRows: "1fr 1fr",
            gridTemplateColumns: "1fr 1fr",
            //border: "1px solid red",
            gridTemplateAreas: `
              "device objectcount"
              "device objectsum"
            `,
            padding: "1rem",
            gap: "1rem 1rem",
            width: "calc(100% - 2rem)",
            //aspectRatio: "2 / 0.3",
          }}>
            <div style={{
              gridArea: "device",
              border: "1px solid black",
              borderRadius: "0.5rem",
              padding: "1rem",
              display: "flex",
              flexDirection: "column",
            }}>
              <div style={{
                fontSize: "0.8rem",
                display: "flex",
                justifyContent: "space-between",
              }}>
                Abstimmgerät
                
                <EditIcon style={{
                  cursor: "pointer",
                  opacity: "0.5",
                  fontSize: "1.2rem",
                }}
                onClick={() => handleSecondarySidebar("devices")}
                
                />
                
                
              </div>
              <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
                height: "100%",
                //border: "1px solid blue",
              }}>
                {!owner.deviceId
                ? <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem",
                  }}>
                    
                    <div style={{
                      opacity: "0.7",
                    }}>
                      Kein Abstimmgerät hinterlegt
                    </div>
                      
                    
                    
                    <div>
                      <MyButton text="Hinzufügen" onClick={() => handleSecondarySidebar("devices")} />
                    </div>
                  </div>
                : <div style={{
                  display: "flex",
                  //border: "1px solid red",
                  gap: "1rem",
                }}>
                    <SmartphoneIcon sx={{width: 48, height: 48}}/>
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}>
                      <div style={{
                        fontSize: "1.15rem",
                      }}>{owner.device.title}</div>
                      <div style={{
                        opacity: "0.7",
                        fontStyle: "italic",
                        fontSize: "0.8rem",
                      }}>{owner.device.entityId}</div>
                    </div>
                    
                  </div>
                }
              </div>
            </div>
            <div style={{
              gridArea: "objectcount",
            }}>
              <AddObjectNumberLabel color="dark">
                <div>5</div>
                <div>Teilobjekte in Besitz</div>
              </AddObjectNumberLabel>
            </div>

            <div style={{
              gridArea: "objectsum",
            }}>
              <AddObjectNumberLabel color="light">
              <div></div>
              <div>Summe MEA</div>
            </AddObjectNumberLabel>

            </div>
          </div>

        </AddObjectInfoContainer>
      
        <AddObjectSubObjectsContainer>
          <AddObjectSubObjectsHeader>
            <div>Teilobjekte</div>
            <div></div>
          </AddObjectSubObjectsHeader>
          <AddObjectsBreakLine />
          
          {!owner.subobjects
          ? <AddObjectsNoSubObjects>
              Keine Teilobjekte definiert!
            </AddObjectsNoSubObjects>
          : <GridTable>
              <GridTableHead>
                <tr>
                  <th>Name</th>
                  <th>Nummer</th>
                  <th>Typ</th>
                  <th>Eigentümer</th>
                  <th>MEA</th>
                  <th></th>
                </tr>
              </GridTableHead>
              <tbody>
                {owner.subobjects.map((subobject, index) => 
                  <tr key={index} onMouseEnter={(e) => setHoverTableRowIndex(index)} onMouseLeave={(e) => setHoverTableRowIndex(-1)}>
                    <td>{subobject.name}</td>
                    <td>{subobject.number}</td>
                    <td>{subobject.type.title}</td>
                    <td>{subobject.owner.name}</td>
                    <td>{subobject.mea}</td>
                    <td>
                      <div style={{opacity: true || hoverTableRowIndex === index ? "1" :" 0", transition: "opacity 0.2s", display: "flex", justifyContent: "flex-end", gap: "10px"}}>
                        <AddObjectIconButton onClick={(e) => {}}>
                          <EditIcon color="info" />
                        </AddObjectIconButton>
                        <AddObjectIconButton>
                          <DeleteOutlineIcon color="error"/>
                        </AddObjectIconButton>  
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </GridTable>
          }

          <AddObjectsBreakLine />

          <Grow in={addNew} unmountOnExit>
            <AddObjectAddSubObjectContainer>
              <AddObjectAddSubObjectInputs>
                <TextField label="Name" 
                  fullWidth 

                  required

                  onChange= {(e) => {}}
                />
                <TextField 
                  label="Typ" 
                  select 
                  fullWidth 
                  defaultValue={"-"} 

                  required

                >
                  <MenuItem value="-">-</MenuItem>
                  {types.map((type, index) =>
                    <MenuItem key={index} value={type.id}>{type.title}</MenuItem>
                  )}
                </TextField>
                <TextField 
                  label="Eigentümer" 
                  select 
                  fullWidth 
                  //defaultValue={"-"}

                  required


                >
                  <MenuItem value="-">-</MenuItem>
                  {owners.map((owner, index) =>
                    <MenuItem key={index} value={owner.id}>{owner.name}</MenuItem>
                  )}
                </TextField>
                <TextField 
                  label="MEA" 
                  fullWidth 

                  required


                />
                <TextField 
                  label="Nummer" 
                  fullWidth 

                  required 


                />
              </AddObjectAddSubObjectInputs>
              <AddObjectAddSubObjectButtons >
                <MyButton color="secondary" text="Abbrechen" />
                <MyButton color="secondary" text="Neuer Objekttyp" onClick={()=>handleSecondarySidebar("type")}/>
                <MyButton color="secondary" text="Neuer Eigentümer" onClick={()=>handleSecondarySidebar("owner")}/>
                <MyButton color="primary" text="Speichern" />
              </AddObjectAddSubObjectButtons>
            </AddObjectAddSubObjectContainer>
            
          </Grow>

          
        </AddObjectSubObjectsContainer>
      </AddObjectContent>
      
      
    </AddObjectRoot>
    </ThemeProvider>
  );
}

export default OwnerAdd;