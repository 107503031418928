import React, {useRef} from "react";
import voteService from "../../services/vote.service";
import styled from "@emotion/styled/macro";
import texts from "../../texts";
import { TextField, FormControlLabel, Checkbox, Button } from "@mui/material";
import MyButton from "../../layout-components/MyButton/MyButton";

const AddVoteRoot = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",
  height: "100%",
  width: "100%",
  overflow: "hidden",
}))

const AddVoteContent = styled.div((props) =>   ({
  display: "flex",
  flexDirection: "column",
  gap: "1vh",
  height: "100%",
  width: "100%",
  overflow: "hidden",
}))

const AddVoteForm = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  alignItems: "center",
  overflow: "auto",
  paddingTop: "10px",
}))

const AddVoteButtons = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  flexShrink: "0",
}))

const StyledH3 = styled.h3((props) => ({
  marginTop: "1vh",
  marginBottom: "1vh",
}))

const AddVote = (props) => {

  const {
    headvote,
    setHeadvote,
    handleClose=()=>{},
    communities
  } = props;

  const [titleError, setTitleError] = React.useState(false);
  const [restrictedVote, setRestrictedVote] = React.useState(false);
  const [checkedCommunities, setCheckedCommunites] = React.useState([]);
  const [qualified, setQualified] = React.useState(false);

  const titleRef = useRef();
  const descRef = useRef();
  const checkRef = useRef();

  const handleSave = async() => {

    const titleValue = titleRef.current.value;
    const descValue = descRef.current.value;
    
    if(titleValue === ""){
      setTitleError(true);
      return;
    }

    try{
      var vote = {
        title: titleValue,
        description: descValue,
        headvote: headvote.id,
        communities: checkedCommunities,
        qualified: qualified,
      };

      console.log("new vote", vote);

      voteService.create(vote, headvote.objectId)
        .then((response) => {
          var _headvote={...headvote};
          _headvote.subvotes.push(response.data);
          setHeadvote(_headvote);
          closeSidebar();
        })
        .catch((e) => {
          console.log(e);
        })
      
    } catch(e){
      console.log(e);
    }

  }

  const handleTitleChanged = () => {
    setTitleError(false);
  }

  const handleKeyDown = (e) => {
    if(e.keyCode === 13) { 
      handleSave();
    }
  }

  const closeSidebar = () => {
    titleRef.current.value="";
    descRef.current.value="";
    checkRef.current.checked = false;
    setRestrictedVote(false);
    setCheckedCommunites([]);
    handleClose();
  }

  const handleRestrictedVotChanged = (e) => {
    setRestrictedVote(e.target.checked);
  }

  const handleCommunityCheckbox = (id) => {
    var _checkedCommunities = [...checkedCommunities];
    var communityIndex = _checkedCommunities.findIndex(x => x === id);
    if(communityIndex === -1){
      //community nicht gefunden
      //add community
       _checkedCommunities.push(id);
    } else {
      //remove community
      _checkedCommunities.splice(communityIndex, 1);
    }
    setCheckedCommunites(_checkedCommunities);
  }

  return(
    <AddVoteRoot>
      
      <AddVoteContent>
        <StyledH3>{texts.votes.add}</StyledH3>
        <AddVoteForm>

          <TextField label={texts.votes.fields.title} 
            fullWidth 
            required 
            inputRef={titleRef}
            error={titleError}
            onChange={handleTitleChanged}
            onKeyDown={handleKeyDown}
          />

          <TextField 
            label={texts.votes.fields.description} 
            multiline 
            minRows={3} 
            fullWidth 
            inputRef={descRef}
          />

          <FormControlLabel 
            label="qualifizierter Beschluss" 
            sx={{
              width: "100%",
              margin: "0",
            }}
            control={
              <Checkbox 
                inputRef={checkRef}
                checked={qualified}
                onChange={(e) => setQualified(e.target.checked)}
              />
            }
          />

          <FormControlLabel 
            label="nur Untergemeinschaften" 
            sx={{
              width: "100%",
              margin: "0",
            }}
            control={
              <Checkbox 
                onChange={handleRestrictedVotChanged}
                checked={restrictedVote}
              />
            }
          />
        </AddVoteForm>

        {/* untergemeinschaften */}
        {restrictedVote
        ? <div style={{
            //border: "1px solid red",
            height: "100%",
            overflow: "auto",
          }}>
            {communities.map((community, i) => 
              <FormControlLabel 
              key={i}
              label={community.title} 
              sx={{
                width: "calc(100% - 1rem)",
                margin: "0",
                paddingLeft: "1rem",
                "& span": {
                  padding: "2px",
                  
                }
              }}
              control={
                <Checkbox
                  onChange={() => handleCommunityCheckbox(community.id)}
                  checked={checkedCommunities.findIndex(x => x === community.id) > -1}
                />
              }
            />
           )}
          </div>
        : null
        }

        <AddVoteButtons>
          <Button variant="contained" onClick={handleSave} color="primary">Speichern</Button>
          <Button variant="outlined" color="error" onClick={closeSidebar}>Abbrechen</Button>
        </AddVoteButtons>
      </AddVoteContent>
    </AddVoteRoot>
  );
}

export default AddVote;