import React, {useRef} from "react";
import voteService from "../../services/vote.service";
import styled from "@emotion/styled/macro";
import texts from "../../texts";
import { TextField, FormControlLabel, Checkbox, Button } from "@mui/material";
import MyButton from "../../layout-components/MyButton/MyButton";

const AddVoteRoot = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",
  height: "100%",
  width: "100%",
}))

const AddVoteContent = styled.div((props) =>   ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  height: "100%",
  width: "100%",
}))

const AddVoteForm = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  alignItems: "center",
}))

const AddVoteButtons = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  flexShrink: "0",
}))

const RemoveVote = (props) => {
  const {
    headvote,
    setHeadvote,
    handleClose=()=>{},
    setRemoveVote,
    vote
  } = props;

  const handleRemove = async() => {
    
    try{
      voteService.removeVote(vote)
        .then((response) => {
          voteService.getOne(headvote.id)
            .then((res) => {
              setHeadvote(res.data);
              closeSidebar();
            })
            .catch((e) => console.log(e));
        })
        .catch((e) => {
          console.log(e);
        })
      
    } catch(e){
      console.log(e);
    }

  }

  const closeSidebar = () => {
    setRemoveVote(null);
  }


  if(vote === undefined || vote === null) return null;

  return(
    <AddVoteRoot>
      <h3>{texts.votes.sing} löschen</h3>
      <AddVoteContent>
        <div style={{textAlign: "center", display: "flex", flexDirection: "column", gap: "1rem", marginTop: "1.5rem"}}>
          <div>Soll</div>
          <div style={{fontWeight: "600", fontSize: "1.1rem"}}>{vote.title}</div>
          <div>wirklich gelöscht werden?</div>
        </div>
        <AddVoteButtons>
          <Button variant="contained" onClick={handleRemove} color="primary">Löschen</Button>
          <Button  onClick={closeSidebar} color="error" variant="outlined">Abbrechen</Button>
        </AddVoteButtons>
      </AddVoteContent>
    </AddVoteRoot>
  );
}

export default RemoveVote;