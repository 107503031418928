import { clearSocket, setConnected, setSocket } from "./slices/socket.slice";
import { clearVote, setVote } from "./slices/voteSlice";
import { clearAuth } from "./slices/authSlice";
import { MessageHandler } from "../utils/MessageHandler";
import { VoteHandler } from "../utils/VoteHandler";
import { addAlert } from "./slices/alertsSlice";
import { setMeeting } from "./slices/meetingSlice";

export const socketMiddleware = (socket) => store => (next) => (action) => {

  const { dispatch, getState } = store
  const { type, data } = action

  
  const messageHandler = new MessageHandler(socket, dispatch, getState);
  const voteHandler = new VoteHandler(dispatch, getState);

  voteHandler.attachMessageHandler(messageHandler);
  messageHandler.attachVoteHandler(voteHandler);

  switch(type) {
    case 'socket/connect':

      if(!getState().connected){
        socket.connect(process.env.REACT_APP_WS_SOCKET_URL);
      }

      socket.on('open', () => {
        dispatch(setConnected(true));
      })

      socket.on('message', (message) => {
        messageHandler.handle(message);
      })

      socket.on('close', () => {
        dispatch(clearSocket());
        dispatch(setMeeting(null));
        dispatch(setVote(null));
      })

      socket.on('error', (e) => {
        dispatch(clearSocket());
        dispatch(setMeeting(null));
        dispatch(setVote(null));
        dispatch(addAlert({
          type: "error",
          msg: "WebSocket-Verbindung unterbrochen. Versuchen Sie sich erneut zu verbinden!",
        }));
      });

      break;

    case 'socket/disconnect':
      socket.disconnect();
      dispatch(clearAuth());
      dispatch(clearSocket());
      dispatch(clearVote());
      break

    case 'socket/reconnect':
      socket.disconnect();
      dispatch(setConnected(false));
      dispatch({type: 'socket/connect'});
      break;

    case 'meeting/init':
      voteHandler.initMeeting(data);  
      break;

    case 'meeting/start':
      voteHandler.startMeeting(data);
      break;

    case 'meeting/stop':
      voteHandler.stopMeeting(data);
      break;

    case 'meeting/end':
      voteHandler.endMeeting(data);
      break;
    
    case 'meeting/next':
      voteHandler.nextVote(data);
      break;

    case 'meeting/reopen':
      break;

    case 'vote/start':
      voteHandler.startVote(data);
      break;

    case 'vote/manual':
      voteHandler.manualVote(data);
      break;

    case 'vote/stop':
      voteHandler.stopVote(data);
      break;

    case 'vote/end':
      voteHandler.endVote(data);
      break;

    case 'vote/reopen':
      voteHandler.reopenVote(data);
      break;

    case 'user/toogle':
      voteHandler.toogleAwayUser(data);
      break;

    default:
      break
  }


  return next(action)

}