import styled from "@emotion/styled/macro";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

const StyledCheckContainer = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "1px",
  backgroundColor: "white",
  border:  "4px solid orange",
  color: "white",
  borderRadius: "100%",
  height: "18px",
  width: "18px",
  minWidth: "18px",
  minHeight: "18px",
}));

const Dot = styled.div((props) => ({
  backgroundColor: "orange",
  height: "12px",
  width: "12px",
  borderRadius: "100%",
}))

const NoneVoteIcon = () => {
  return(
    <StyledCheckContainer>
      <Dot />
    </StyledCheckContainer>
  );
}

export default NoneVoteIcon;