import React, { useEffect } from "react";
import MyList from "../../layout-components/MyList/index";
import OwnerListItem from "./OwnerListItem";
import ownerService from "../../services/owner.service";
import MyPage from "../../layout-components/MyPage";
import MyPageContent from "../../layout-components/MyPage/MyPageContent";
import MyPageTitle from "../../layout-components/MyPage/MyPageTitle";
import texts from "../../texts";

const OwnerList = () => {
  
  const [owners, setOwners] = React.useState([]);

  useEffect(() => {
    init();
  }, []);

  const init = async() => {
    ownerService.getAll()
    .then((response) => {
      setOwners(response.data);
    })
    .catch((error) => {
      console.log(error);
    })
  }

  return(
    <MyPage>
      <MyPageTitle>
      {texts.owners.h_plu}
      </MyPageTitle>
      <MyPageContent style={{display: "flex", flexDirection: "column",}}>
        <MyList 
          component={<OwnerListItem />} 
          elements={owners}
          searchProps={"name"}
          addButton={
          <div>
           {/*<MyButton text="Neu" startIcon={<AddCircleOutlineOutlinedIcon color="secondary" />} onClick={() => navigate('/owner/add')} />*/}
          </div>
          }
        />
      </MyPageContent>
    </MyPage>
  );

}

export default OwnerList;