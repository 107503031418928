import React from "react";
import MyImage from "../MyImage";
import styled from '@emotion/styled';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {
  Replay,
  Check,
  Close,
  VoiceOverOff,
  Mic,
  Smartphone
} from '@mui/icons-material';

import { 
  Zoom,
  Button,
} from '@mui/material';



const SeatRoot = styled.div((props) => ({
  position: "relative",
  backgroundColor: "white",
  border: props.pro ? `3px solid ${props.theme.palette.success.main}` : props.contra ? `3px solid ${props.theme.palette.error.main}` : `3px solid rgba(0, 0, 0, 0.2)`,
  backgroundColor: props.pro ? `rgba(0, 255, 0, 0.3)` : props.contra ? `rgba(255, 0, 0, 0.3)` : `unset`,
  alignItems: "center",
  gap: "10px",
  display: "flex",
  overflow: "hidden",
  paddingLeft: "3px",
  paddingTop: "3px",
  paddingBottom: "3px",
  transition: "border 0.5s linear, background-color 0.5s",
  //transition: "border-color 1s",
}));

const SeatCheckIcon = styled(Check)((props) => ({
  opacity: props.show === 'true' ? "1" : "0",
  position: "absolute",
  right: "0",
  left: "0",
  bottom: "1rem",
  width: "100%",
  fontSize: "1.5rem",
}));

const SeatCloseIcon = styled(Close)((props) => ({
  opacity: props.show === 'true' ? "1" : "0",
  position: "absolute",
  right: "0",
  left: "0",
  bottom: "1rem",
  width: "100%",
  fontSize: "1.5rem",
}));

const SeatPresentation = (props) => {

  //#region props
  var {
    headseat,
    user,   //contains seat.user infos
    userProps={},
    seatnum,
    style,
    handleManualVote,
    handleManualSpeaker,
    handleManualAway
  } = props;
  //#endregion

  //#region states
  const [hover, setHover] = React.useState(false);
  //#endregion

  //#region hooks
  const classes = {}; //useStyles();
  //#endregion

  const handleAway = (flag) => {
    handleManualAway({seatnum: seatnum});
  }

  const handleAnnouncement = (value) => {
    handleManualSpeaker({seatnum: seatnum})
  }

  const setVoteOnSeat = (value) => {
    handleManualVote({
      seatnum: seatnum,
      value: value,
    })
  }


  return(
    <SeatRoot pro={userProps.pro && !userProps.contra} contra={!userProps.pro && userProps.contra}>
      
        <div style={{display: "flex", gap: "10px", height: "100%", width: "100%", alignItems: "center", justifyContent: "center"}}>
          {/* user */}
          
          <div className={"user"}>
          
            <div className={"value"} style={{textAlign: "center", display: "flex", alignItems: "center", gap: "0.5rem"}}>
              {user.deviceId !== null
              ? <Smartphone />
              : null
              }
              {user.owner
              ? user.owner.name
              : null
              }
            </div>
          </div>
          {/* user end */}

          {/* vote */}
          {/*<div className={"icons"}>*/}
          <SeatCheckIcon
             show={userProps.pro ? 'true' : 'false'}
          />
          <SeatCloseIcon
            show={userProps.contra ? 'true' : 'false'}
          />
          
          {/*</div> */}

          {/* away */} 
          {userProps.away
          ? <div className={`${userProps.away ? classes.away : null}`}>
              <VoiceOverOff className={`${classes.icon} icon ${userProps.away ? classes.fadeIn : classes.fadeOut}`}/>
            </div>
          : null
          }

        </div>

    </SeatRoot>
  );

}


export default SeatPresentation;