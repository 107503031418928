import { createSlice } from '@reduxjs/toolkit'

export const socketSlice = createSlice({
  name: 'socket',
  initialState: {
    connected: false,
    id: null,
    approved: false,
    admin: false,
  },
  reducers: {
    setSocket: (state, action) => {
      state.approved = action.payload.approved;
      state.authed = action.payload.authed;
      state.id = action.payload.id;
    },
    setConnected: (state, action) => {
      state.connected = action.payload;
    },
    clearSocket: (state, action) => {
      state.connected = false;
      state.id = null;
      state.approved = false;
      state.authed = false;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setSocket, setConnected, clearSocket } = socketSlice.actions

export default socketSlice.reducer