import React, { useEffect } from 'react';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
import styled from '@emotion/styled';
import { Checkbox, Collapse, FormControlLabel, IconButton, ListItem } from '@mui/material';
import DoorBackOutlinedIcon from '@mui/icons-material/DoorBackOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import subobjects from '../../texts/subobjects';
import communityService from '../../services/community.service';

//#region styles
const ListItemRoot = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
  gap: "1rem",
  padding: "1rem 1rem",
  position: "relative",
  transition: "background-color 0.2s",
  width: "calc(100% - 2rem)",
  overflow: "hidden",
}));

const ListItemInfos = styled.div((props) => ({
  display: "flex",
  gap: "2rem",
  position: "relative",
}))

const ListItemButtons = styled.div((props) => ({
  position: "absolute",
  right: "1rem",
  bottom: "-1rem",
  display: "flex",
  gap: "0.25rem",
}))

const StyledIcon = styled(GroupsIcon)((props) => ({
  fontSize: "3rem",
  opacity: "0.5",
  color: props.outdated === "true" ? "red" : "unset",
  padding: "5px",
}));

const DateInfo = styled.div((props) => ({
  position: "absolute",
  top: "0",
  right: "1rem",
  fontSize: "0.75rem",
  gap: "0.5rem",
  display: "flex",
}))

const InfoElement = styled.div((props) => ({
  //width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  userSelect: "none",
  padding: "5px",
  "& div:first-of-type": {
    fontSize: "1.25rem",
    position: "relative",
  },
  "& div:nth-of-type(2)": {
    opacity: "0.5",
  }
}))

const InfoElementSubs = styled(InfoElement)((props) => ({
  border: props.active === "true" ? `1px solid ${props.theme.palette.primary.main}` : `1px solid rgba(0, 0, 0, .2)`,
  borderRadius: "3px",
  cursor: "pointer",
  backgroundColor: props.active === "true" ? props.theme.palette.primary.light : "rgba(0, 0, 0, 0.1)",
  transition: "border 0.3s, background-color 0.3s",
}))
//#endregion

const CommunityListItem = (props) => {

  const {
    element, handleSecondarySidebar
  } = props;

  const [community, setCommunity] = React.useState(null);
  const [showSubs, setShowSubs] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [editSubs, setEditSubs] = React.useState([]);

  useEffect(() => {
    setCommunity(element);
  },[element])

  const handleSave = () => {
    var newCommunity = {
      id: community.id,
      title: community.title,
      subobjects: editSubs,
    };

    communityService.update(newCommunity)
      .then((response) => {
        setCommunity(response.data);
        handleAbortEdit();
      })
      .catch((e) => console.log(e));
  }

  const handleEdit = () => {
    var _editSubs = community.subobjects.map(x => x.id);
    setEditSubs(_editSubs);
    setEdit(true);
  }

  const handleAbortEdit = () => {
    setEditSubs([]);
    setEdit(false);
  }

  const handleEditSubobject = (subobject) => {
    var index = editSubs.findIndex(x => x === subobject.id);
    var _editSubs = [...editSubs];
    if(index > -1){
      //remove sub
      _editSubs.splice(index, 1);
    } else {
      _editSubs.push(subobject.id)
    }
    setEditSubs(_editSubs);
  }

  const handleDelete = () => {
    console.log("handleDelete");
    handleSecondarySidebar("remove_community", community)
  }

  if(community === null) return null;

  return(
    <ListItemRoot>
      <ListItemInfos>
        <StyledIcon/>
        <DateInfo>
          <div>{new Date(community.createdAt).toLocaleTimeString()}</div>
          <div>{new Date(community.createdAt).toLocaleDateString()}</div>
        </DateInfo>
        <ListItemButtons>
          {edit
          ? <>
              <IconButton color="success" onClick={handleSave}>
                <CheckIcon />
              </IconButton>
              <IconButton color="error" onClick={handleAbortEdit}>
                <CloseIcon />
              </IconButton>
            </>
          : <>
              <IconButton color="info" onClick={handleEdit}>
                <EditIcon />
              </IconButton>
              <IconButton color="error" onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
            </>
          }
        </ListItemButtons>
        <InfoElement>
          <div>{community.title}</div>
          <div>Titel</div>
        </InfoElement>
        <InfoElementSubs onClick={() => setShowSubs(!showSubs)} active={showSubs ? "true" : "false"}>
          <div>
            {community.subobjects.length}
            <div style={{
              position: "absolute",
              right: "-17px",
              top: "-7px",
              display: "none",
            }}>
              <InfoOutlinedIcon color="info" sx={{height: "15px", width: "15px"}}/>
            </div>
          </div>
          <div>
            Anzahl Teilobjekte
          </div>
          
        </InfoElementSubs>
        <InfoElement>
          <div>{community.object.name}</div>
          <div>Objekt</div>
        </InfoElement>
      </ListItemInfos>
      
      <Collapse in={edit}>
          <div style={{
             display: "grid",
             gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
             gap: "0.5rem 0.5rem",
             overflowY: "auto",
             height: "100%",
             padding: "0.5rem",
          }}>
            {community.object.subobjects === undefined ? "Keine Subobjecte" : community.object.subobjects.map((subobject, index) =>
              <div key={index} style={{}}>
                <FormControlLabel label={
                  <div style={{display: "flex", gap: "7px"}}>
                    <div>{subobject.number}</div> 
                    <div>{subobject.name}</div>
                  </div>
                } control={<Checkbox onChange={() => handleEditSubobject(subobject)} checked={editSubs.findIndex(x => x === subobject.id) > -1}/>}
                />
              </div>
            )}
          </div>
      </Collapse>
      <Collapse in={showSubs}>
        <div style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr)",
          width: "100%",
          overflow: "hidden",
          //gap: "1rem 1rem",
        }}>
          {community.subobjects === undefined ? null : community.subobjects.map((sub, index) =>
            <div key={index} style={{
              display: "flex",
              gap: "1rem", 
              border: "1px solid rgba(0, 0, 0, 0.2)", 
              padding: "0.5rem",
              //width: "250px",
            }}>
              <div>
                <DoorBackOutlinedIcon />
              </div>
              <div>
                <div>{sub.name}</div>
                <div>{sub.owner === undefined ? null : sub.owner.name}</div>
              </div> 
            </div>
          )}
        </div>
      </Collapse>
      
    </ListItemRoot>
  );
}

export default CommunityListItem;