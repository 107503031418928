import React, { useEffect } from "react";

import MyList from "../../layout-components/MyList/index";
import DeviceListItem from "./CommunityListItem";

import deviceService from "../../services/device.service";
import ownerService from "../../services/owner.service";
import MyPage from "../../layout-components/MyPage";
import MyPageContent from "../../layout-components/MyPage/MyPageContent";
import MyPageTitle from "../../layout-components/MyPage/MyPageTitle";
import texts from "../../texts";
import communityService from "../../services/community.service";
import CommunityListItem from "./CommunityListItem";
import { Button, Collapse, FormControlLabel, MenuItem, TextField, Checkbox } from "@mui/material";
import objectService from "../../services/object.service";
import styled from '@emotion/styled';
import SecondarySidebar from "../../layout-components/SecondarySidebar";
import RemoveCommunity from "./RemoveCommunity";

//#region styles
const CommunityListRoot = styled.div((props) => ({
  //border: "1px solid red",
  height: "calc(100% - 2px)",
  width: "calc(100% - 2px)",
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
  overflowX: "hidden",
}));

const CommunityListHeader = styled.div((props) => ({
  //borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
  padding: "0.5rem 0rem",
  justifyContent: "flex-end",
  display: "flex",
  gap: "1rem",
}))

const CommunityListAddContainer = styled.div((props) => ({
  height: props.open === "true" ? "100%" : "0px",
  transition: "height 0.5s",
  //backgroundColor: "red",
  overflow: "hidden",
}));

const CommunityListAddContent = styled.div((props) => ({
  border: `1px solid ${props.theme.palette.primary.light}`,
  borderRadius: "5px",
  padding: "0.5em",
  //backgroundColor: "yellow",
  height: "calc(100% - 2px - 1.5rem)",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
}));

const CommunityListAddContentHeader = styled.div((props) => ({
  display: "flex", 
  alignItems: "center", 
  justifyContent: "space-between",
}));

const CommunityListAddContentInputs = styled.div((props) => ({
  display: "flex", 
  gap: "1rem", 
  width: "100%", 
  flexWrap: "wrap",
}))

const CommunityListAddContentInputsTextField = styled.div((props) => ({
  flex: "1 0 0",
  minWidth: "350px",
}))

const CommunityListAddContentInputsSelect = styled.div((props) => ({
  flex: "3 0 0",
  minWidth: "350px",
}))

const CommunityListAddSubobjectsContainer = styled.div((props) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
}))

const CommunityListAddSubobjectsHeader = styled.div((props) => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "space-between",
  padding: "0.25rem",
}))

const CommunityListAddSubobjectsContent = styled.div((props) => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
  gap: "0.5rem 0.5rem",
  overflowY: "auto",
  height: "100%",
  padding: "0.5rem",
}));

const CommunityListContainer = styled.div((props) => ({
  border: "1px solid rgba(0, 0, 0, 0.2)",
  height: props.open === "true" ? "calc(0% - 2px)" : "calc(100% - 2px)",
  width: "calc(100% - 2px)",
  overflow: "auto",
}));

const CommunityListNoItems = styled.div((props) => ({
  display: "flex",
  padding: "1rem",
  justifyContent: "center",
  opacity: "0.7",

}));

//#endregion

const CommunityList = () => {
  
  //#region states
  const [communites, setCommunities] = React.useState([]);
  const [objects, setObjects] = React.useState([]);
  const [add, setAdd] = React.useState(false);
  const [selectedObject, setSelectedObject] = React.useState(" ");
  const [addTitle, setAddTitle] = React.useState("");
  const [selectedAddObject, setSelectedAddObject] = React.useState(" ");
  const [selectedAddSubobjects, setSelectedAddSubobjects] = React.useState([]);
  const [loading, setLaoding] = React.useState(false);
  const [secondarySidebar, setSecondarySidebar] = React.useState(false);  //show or hide secondarysidebar
  const [sidebarContent, setSidebarContent] = React.useState(null);       //hold content for secondarysidebar
  //#endregion

  //#region functions
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    communityService.getAll(selectedObject)
      .then((response) => setCommunities(response.data))
      .catch((e) => console.log(e));
  }, [selectedObject])

  useEffect(() => {
    setSelectedAddSubobjects([])
  },[selectedAddObject]);

  const init = async() => {
    objectService.getAll()
      .then((response) => {
        setObjects(response);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const handleAddClicked = () => {
    setAdd(!add);
  }

  const handleSelectedSubobject = (id) => {
    var _selectedAddSubobjects = [...selectedAddSubobjects];
    var subIndex = _selectedAddSubobjects.findIndex(x => x === id);
    if(subIndex === -1){
      //community nicht gefunden
      //add community
      _selectedAddSubobjects.push(id);
    } else {
      //remove community
      _selectedAddSubobjects.splice(subIndex, 1);
    }
    setSelectedAddSubobjects(_selectedAddSubobjects);
  }


  const handleSave = () => {
    var community = {
      title: addTitle,
      object: selectedAddObject.id,
      subobjects: selectedAddSubobjects,
    }

    communityService.create(community)
      .then((response) => {
        communityService.getAll(selectedObject)
          .then((res) => {
            setAdd(false);
            setAddTitle("");
            setSelectedAddObject(" ");
            setSelectedAddSubobjects([]);
            setCommunities(res.data)
          })
          .catch((error) => console.log(error));
      })
      .catch((e) => console.log(e));

  }

  const handleSecondarySidebarClosed = () => {
    console.log("handleSecondarySidebarClosed");
    setSidebarContent(null);
    setSecondarySidebar(false);
  }

  const handleSecondarySidebar = (type, community) => {
    console.log("handleSecondarySidebar", type, community);
    switch(type){
      case "remove_community":
        setSidebarContent(
          <RemoveCommunity 
            community={community} 
          />
        );
        break;


      default:
        setSidebarContent(null);
        break;
    }
    setSecondarySidebar(true);
  }

  //#endregion

  console.log("secondarySidebar", secondarySidebar);

  return(
    <MyPage>
      <MyPageTitle>
        {texts.communities.h_plu}
      </MyPageTitle>
       
      <MyPageContent>
        <SecondarySidebar 
          content={sidebarContent}
          open={secondarySidebar} 
          onClose={handleSecondarySidebarClosed}
        />
        <CommunityListRoot>
          <CommunityListHeader>
            <TextField select fullWidth label="Objekt" value={selectedObject} onChange={(e) => setSelectedObject(e.target.value)}>
              <MenuItem value=" ">Alle</MenuItem>
              {objects.map((object, index) =>
                <MenuItem key={index} value={object}>{object.name}</MenuItem>
              )}
            </TextField>
            <Button variant="contained" onClick={handleAddClicked} color={add ? "secondary" : "primary"}>
              {add ? "Abbrechen" : "Hinzufügen"}
            </Button>
          </CommunityListHeader>
          <CommunityListAddContainer open={add ? "true" : "false"}>
            <CommunityListAddContent>
              <CommunityListAddContentHeader>
                <div>Neue Untergemeinschaft anlegen</div> 
                <div>
                  <Button variant="contained" size="small" onClick={handleSave}>Speichern</Button>
                </div>
              </CommunityListAddContentHeader>
              <CommunityListAddContentInputs>
                <CommunityListAddContentInputsTextField>
                  <TextField label="Titel" fullWidth onChange={(e) => setAddTitle(e.target.value)}/>
                </CommunityListAddContentInputsTextField>
                <CommunityListAddContentInputsSelect>
                  <TextField label="Objekt" value={selectedAddObject} select onChange={(e) => setSelectedAddObject(e.target.value)} fullWidth>
                    <MenuItem value=" ">-</MenuItem>
                    {objects.map((object, index) =>
                      <MenuItem key={index} value={object}>{object.name}</MenuItem>
                    )}
                  </TextField>
                </CommunityListAddContentInputsSelect>
              </CommunityListAddContentInputs>

              <CommunityListAddSubobjectsContainer>
                <CommunityListAddSubobjectsHeader>
                  <div>Teilobjekte</div>
                  <div>Ausgewählt: {selectedAddSubobjects.length}</div>
                </CommunityListAddSubobjectsHeader>
                <CommunityListAddSubobjectsContent>
                  {selectedAddObject.subobjects === undefined || selectedAddObject.subobjects.length < 0  
                  ? <div>
                      Keine Teilobjekte vorhanden!
                  </div> 
                  : selectedAddObject.subobjects.map((subobject, index) =>
                    <div key={index} style={{}}>
                      <FormControlLabel label={
                        <div style={{display: "flex", gap: "7px"}}>
                          <div>{subobject.number}</div> 
                          <div>{subobject.name}</div>
                        </div>
                      } control={<Checkbox onChange={() => handleSelectedSubobject(subobject.id)} checked={selectedAddSubobjects.findIndex(x => x === subobject.id) > -1}/>}
                      />
                    </div>
                  )}
                </CommunityListAddSubobjectsContent>
                
              </CommunityListAddSubobjectsContainer>
            </CommunityListAddContent>
          </CommunityListAddContainer>
            <CommunityListContainer open={add ? "true" : "false"}>
              {communites.length > 0
              ? communites.map((comm, index) => 
                  <CommunityListItem 
                    key={index} 
                    element={comm} 
                    handleSecondarySidebar={handleSecondarySidebar}
                  />
                )
              : <CommunityListNoItems>
                  Keine Element vorhanden!
                </CommunityListNoItems>
              }
            </CommunityListContainer>
        </CommunityListRoot>
      </MyPageContent>
    </MyPage>
  );
}

export default CommunityList;