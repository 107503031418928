import React, {useRef} from "react";
import voteService from "../../services/vote.service";
import styled from "@emotion/styled/macro";
import texts from "../../texts";
import { TextField, FormControlLabel, Checkbox, Button } from "@mui/material";
import MyButton from "../../layout-components/MyButton/MyButton";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addAlert } from "../../redux/slices/alertsSlice";

//#region styles
const AddVoteRoot = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",
  height: "100%",
  width: "100%",
}))

const AddVoteContent = styled.div((props) =>   ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  height: "100%",
  width: "100%",
}))

const AddVoteForm = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  alignItems: "center",
}))

const AddVoteButtons = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  flexShrink: "0",
}))
//#endregion

const RemoveMeeting = (props) => {
  const {
    meeting,
    setHeadvote,
    handleClose=()=>{},
    setRemoveVote,
    vote
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRemove = async() => {
    
    try{
      voteService.removeVote(meeting)
        .then((response) => {
          dispatch(addAlert({
            type: "info",
            msg: `${texts.meetings.sing} erfolgreich gelöscht!`
          }))
          navigate('/');
        })
        .catch((e) => {
          console.log(e);
          dispatch(addAlert({
            type: "error",
            msg: `${texts.meeting.sing} konnte nicht gelöscht!`
          }))
        })
      
    } catch(e){
      console.log(e);
    }

  }

  const closeSidebar = () => {
    setRemoveVote(null);
  }


  if(meeting === undefined || meeting === null) return null;

  return(
    <AddVoteRoot>
      <h3>{texts.meetings.sing} löschen</h3>
      <AddVoteContent>
        <div style={{textAlign: "center", display: "flex", flexDirection: "column", gap: "1rem", marginTop: "1.5rem"}}>
          <div>Soll die {texts.meetings.sing}</div>
          <div style={{fontWeight: "600", fontSize: "1.1rem"}}>{meeting.title}</div>
          <div>wirklich gelöscht werden?</div>
        </div>
        <div style={{
          fontSize: "0.7rem",
          opacity: "0.7",
          //fontStyle: "italic",
        }}>
          Die {texts.meetings.sing} wird unwiderruflich gelöscht!
        </div>
        <AddVoteButtons>
          <Button variant="contained" onClick={handleRemove} color="primary">Löschen</Button>
          <Button  onClick={closeSidebar} color="error" variant="outlined">Abbrechen</Button>
        </AddVoteButtons>
      </AddVoteContent>
    </AddVoteRoot>
  );
}

export default RemoveMeeting;