import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styled from '@emotion/styled';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Button } from '@mui/material';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    //maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '2px solid rgba(0, 0, 0, 1)',
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    //gap: "10px",
    paddingLeft: "0",
    paddingRight: "0",
    paddingTop: "0",
    paddingBottom: "0",
    borderRadius: "5px",  
  }
}));

const TooltipContentRoot = styled.div((props) => ({
  display: "grid",
  gridTemplateColumns: "1fr auto",
  alignItems: "center",
  gap: "0rem 0rem",
  "> div": {
    padding: "1rem 1rem",
    height: "calc(100% - 2rem)",
    fontSize: "1rem",
    borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
    "&:last-of-type": {
      borderBottom: "none",
    }
  }
}));

const tooltipContent= {
  change: (socket, handleReconnect) => {
    return (
      <React.Fragment>
        <TooltipContentRoot>

        <div>Verbunden</div>
        <div>
          {socket.connected
          ? <CheckCircleOutlinedIcon color="success"/>
          : <CancelOutlinedIcon color="error" />
          }
        </div>
        
        
        <div>Genehmigt</div>
        <div>
          {socket.approved
          ? <CheckCircleOutlinedIcon color="success"/>
          : <CancelOutlinedIcon color="error" />
          }
        </div>
        
        
        <div>Autorisiert</div>
        <div>
        {socket.authed
          ? <CheckCircleOutlinedIcon color="success"/>
          : <CancelOutlinedIcon color="error" />
          }
        </div>
        
        <div style={{gridColumn: "1 / 3", display: "flex", justifyContent: "center"}}>
          <Button onClick={handleReconnect} variant="outlined" color="info">Neu verbinden</Button>
        </div>
        </TooltipContentRoot>
        
      </React.Fragment>
    );
  }  
}

const SidebarConnectionInfoRoot = styled.div((props) => ({
  cursor: "pointer",
  textDecoration: "underline",
  textDecorationStyle: "dotted",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
  padding: "1vh 0rem 1vh 0rem",
  borderTop: "1px solid rgba(0, 0, 0, 0.2)",
  borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
}))

const SidebarConnectionInfo = (props) => {

  const { sidebarClosed } = props;

  const socket = useSelector((state) => state.socket);
  const dispatch = useDispatch();

  const handleReconnect = () => {
    dispatch({type: 'socket/reconnect'})
  }

  return(
    <HtmlTooltip title={tooltipContent.change(socket, handleReconnect)} arrow={true}  placement="right-start">
      <SidebarConnectionInfoRoot>
        {socket.connected
        ? <WifiIcon /> 
        : <WifiOffIcon />
        }
        {sidebarClosed
        ? ""
        : "Verbindung"
        }
      </SidebarConnectionInfoRoot>
    </HtmlTooltip>
  );
}

export default SidebarConnectionInfo;