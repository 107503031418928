import React, { useEffect } from "react";
import { Button, Checkbox, FormControlLabel, MenuItem, Tab, Tabs, TextField } from "@mui/material";
import MyButton from "../../layout-components/MyButton/MyButton";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import styled from '@emotion/styled';
import Papa from "papaparse";
import PropTypes from 'prop-types';
import { useTheme } from "@emotion/react";
import MyPage from '../../layout-components/MyPage';
import MyPageTitle from "../../layout-components/MyPage/MyPageTitle";
import MyPageContent from "../../layout-components/MyPage/MyPageContent";
import GridTable from "../../layout-components/GridTable";
import ImportData from "./ImportData";
import configService from "../../services/config.service";
import { useDispatch } from "react-redux";
import { addAlert } from "../../redux/slices/alertsSlice";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const CustomTabs = styled(Tabs)((props) => ({

}))

const CustomTabPanelRoot = styled.div((props) => ({
  height: "100%",
  width: "100%",
  overflowY: "hidden",
}));

const CustomTab = styled.div((props) => ({
  padding: "1rem 1rem",
  border: "1px solid rgba(0, 0, 0, .2)",
  height: "calc(100% - 2rem - 2px)",
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <CustomTabPanelRoot
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <CustomTab>
          {children}
        </CustomTab>
      )}
    </CustomTabPanelRoot>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const dataColumns = [
  {
    title: "Obj Nr.",
    labels: "objNo"
  },
  {
    title: "Obj Name",
    labels: "objName"
  },
  {
    title: "SubObj Nr.",
    labels: "subObjNo"
  },
  {
    title: "SubObj Name",
    labels: "subObjName"
  },
  {
    title: "SubObj Typ",
    labels: "subObjType"
  },
  {
    title: "Eigentümer",
    labels: "owner"
  },
  {
    title: "MEA",
    labels: "mea"
  },
]

const Options = () => {

  const [importData, setImportData] = React.useState(null);
  const [value, setValue] = React.useState(0);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return(
    <MyPage>
      <MyPageTitle size="small">
        Daten Import
      </MyPageTitle>
      <MyPageContent style={{display: "flex", flexDirection: "column", border: "1px solid rgba(0, 0, 0, 0.2)", borderRadius: "5px"}}>
        
        
        <ImportData />
        
       
      </MyPageContent>
    </MyPage>
  );
}

export default Options;