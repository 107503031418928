import axios from "axios";
import {store} from '../redux/store';

const api = process.env.REACT_APP_API_URL;
const dacorAuthToken = process.env.REACT_APP_AUTH_TOKEN;

/*
  Zugriffsbeschränkungen
  Der Zugriff auf Resourcen, die auf dem Server liegen, zu beschränken, werden zwei verschiedene Tokens verwendet.
  1. DacorAuthToken: Der DacorAuthToken beschränkt den Zugriff auf alle Resourcen. Dieser Token muss immer im Header stehen, 
    egal ob die Resourcen "öffentlich", also auch ohne Login, oder "privat", also nur mit Login, ist.
  2. AccessToken: Dieser Token wird durch das Einloggen des Benutzers zur Verfügung gestellt. Damit können Resourcen abgefragt werden,
    die sich auf den Mandanten beziehen, zu dem sie gehören.
*/

const getAll = (object=null) => {

  var state = store.getState();
  
  if(object !== null && object.id !== undefined){
    return axios
    .get(`${api}/user/community/all?obj=${object.id}`, {
      headers: {
        "x-dacor-auth-token": dacorAuthToken,
        "x-access-token": state.auth.token
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    })
  }

  return axios
    .get(`${api}/user/community/all`, {
      headers: {
        "x-dacor-auth-token": dacorAuthToken,
        "x-access-token": state.auth.token
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    })
}

const create = (community) => {
  var state = store.getState();

  return axios
    .post(`${api}/user/community/create`, community, {
      headers: {
        "x-dacor-auth-token": dacorAuthToken,
        "x-access-token": state.auth.token
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    })
}

const update = (community) => {
  var state = store.getState();
  
  return axios
    .post(`${api}/user/community/update`, community, {
      headers: {
        "x-dacor-auth-token": dacorAuthToken,
        "x-access-token": state.auth.token
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
    })
}

export default{
  getAll,
  create,
  update
}